import React, { useRef, useEffect } from "react";
import cx from "classnames";
import {
  GraphTooltip,
  IndexTooltip,
  IndexTooltipItem,
  EventsTooltip,
  EventsTooltipItem,
  TransferTooltipItem,
  Tooltip,
} from "./PerformanceGraphTypes";
import { Currency } from "../../context/LocalizationContext";
import { dataPerformance } from "../../data/dataPerformance";
import { InvestmentAccount } from "../../data/dataAccounts";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Amount } from "../amount/Amount";
import { Change } from "../amount/Change";
import "./OverlayLegend.scss";
import { DateTime } from "luxon";
import { comparisonMarkets } from "../../data/dataPerformance";
import { RouteAwareToggle } from "../route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { INTERNAL_TRANSFER_PAGE_URL } from "../../pages/internalTransfer/InternalTransferPage";

interface Props {
  account?: InvestmentAccount;
  graphTooltip: GraphTooltip;
  indexTooltip: IndexTooltip;
  date: Date;
  eventsTooltip: EventsTooltip;
  showEvents: boolean;
}

function getDefaultEvent(): EventsTooltipItem {
  return {
    date: new Date(),
    name: "",
    shortName: "",
    amount: 0,
    type: "DEPOSIT",
    currency: Currency.SEK,
  };
}

export const OverlayLegend: React.FC<Props> = (props) => {
  const {
    date,
    account,
    graphTooltip,
    indexTooltip,
    eventsTooltip,
    showEvents,
  } = props;
  const prevIndexItem = useRef<IndexTooltipItem[]>([]);

  const prevLysaItem = useRef({
    worth: 0,
    change: 0,
    currency: Currency.SEK,
  });

  useEffect(() => {
    prevIndexItem.current = [];
  }, [indexTooltip]);

  const stringDate = dataPerformance.toStringDate(date) || "";

  const prevDepositItem = useRef<EventsTooltipItem>(getDefaultEvent());
  const prevWithdrawalItem = useRef<EventsTooltipItem>(getDefaultEvent());
  const prevInternalItem = useRef<TransferTooltipItem>({
    date: new Date(),
    from: "",
    to: "",
    amount: [0],
    type: "INTERNAL",
    currency: Currency.SEK,
  });
  const intl = useIntl();

  // const lysaItem = graphTooltip[stringDate] || prevLysaItem.current;
  const lysaItem = getClosestValue(stringDate, graphTooltip, {
    date: "",
    worth: 0,
    change: 0,
    currency: Currency.SEK,
  });
  prevLysaItem.current = lysaItem;

  const indexItem = indexTooltip[stringDate] || prevIndexItem.current;
  prevIndexItem.current = indexItem;

  const depositItem = eventsTooltip.DEPOSIT[stringDate];
  prevDepositItem.current = depositItem || prevDepositItem.current;

  const withdrawalItem = eventsTooltip.WITHDRAWAL[stringDate];
  prevWithdrawalItem.current = withdrawalItem || prevWithdrawalItem.current;

  const internalItem = eventsTooltip.INTERNAL[stringDate];
  prevInternalItem.current = internalItem || prevInternalItem.current;

  return (
    <div className="overlay-legend">
      <ul>
        {indexItem.map((item) => {
          return (
            <li key={item.key}>
              <div className="overlay-legend-name">
                <span
                  className="graph-indicator"
                  style={{ backgroundColor: item.color }}
                />
                {intl.formatMessage(comparisonMarkets[item.id])}
              </div>
              <div className="overlay-legend-change">
                <Change change={item.change} />
              </div>
            </li>
          );
        })}

        <li className="overlay-legend-lysa">
          <div className="overlay-legend-name">
            <span className="graph-indicator" />
            {typeof account !== "undefined" ? (
              <b>{account.name}</b>
            ) : (
              <FormattedMessage
                id="performance.graph.tooltip.legend"
                description="Graph performance tooltip label"
                defaultMessage="Total profits"
              />
            )}
          </div>
          <div className="overlay-legend-worth">
            <Amount amount={lysaItem.worth} />
          </div>
          <div className="overlay-legend-change">
            <Change change={lysaItem.change} />
          </div>
        </li>

        {showEvents && (
          <>
            <li
              className={cx("performance-overlay-legend-deposit", {
                "is-visible": depositItem,
              })}
            >
              <div className="overlay-legend-type">
                <span className="graph-indicator" />
                <FormattedMessage
                  id="performance.graph.overlay.deposit"
                  description="Graph performance overlay label deposit"
                  defaultMessage="Deposit"
                />
              </div>

              <div className="overlay-legend-event-name">
                {prevDepositItem.current.name}
              </div>

              <div className="overlay-legend-amount">
                <Amount amount={prevDepositItem.current.amount} />
              </div>
            </li>
            <li
              className={cx("performance-overlay-legend-withdrawal", {
                "is-visible": withdrawalItem,
              })}
            >
              <div className="overlay-legend-type">
                <span className="graph-indicator" />
                <FormattedMessage
                  id="performance.graph.overlay.withdrawal"
                  description="Graph performance overlay label withdrawal"
                  defaultMessage="Withdrawal"
                />
              </div>

              <div className="overlay-legend-event-name">
                {prevWithdrawalItem.current.name}
              </div>

              <div className="overlay-legend-amount">
                <Amount amount={prevWithdrawalItem.current.amount} />
              </div>
            </li>

            <RouteAwareToggle path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
              <li
                className={cx("performance-overlay-legend-internal", {
                  "is-visible": internalItem,
                })}
              >
                <div className="overlay-legend-type">
                  <span className="graph-indicator" />
                  <FormattedMessage
                    id="performance.graph.overlay.internal"
                    description="Graph performance overlay label internal"
                    defaultMessage="Internal transfer"
                  />
                </div>

                <div className="overlay-legend-event-name">
                  {prevInternalItem.current.from} &#8594;{" "}
                  {prevInternalItem.current.to}
                </div>

                <div className="overlay-legend-amount">
                  {prevInternalItem.current.amount.map((amount, idx) => (
                    <React.Fragment
                      key={`${idx}${prevInternalItem.current.date.getTime()}`}
                    >
                      {!!idx && "/"}
                      <b>
                        <FormattedNumber value={Math.round(amount)} />
                      </b>
                      <span className="internal-postfix">
                        {prevInternalItem.current.currency}
                      </span>
                    </React.Fragment>
                  ))}
                </div>
              </li>
            </RouteAwareToggle>
          </>
        )}
        <li className="overlay-legend-interval">
          <span />
          <i className="overlay-legend-date">{stringDate}</i>
          <span />
        </li>
      </ul>
    </div>
  );
};

function getClosestValue(
  dateString: string,
  data: GraphTooltip,
  defaultValue: Tooltip
) {
  const date = DateTime.fromFormat(dateString, "yyyy-MM-dd");
  if (!date.isValid) {
    return defaultValue;
  }
  if (data[dateString]) {
    return data[dateString];
  }
  let lastData = defaultValue;
  for (const tooltip in data) {
    if (
      DateTime.fromFormat(data[tooltip].date, "yyyy-MM-dd").diff(date, "days")
        .days <= 0
    ) {
      lastData = data[tooltip];
    } else {
      return lastData;
    }
  }
  return lastData;
}
