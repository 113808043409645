import { Card, Button, Icon } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { useRouteMatch } from "react-router";
import { Amount } from "../../components/amount/Amount";
import { GridCol } from "../../components/grid/gridCol/GridCol";
import {
  AlignValues,
  GridRow,
  JustifyContentValues,
} from "../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../components/TranslatedText";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  ContractNoteResponse,
  CONTRACT_NOTE_TYPE,
  dataInvoice,
} from "../../data/dataInvoice";
import { ContractNoteId } from "../../data/dataTransactions";
import { PageStripped } from "../PageStripped";
import { TradeTable } from "./tradeTable/TradeTable";
import { Link } from "react-router-dom";
import { getNavLink } from "../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { ContactPhoneNumbers } from "../../components/Contact";
import { LysaCountry } from "@lysaab/shared";

import "./InvoicePage.scss";

export const INVOICE_PAGE_URL = "/invoice/:contractNoteId";

interface Match {
  contractNoteId?: ContractNoteId;
}

const messages = defineMessages({
  actionBuy: {
    id: "historicTransactionsPage.historicTransactions.actionBuy",
  },
  actionSell: {
    id: "historicTransactionsPage.historicTransactions.actionSell",
  },
  actionSwitch: {
    id: "historicTransactionsPage.historicTransactions.actionSwitch",
  },
  typeBuy: {
    id: "historicTransactionsPage.historicTransactions.typeBuy",
  },
  typeSell: {
    id: "historicTransactionsPage.historicTransactions.typeSell",
  },
  typeSwitch: {
    id: "historicTransactionsPage.historicTransactions.typeSwitch",
  },
});

export const InvoicePage = () => {
  const intl = useIntl();
  const [invoiceData, setInvoiceData] = useState<ContractNoteResponse>();
  const match = useRouteMatch<Match>();
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country || "";

  useEffect(() => {
    if (match.params.contractNoteId) {
      dataInvoice
        .getInvoice(match.params.contractNoteId)
        .then((response) => setInvoiceData(response));
    }
  }, [match.params.contractNoteId]);

  const transactionTypes: Record<CONTRACT_NOTE_TYPE, string> = {
    [CONTRACT_NOTE_TYPE.BUY]: intl.formatMessage(messages.typeBuy),
    [CONTRACT_NOTE_TYPE.SELL]: intl.formatMessage(messages.typeSell),
    [CONTRACT_NOTE_TYPE.SWITCH]: intl.formatMessage(messages.typeSwitch),
  };

  const actions: Record<CONTRACT_NOTE_TYPE, string> = {
    [CONTRACT_NOTE_TYPE.BUY]: intl.formatMessage(messages.actionBuy),
    [CONTRACT_NOTE_TYPE.SELL]: intl.formatMessage(messages.actionSell),
    [CONTRACT_NOTE_TYPE.SWITCH]: intl.formatMessage(messages.actionSwitch),
  };

  if (typeof invoiceData === "undefined") return null;
  return (
    <PageStripped>
      <div className="invoice-page">
        <div className="invoice-page-menu">
          <Link to={getNavLink(OVERVIEW_PAGE_URL)}>
            <Icon.Logo size={70} />
          </Link>
          <Button
            size="small"
            className="invoice-page-menu__print-button"
            onClick={() => window.print()}
            label={<TranslatedText id="invoicePage.print" />}
            icon="Print"
          />
        </div>
        <Card className="invoice">
          <div className="invoice-header">
            <GridRow
              justifyContent={JustifyContentValues.SPACE_BETWEEN}
              alignItems={AlignValues.CENTER}
            >
              <GridCol xsmall={6}>
                <Icon.Logo size={90} />
              </GridCol>
              <GridCol as="dl" xsmall={12} small={5}>
                <dt>
                  <TranslatedText id="invoicePage.invoiceNumber" />
                </dt>
                <dd className="invoice-header__details">
                  {invoiceData.contractNoteId}
                </dd>
                <dt>
                  <TranslatedText id="invoicePage.accountId" />
                </dt>
                <dd className="invoice-header__details">
                  {invoiceData.accountId}
                </dd>
              </GridCol>
            </GridRow>
          </div>
          <div className="invoice-body">
            <h1 className="invoice-body-header">
              <TranslatedText
                id="invoicePage.settlementNote"
                values={{ transaction: transactionTypes[invoiceData.type] }}
              />
            </h1>
            <p className="invoice-body-text">
              <TranslatedText
                id="invoicePage.belongingTo"
                values={{ name: invoiceData.customerName }}
              />
            </p>

            <GridRow as="ul" className="invoice-body-table">
              <GridCol
                xsmall={12}
                small={6}
                as="li"
                className="invoice-body-table__cell"
              >
                <div className="invoice-body-table__cell--bold">
                  <TranslatedText id="invoicePage.orderDate" />
                </div>
                {intl.formatDate(invoiceData.orderDate)}
              </GridCol>
              <GridCol
                xsmall={12}
                small={6}
                as="li"
                className="invoice-body-table__cell"
              >
                <div className="invoice-body-table__cell--bold">
                  <TranslatedText id="invoicePage.tradeDate" />
                </div>
                {intl.formatDate(invoiceData.tradeDate)}
              </GridCol>
              <GridCol xsmall={12} as="li" className="invoice-body-table__cell">
                <div className="invoice-body-table__cell--bold">
                  <TranslatedText id="invoicePage.accountName" />
                </div>
                {invoiceData.accountName}
              </GridCol>
              <GridCol xsmall={12} as="li" className="invoice-body-table__cell">
                <div className="invoice-body-table__cell--bold">
                  <TranslatedText
                    id="invoicePage.identificationNumber"
                    values={{ country }}
                  />
                </div>
                {invoiceData.identificationNumber}
              </GridCol>
            </GridRow>

            <p className="invoice-body-text">
              <TranslatedText id="invoicePage.trades" />
            </p>

            <TradeTable
              className="invoice-body-table"
              trades={invoiceData.trades}
            />

            <div className="invoice-body-summary">
              <p className="invoice-body-text">{actions[invoiceData.type]}</p>
              <Amount amount={invoiceData.amount} decimals={2} />
            </div>
          </div>
          <div className="invoice-body-footer">
            <div className="flex">
              <span className="invoice--font-bold">
                <TranslatedText id="invoicePage.footerLysa" />
              </span>
              <span>
                <TranslatedText id="invoicePage.footerAddress" />
              </span>
            </div>
            <div className="flex">
              <span>
                {intl.formatMessage(
                  ContactPhoneNumbers[
                    localizationContext.state.country || LysaCountry.SWEDEN
                  ]
                )}
              </span>
              <span>
                <TranslatedText id="invoicePage.footerOrganisationNumber" />
              </span>
            </div>
          </div>
        </Card>
      </div>
    </PageStripped>
  );
};
