import React, { VoidFunctionComponent } from "react";
import { Snackbar, SNACKBAR_TYPES, Button } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import "./HorizonWarning.scss";

interface Props {
  navigateToHorizon?: () => void;
}

export const HorizonWarning: VoidFunctionComponent<Props> = ({
  navigateToHorizon,
}) => {
  return (
    <div className="horizon-warning">
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <p>
          <FormattedMessage
            id="horizonwarning.text.warning"
            values={{
              b: (text) => <strong>{text}</strong>,
            }}
          />
        </p>
        {navigateToHorizon && (
          <Button
            variant="secondary"
            block
            onClick={navigateToHorizon}
            label={<FormattedMessage id="horizonwarning.link.horizon" />}
          />
        )}
      </Snackbar>
    </div>
  );
};
