import React from "react";
import cx from "classnames";
import { NewIcon, Typography } from "@lysaab/ui-2";

import "./StepIndicator.scss";

interface Step {
  text: string;
  /** Only shown when step is active. */
  activeText?: string;
}

interface Props {
  steps: Step[];
  /** The amount of steps to be marked as done. */
  stepsDone: number;
}

export const StepIndicator: React.VFC<Props> = ({ steps, stepsDone }) => {
  return (
    <div className="StepIndicator">
      <ol>
        {steps.map(({ text, activeText }, index) => {
          return (
            <li
              key={index}
              className={cx("StepIndicator__step", {
                "StepIndicator__step--done": index < stepsDone,
                "StepIndicator__step--active": index === stepsDone,
              })}
            >
              <div className="StepIndicator__icon">
                {index < stepsDone && (
                  <NewIcon.Check primaryColor="white" size={18} />
                )}
              </div>
              <div className="StepIndicator__content">
                <Typography
                  component="span"
                  type="body"
                  variant={index === stepsDone ? "primary" : "secondary"}
                >
                  {text}
                </Typography>
                {index === stepsDone && activeText && (
                  <Typography component="span" type="body-small">
                    {activeText}
                  </Typography>
                )}
              </div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};
