import React from "react";
import { Snackbar, SNACKBAR_TYPES } from "@lysaab/ui-2";
import { TranslatedText } from "../TranslatedText";
import { ExternalAccount } from "../../data/dataWithdrawals";

interface Props {
  externalAccounts: ExternalAccount[];
}

export const MissingExternalAccount: React.FunctionComponent<Props> = ({
  externalAccounts,
}) => {
  if (externalAccounts.length > 0) {
    return null;
  }
  return (
    <>
      <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
        <TranslatedText id="withdrawals.missing" />
      </Snackbar>
    </>
  );
};
