import React, {
  useReducer,
  createContext,
  FunctionComponent,
  useEffect,
  useCallback,
  useState,
} from "react";

interface LoadingContextState {
  isLoading?: boolean;
  isLoadingMap: {
    [key: string]: boolean | undefined;
  };
}

interface LoadingContextProps {
  state: LoadingContextState;
  setState: (newState: { [key: string]: boolean | undefined }) => void;
}

const LoadingContext = createContext<LoadingContextProps>(
  {} as LoadingContextProps
);

function stateReducer(
  state: LoadingContextState,
  newState: { [key: string]: boolean | undefined }
) {
  const newIsLoadingMap = { ...state.isLoadingMap, ...newState };
  const isLoading = Object.values(newIsLoadingMap).includes(true);

  return { isLoadingMap: { ...newIsLoadingMap }, isLoading };
}

export const LoadingContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    isLoading: false,
    isLoadingMap: {},
  });

  return (
    <LoadingContext.Provider value={{ state, setState }}>
      {children}
    </LoadingContext.Provider>
  );
};

export function useLoadingContext(initialValue = true) {
  const { state, setState } = React.useContext(LoadingContext);
  const [id] = useState(uuidv4());

  if (state === undefined) {
    throw new Error(
      "LoadingContext must be used within a LoadingContextProvider"
    );
  }

  useEffect(() => {
    setState({ [id]: initialValue });
  }, [id, initialValue, setState]);

  const setIsLoading = useCallback(
    (bool: boolean) => {
      setState({ [id]: bool });
    },
    [id, setState]
  );

  return { isLoading: state.isLoading, setIsLoading };
}

function uuidv4() {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    // eslint-disable-next-line no-mixed-operators
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
