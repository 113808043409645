import React, { useContext, useEffect, useState } from "react";
import { Page, PageHeader } from "../Page";
import { FormattedMessage, useIntl } from "react-intl";
import { dataTotp } from "../../data/dataTotp";
import { LysaLink, Spinner } from "@lysaab/ui-2";
import { LocalizationContext } from "../../context/LocalizationContext";
import "./ContactPage.scss";
import { ContactPhoneNumbers, OpeningHoursUTC } from "../../components/Contact";
import { getNavLink } from "../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../messages/MessagesPage";
import { Link } from "react-router-dom";
import { LysaCountry } from "@lysaab/shared";

export const CONTACT_PAGE_URL = "/contact";

export const ContactPage: React.FC = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [totp, setTotp] = useState("");

  useEffect(() => {
    let shouldUpdate = true;

    const getTotp = () => {
      dataTotp
        .getTOTP()
        .then((data) => {
          if (!shouldUpdate) {
            return;
          }

          setTotp(data.password);

          // Get a new totp in 4.5 minutes
          setTimeout(getTotp, 4.5 * 60 * 1000);
        })
        .catch((e) => {
          console.error("error", e);
          setTotp("- - - - - -");
        });
    };

    getTotp();

    return () => {
      shouldUpdate = false;
    };
  }, []);

  if (!totp) {
    return <Spinner />;
  }

  return (
    <Page className="contact-page">
      <PageHeader>
        <h1>
          <FormattedMessage id="contact.page.header" />
        </h1>
      </PageHeader>
      <p>
        <FormattedMessage
          id="contact.page.phone_and_email"
          values={{
            opening: () => {
              return intl.formatTime(
                OpeningHoursUTC[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ].OPENING
              );
            },
            closing: () => {
              return intl.formatTime(
                OpeningHoursUTC[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ].CLOSING
              );
            },
            phone: () => {
              const phoneNumber = intl.formatMessage(
                ContactPhoneNumbers[
                  localizationContext.state.country || LysaCountry.SWEDEN
                ]
              );
              return (
                <LysaLink href={`tel:${phoneNumber}`}>{phoneNumber}</LysaLink>
              );
            },
            link: (text) => {
              return (
                <LysaLink component={Link} to={getNavLink(MESSAGES_PAGE_URL)}>
                  {text}
                </LysaLink>
              );
            },
          }}
        />
      </p>
      <p>
        <FormattedMessage id="contact.page.provide_number" />
      </p>
      <div className="contact-totp">{totp}</div>
    </Page>
  );
};
