import React, { useReducer, createContext, FunctionComponent } from "react";
import { MergedInternalTransferAccount } from "../../data/dataTransfer";

export interface InternalTransferState {
  fromAccount?: MergedInternalTransferAccount;
  toAccount?: MergedInternalTransferAccount;
  amount?: number;
}

export interface InternalTransferContextProps {
  state: InternalTransferState;
  setState: (newState: Partial<InternalTransferState>) => void;
}

export const InternalTransferContext =
  createContext<InternalTransferContextProps>(
    {} as InternalTransferContextProps
  );

function stateReducer(
  state: InternalTransferState,
  newState: Partial<InternalTransferState>
) {
  return { ...state, ...newState };
}

export const InternalTransferContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <InternalTransferContext.Provider value={{ state, setState }}>
      {children}
    </InternalTransferContext.Provider>
  );
};
