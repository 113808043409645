import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  LysaLink,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { Link, useHistory, useLocation } from "react-router-dom";
import { TrackerEvent } from "../../../../../../data/dataCustomerTracking";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { UserContext } from "../../../../../../context/UserContext";
import {
  Consent,
  dataAutogiro,
  getConsentBankText,
} from "../../../../../../data/dataAutogiro";
import { LegalEntityType } from "../../../../../../data/dataLogin";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { MonthlyContext, MonthlyState } from "../MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import {
  dataMonthlyPayments,
  MonthlyPaymentWithBankInfo,
} from "../../../../../../data/dataMonthlyPayments";
import { MONTHLY_OVERVIEW_PAGE } from "../../../../../../pages/deposits/monthly/overview/MonthlyOverview";
import { ADD_DEPOSIT_AUTOGIRO_URL } from "../../Autogiro/addAccount/AddDepositAccountAutogiroStory";

interface Props {
  next: () => void;
}

const messages = defineMessages({
  externalAccountLabel: {
    id: "sweden.deposits.monthly.story.external-account-selection.label",
  },
  lysaAccountRequired: {
    id: "sweden.deposits.monthly.story.external-account-selection.lysa.required",
  },
});

export interface MonthlyContextState {
  returnState?: MonthlyState;
}

export const ExternalAccountSelection = ({ next }: Props) => {
  const location = useLocation<MonthlyContextState>();
  const [consents, setConsents] = useState<Consent[]>([]);
  const [accountAlternatives, setAccountAlternatives] = useState<
    Alternative<string>[]
  >([]);
  const [monthlyPayments, setMonthlyPayments] = useState<
    MonthlyPaymentWithBankInfo[]
  >([]);
  const [monthlyPaymentExist, setMonthlyPaymentExist] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const monthlyContext = useContext(MonthlyContext);
  const userContext = useContext(UserContext);
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const history = useHistory();

  useEffect(() => {
    if (typeof monthlyContext.state.selectedLysaAccount === "undefined") {
      history.replace(getNavLink(BASE_ROUTES.INTRO));
    }
  }, [history, monthlyContext.state.selectedLysaAccount]);

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      dataAutogiro.getConsents(),
      dataMonthlyPayments.getMonthlyPayments(),
    ])
      .then(([consents, monthlyPayments]) => {
        setConsents(consents);
        setMonthlyPayments(monthlyPayments);
        setAccountAlternatives(
          consents.map((consent) => ({
            text: getConsentBankText(consent),
            value: consent.externalBankAccount,
          }))
        );
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
    if (location.state?.returnState) {
      monthlyContext.setState(location.state.returnState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAlternativeValidation = (alternative: Alternative<string>) => {
    monthlyContext.setState({
      externalAccount: consents.find(
        (account) => account.externalBankAccount === alternative.value
      ),
    });

    const hasMonthlyPaymentCombination = monthlyPayments.some(
      (monthlyPayment) => {
        return (
          monthlyPayment.externalBankAccount === alternative.value &&
          monthlyPayment.accountId ===
            monthlyContext.state.selectedLysaAccount?.accountId
        );
      }
    );
    setMonthlyPaymentExist(hasMonthlyPaymentCombination);
  };

  return (
    <div>
      <h2>
        <TranslatedText id="sweden.deposits.monthly.story.external-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid || accountAlternatives.length === 0) {
            return;
          } else if (monthlyPaymentExist) {
            return;
          } else {
            EventTracker.track({
              event: TrackerEvent.ADD_MONTHLY_EXTERNAL_ACCOUNT,
              message: `${monthlyContext.state.externalAccount?.bank} - ${monthlyContext.state.externalAccount?.externalBankAccount}`,
            });
            next();
          }
        }}
      >
        <Card>
          {isLoading && <Spinner />}
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="sweden.deposits.monthly.story.external-account-selection.error" />
            </Snackbar>
          )}
          {monthlyPaymentExist && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText
                id="deposits.monthly.story.external-account-selection.monthlyPaymentExist"
                values={{
                  externalAccount: `${monthlyContext.state.externalAccount?.externalBankAccount}`,
                  lysaAccount: monthlyContext.state.selectedLysaAccount?.name,
                  link: (text) => (
                    <LysaLink
                      component={Link}
                      to={getNavLink(MONTHLY_OVERVIEW_PAGE)}
                    >
                      {text}
                    </LysaLink>
                  ),
                }}
              />
            </Snackbar>
          )}
          {accountAlternatives.length > 0 ? (
            <RadioGroup
              alternatives={accountAlternatives}
              value={
                typeof monthlyContext.state.externalAccount !== "undefined"
                  ? accountAlternatives.find(
                      (alternative) =>
                        alternative.value ===
                        monthlyContext.state.externalAccount
                          ?.externalBankAccount
                    )
                  : undefined
              }
              header={intl.formatMessage(messages.externalAccountLabel)}
              onChange={(alt) => {
                handleAlternativeValidation(alt);
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.lysaAccountRequired)
                ),
              ]}
            />
          ) : (
            <p>
              <TranslatedText id="sweden.deposits.monthly.story.external-account-selection.no-accounts" />
            </p>
          )}
          {!isError && (
            <LysaLink
              component={Link}
              to={
                userContext.state.legalEntityType === LegalEntityType.PERSON
                  ? getNavLink(BASE_ROUTES.BANK_SELECTION)
                  : {
                      pathname: getNavLink(ADD_DEPOSIT_AUTOGIRO_URL),
                      state: {
                        returnUrl: getNavLink(BASE_ROUTES.EXTERNAL_ACCOUNT),
                        returnState: monthlyContext.state,
                      },
                    }
              }
            >
              <TranslatedText id="sweden.deposits.monthly.story.external-account-selection.add-external-account" />
            </LysaLink>
          )}
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="sweden.deposits.monthly.story.external-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
};
