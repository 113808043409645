import {
  Alternative,
  Button,
  Card,
  encode,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, {
  useEffect,
  VoidFunctionComponent,
  useState,
  useRef,
} from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { SavingsAccountId } from "../../../../../data/dataAccounts";
import { dataProfile } from "../../../../../data/dataProfile";
import { useAccounts } from "../../../../../hooks/useAccounts";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { useIsReadOnly } from "../../../../../hooks/useIsReadOnly";
import { END_INVESTMENT_URL } from "../../../../../pages/endInvestment/EndInvestment";
import { NOT_FOUND_URL } from "../../../../../pages/NotFoundPage";
import { ExternalAccounts } from "./components/externalAccounts/ExternalAccounts";
import { dataSavingsAccountInterest } from "../../../../../data/dataSavingsAccountInterest";
import { useSavingsAccountCloseable } from "../../../../../hooks/useSavingsAccountCloseable";

import "./CloseSavingsAccountConfirmation.scss";

interface Props {
  next: () => void;
  addWithdrawalAccountUrl?: string;
}

export const CloseSavingsAccountConfirmation: VoidFunctionComponent<Props> = ({
  next,
  addWithdrawalAccountUrl,
}) => {
  const [selectedExternalAccount, setSelectedExternalAccount] =
    useState<Alternative<string>>();
  const { accounts } = useAccounts();
  const history = useHistory();
  const isReadonly = useIsReadOnly();
  const [hasAccruedInterest, setHasAccruedInterest] = useState(false);
  const { accountId } = useParams<{ accountId: SavingsAccountId }>();
  const formRef = useRef<LysaFormRef>();
  const [status, setStatus] = useState<
    | "LOADING"
    | "READY_FOR_DELETION"
    | "SENDING_TO_SERVER"
    | "GENERAL_ERROR"
    | "ON_CLOSE_ERROR"
  >("LOADING");
  const getSavingsAccountCloseable = useSavingsAccountCloseable();

  useEffect(() => {
    if (
      typeof getSavingsAccountCloseable !== "function" ||
      status === "SENDING_TO_SERVER"
    ) {
      return;
    }
    const closeableState = getSavingsAccountCloseable(accountId);

    if (!closeableState.isCloseable) {
      return history.replace(getNavLink(NOT_FOUND_URL));
    }
  }, [accountId, getSavingsAccountCloseable, history, status]);

  useEffect(() => {
    if (typeof accounts === "undefined") {
      return;
    }
    dataSavingsAccountInterest
      .getAccruedInterest(accountId)
      .then((accruedInterest) => {
        setHasAccruedInterest(accruedInterest.accruedInterest > 0);
        setStatus("READY_FOR_DELETION");
      })
      .catch(() => {
        setStatus("GENERAL_ERROR");
      });
  }, [accountId, accounts]);

  if (getSavingsAccountCloseable === "ERROR" || status === "GENERAL_ERROR") {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="closeSavingsAccountStory.confirmation.header" />
        </Typography>
        <Card className="close-account-confirmation-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="closeSavingsAccountStory.confirmation.error" />
          </Snackbar>
        </Card>
        <Button
          block
          component={Link}
          to={getNavLink(END_INVESTMENT_URL)}
          label={
            <TranslatedText id="closeSavingsAccountStory.confirmation.back" />
          }
        />
      </>
    );
  }

  if (isReadonly) {
    <>
      <Typography type="h2">
        <TranslatedText id="closeSavingsAccountStory.confirmation.header" />
      </Typography>

      <Card className="close-savings-account-confirmation">
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="closeSavingsAccountStory.confirmation.error.closeDisabled" />
        </Snackbar>
      </Card>
    </>;
  }

  const account = accounts?.savingsAccounts.find(
    (account) => account.accountId === accountId
  );

  if (
    getSavingsAccountCloseable === "LOADING" ||
    status === "LOADING" ||
    status === "SENDING_TO_SERVER" ||
    typeof account === "undefined"
  ) {
    return <Spinner />;
  }

  return (
    <>
      <Typography type="h2">
        <TranslatedText id="closeSavingsAccountStory.confirmation.header" />
      </Typography>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            isReadonly ||
            (hasAccruedInterest &&
              typeof selectedExternalAccount === "undefined")
          ) {
            return;
          }
          setStatus("SENDING_TO_SERVER");
          dataProfile
            .closeSavingsAccount(accountId, selectedExternalAccount?.value)
            .then(next)
            .catch(() => {
              setStatus("ON_CLOSE_ERROR");
            });
        }}
      >
        {hasAccruedInterest && (
          <Card>
            <ExternalAccounts
              addWithdrawalAccountUrl={addWithdrawalAccountUrl}
              selectedExternalAccount={selectedExternalAccount}
              setSelectedExternalAccount={setSelectedExternalAccount}
            />
          </Card>
        )}
        <Card className="close-savings-account-confirmation">
          {status === "ON_CLOSE_ERROR" && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="closeSavingsAccountStory.confirmation.error" />
            </Snackbar>
          )}
          <Typography type="body">
            <TranslatedText
              id="closeSavingsAccountStory.confirmation.ingress"
              values={{
                accountName: account.name,
              }}
            />
          </Typography>
          <Typography type="body">
            <TranslatedText id="closeSavingsAccountStory.confirmation.download.textBefore" />
          </Typography>
          <div className="close-savings-account-confirmation-rows">
            <div className="flex">
              <span>
                <TranslatedText id="closeSavingsAccountStory.confirmation.download.accountPerformance" />
              </span>
              <Button
                component="a"
                variant="secondary"
                size="small"
                inline
                download
                href={
                  process.env.REACT_APP_API +
                  encode`/gdpr-export/performance/${account.accountId}`
                }
                label={
                  <TranslatedText id="closeSavingsAccountStory.confirmation.account.download" />
                }
              />
            </div>

            <div className="flex">
              <span>
                <TranslatedText id="closeSavingsAccountStory.confirmation.download.accountTransactions" />
              </span>
              <Button
                component="a"
                variant="secondary"
                size="small"
                download
                inline
                href={
                  process.env.REACT_APP_API +
                  encode`/gdpr-export/transactions/${account.accountId}`
                }
                label={
                  <TranslatedText id="closeSavingsAccountStory.confirmation.account.download.link" />
                }
              />
            </div>
          </div>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="closeSavingsAccountStory.confirmation.account.download.textAfter" />
          </Snackbar>
        </Card>

        {status === "READY_FOR_DELETION" && (
          <Button
            type="submit"
            block
            label={
              <TranslatedText id="closeSavingsAccountStory.confirmation.button" />
            }
          />
        )}
      </Form>
    </>
  );
};
