import React, { useEffect, useState, useCallback, useRef } from "react";
import cx from "classnames";
import { CardList, Typography } from "@lysaab/ui-2";
import { CompoundAccount } from "../../data/dataAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { dataTransfer } from "../../data/dataTransfer";
import {
  PendingInternalTransferEntry,
  PendingInternalTransferEntryProps,
} from "./PendingInternalTransferEntry";

import "./PendingInternalTransfers.scss";
import { TranslatedText } from "../../components/TranslatedText";
import { DateTime } from "luxon";

interface Props {
  account: CompoundAccount | undefined;
  accounts: CompoundAccount[];
  showHeader?: boolean;
}

const MAX_PENDING_TRANSFERS_SHOWN = 4;

export const PendingInternalTransfers: React.FC<Props> = ({
  account,
  accounts,
  showHeader = true,
}) => {
  const [pendingInternalTransfers, setPendingInternalTransfers] = useState<
    PendingInternalTransferEntryProps[]
  >([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  const load = useCallback(
    // TODO: Type this
    (account: any) => {
      dataTransfer.getPendingInternalTransfers().then((data) => {
        const filteredPendingInternalTransfers = data.filter(
          (pendingInternalTransfer) => {
            return (
              pendingInternalTransfer.toAccountId === account?.accountId ||
              pendingInternalTransfer.fromAccountId === account?.accountId
            );
          }
        );
        filteredPendingInternalTransfers.sort((a, b) => {
          return (
            DateTime.fromISO(b.created).toMillis() -
            DateTime.fromISO(a.created).toMillis()
          );
        });
        const mergedPendingInternalTransfers =
          filteredPendingInternalTransfers.map((internalTransfer) => {
            const fromAccount = accounts.find(
              (account) => account.accountId === internalTransfer.fromAccountId
            );
            const toAccount = accounts.find(
              (account) => account.accountId === internalTransfer.toAccountId
            );
            return {
              ...internalTransfer,
              fromAccount,
              toAccount,
            };
          });

        setPendingInternalTransfers(mergedPendingInternalTransfers);
        setStatus(Status.SUCCESS);
      });
    },
    [accounts]
  );

  useEffect(() => {
    if (!account) {
      return;
    }
    load(account);
  }, [account, load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => {
      load(account);
    }, 500);
  }, [account, load]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  let elem = null;
  let hasAdditionalPendingInternalTransfers =
    pendingInternalTransfers.length > MAX_PENDING_TRANSFERS_SHOWN;
  if (pendingInternalTransfers.length === 0) {
    elem = (
      <div>
        <TranslatedText id="accountPage.pending-internal-transfers.none" />
      </div>
    );
  } else {
    const items = pendingInternalTransfers.slice(
      0,
      MAX_PENDING_TRANSFERS_SHOWN
    );
    elem = (
      <div className="account-page-pending-internal-transfers-list">
        <ul>
          {items.map((item, index) => {
            return (
              <li key={item.created.toString() + index}>
                <PendingInternalTransferEntry
                  account={account}
                  pendingInternalTransfer={item}
                />
              </li>
            );
          })}
        </ul>
        <div className="additional-pending-internal-transfers-indicator" />
      </div>
    );
  }

  if (pendingInternalTransfers.length === 0) {
    return null;
  }

  return (
    <section
      className={cx("account-page-pending-internal-transfers", {
        "has-additional-pending-internal-transfers":
          hasAdditionalPendingInternalTransfers,
      })}
    >
      {showHeader && (
        <Typography type="h2">
          <TranslatedText id="accountPage.pending-internal-transfers.header" />
        </Typography>
      )}

      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              <strong>
                {hasAdditionalPendingInternalTransfers ? (
                  <TranslatedText
                    id="accountPage.pending-internal-transfers.recent"
                    values={{ name: account?.name }}
                  />
                ) : (
                  <TranslatedText
                    id="accountPage.pending-internal-transfers.all"
                    values={{ name: account?.name }}
                  />
                )}
              </strong>
              {elem}
            </div>
          </div>
        </Retry>
      </CardList>
    </section>
  );
};
