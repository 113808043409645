import React, { useReducer } from "react";

export interface NotificationsState {
  transactionsAcknowledged: boolean;
  messagesAcknowledged: boolean;
  unreadMessages: number;
}

export interface NotificationsContextProps {
  state: NotificationsState;
  setState: (newState: Partial<NotificationsState>) => void;
}

export const NotificationsContext =
  React.createContext<NotificationsContextProps>(
    {} as NotificationsContextProps
  );

function stateReducer(
  state: NotificationsState,
  newState: Partial<NotificationsState>
) {
  return { ...state, ...newState };
}

export const NotificationsContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    transactionsAcknowledged: false,
    messagesAcknowledged: false,
    unreadMessages: 0,
  });

  return (
    <NotificationsContext.Provider value={{ state, setState }}>
      {children}
    </NotificationsContext.Provider>
  );
};

export function useNotificationsContext() {
  const context = React.useContext(NotificationsContext);
  if (context === undefined) {
    throw new Error(
      "useNotificationsContext must be used within a NotificationsContextProvider"
    );
  }
  return context;
}
