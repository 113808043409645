import React, {
  FunctionComponent,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from "react";
import {
  BankIDStatus,
  PendingHintCode,
  FailedHintCode,
  OrderRef,
  useBankId,
  useDisablePollInBackground,
} from "@lysaab/ui-2";
import { IskTransferContext } from "./IskTransferContext";
import { BASE_ROUTES, ISK_TRANSFER_URL } from "./IskTransferStory";
import { useHistory, useLocation } from "react-router-dom";
import { dataIsk } from "../../../../data/dataIsk";
import { getNavLink } from "../../../../hooks/useCountryUrls";
import { useIntl, IntlShape } from "react-intl";
import {
  signingMessages,
  signPendingMessages,
  signFailedMessages,
} from "./BankIdMessages";
import { parse, stringify } from "query-string";
import { Location } from "history";

interface SearchParams {
  orderRef?: OrderRef;
  transferId?: number;
  at?: string;
}

export const useIskSignBankId = (onComplete: () => void) => {
  const history = useHistory();
  const location = useLocation();
  const urlParams = getParamsFromUrl(location);
  const { orderRef, autoStartToken, transferId } = urlParams;

  const qrCodePollFn = useCallback(
    () => dataIsk.pollQrCode(orderRef),
    [orderRef]
  );

  const pollFnRaw = useMemo(() => {
    return orderRef && transferId
      ? () => dataIsk.pollSigning(orderRef, transferId)
      : undefined;
  }, [orderRef, transferId]);
  const pollFn = useDisablePollInBackground(pollFnRaw);

  const { initiate, pollStatus, ...rest } = useBankId({
    onComplete,
    initPollFn: dataIsk.startSigning,
    pollFn,
    qrCodePollFn,
  });

  const startSign = useCallback(
    (transferId: number) => {
      if (pollStatus === "IDLE" || pollStatus === "FAILED") {
        initiate(transferId)
          .then((response) => {
            if (response) {
              history.push(
                getUrlWithParams(
                  location,
                  response.orderRef,
                  transferId,
                  response.autoStartToken
                )
              );
            }
          })
          .catch(() => {
            // If we get an error while initiating, we still want to transition
            // to the next step so BankIDStatus can show the error message.
            history.push(BASE_ROUTES.SIGN);
          });
      }
    },
    [history, initiate, location, pollStatus]
  );

  return { startSign, pollStatus, autoStartToken, transferId, ...rest };
};

type Props = ReturnType<typeof useIskSignBankId>;

export const IskSign: FunctionComponent<Props> = ({
  latestResponse,
  autoStartToken,
  transferId,
  qrCode,
  startSign,
  setOpenOnOtherDevice,
}) => {
  const iskTransferContext = useContext(IskTransferContext);
  const history = useHistory();
  const intl = useIntl();

  useEffect(() => {
    if (!iskTransferContext.state.transferId && !transferId) {
      // If we don't have a transferId at all, redirect to start
      history.push(getNavLink(ISK_TRANSFER_URL));
      return;
    } else if (transferId && !iskTransferContext.state.transferId) {
      // If we have a transferId in the url, but not context, user has
      // probably refreshed the page or gotten redirected back to it
      // from BankId in a new tab or browser, so we update the context.
      iskTransferContext.setState({ transferId });
    }
  }, [history, iskTransferContext, transferId]);

  return (
    <div>
      <BankIDStatus
        qrCode={qrCode}
        setOpenOnOtherDevice={setOpenOnOtherDevice}
        getMessages={getMessages(intl)}
        getPendingMessages={getPendingMessages(intl)}
        getFailedMessages={getFailedMessages(intl)}
        retry={() => {
          if (transferId) {
            startSign(transferId);
          } else {
            history.replace(getNavLink(ISK_TRANSFER_URL));
          }
        }}
        response={latestResponse}
        autoStartToken={autoStartToken}
      />
    </div>
  );
};

function getMessages(intl: IntlShape) {
  return () => {
    return {
      qrInfo1: intl.formatMessage(signingMessages.qrInfo1),
      qrInfo2: intl.formatMessage(signingMessages.qrInfo2),
      qrInfo3: intl.formatMessage(signingMessages.qrInfo3),
      buttonOpen: intl.formatMessage(signingMessages.buttonOpen),
      buttonErrorHeader: intl.formatMessage(signingMessages.buttonErrorHeader),
      buttonRetry: intl.formatMessage(signingMessages.buttonRetry),
      buttonClose: intl.formatMessage(signingMessages.buttonClose),
      buttonOtherDevice: intl.formatMessage(signingMessages.buttonOtherDevice),
    };
  };
}

function getPendingMessages(intl: IntlShape) {
  return (hintCode: PendingHintCode) =>
    intl.formatMessage(signPendingMessages[hintCode]);
}

function getFailedMessages(intl: IntlShape) {
  return (hintCode: FailedHintCode) =>
    intl.formatMessage(signFailedMessages[hintCode]);
}

function getUrlWithParams(
  location: Location,
  orderRef: OrderRef | undefined,
  transferId: number | undefined,
  autoStartToken: string
) {
  const search = parse(location.search) as SearchParams;
  search.orderRef = orderRef;
  search.transferId = transferId;
  search.at = autoStartToken;

  return {
    pathname: getNavLink(BASE_ROUTES.SIGN),
    search: stringify(search as Record<string, any>, { skipEmptyString: true }),
  };
}

function getParamsFromUrl(location: Location) {
  const search = parse(location.search) as SearchParams;
  return {
    orderRef: search.orderRef,
    autoStartToken: search.at,
    transferId: search.transferId,
  };
}
