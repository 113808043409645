import React from "react";
import { DateTime } from "luxon";
import { FormattedMessage, FormattedDate } from "react-intl";
import { NavLink } from "react-router-dom";
import "./AccountList.scss";
import { GridCol } from "../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../components/grid/gridRow/GridRow";
import { ClosedAccount } from "../../../../../data/dataProfile";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { MOVE_ACCOUNTING_PAGE_URL } from "./MoveAccounting";

interface Props {
  title: string;
  accounts: ClosedAccount[];
}

export const AccountList = ({ title, accounts }: Props) => {
  if (!accounts || !accounts.length) return null;
  return (
    <GridRow className="account-list">
      {title ? (
        <GridCol xsmall={12}>
          <h4>{title}</h4>
        </GridCol>
      ) : null}
      {accounts.map((account) => {
        const params = new URLSearchParams();

        params.set("accountId", account.accountId);

        return (
          <GridCol
            xsmall={6}
            small={4}
            large={12}
            className="account-list-item"
            key={`accounts-${account.accountId}`}
          >
            <ul>
              <li>
                <span className="account-list-item-title">
                  <b>{account.name}</b>
                </span>
              </li>
              <li>
                <NavLink
                  to={{
                    pathname: getNavLink(MOVE_ACCOUNTING_PAGE_URL),
                    search: params.toString(),
                  }}
                >
                  <FormattedMessage id="sweden.accountingPage.account.link" />
                </NavLink>
              </li>
              <li>
                <span className="account-list-item-date">
                  (<FormattedDate value={account.created} />
                  {" - "}
                  {account.hasOwnProperty("closed") ? (
                    <FormattedDate
                      value={DateTime.fromISO(
                        (account as ClosedAccount).closed
                      ).toJSDate()}
                    />
                  ) : (
                    <FormattedMessage id="sweden.accountingPage.account.date.now" />
                  )}
                  )
                </span>
              </li>
            </ul>
          </GridCol>
        );
      })}
    </GridRow>
  );
};
