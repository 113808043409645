import React, { useMemo } from "react";
import { QRCodeCanvas } from "qrcode.react";
import "./BankgiroQrCode.scss";
import { DateTime } from "luxon";

interface Props {
  referenceNumber: string;
  bgNumber: string;
  size?: number;
}

const RECIPIENT_NAME = "Lysa AB";
const ORGANISATION_NUMBER = "559028-0821";

// PDF download with information about payment QRcode data and options reference
// https://web.archive.org/web/20140519163350/http://www.usingqr.se/wp-content/themes/usingqr/ui-twelve/functions/ajax/file-downloader.php
export const BankgiroQrCode: React.VFC<Props> = ({
  referenceNumber,
  bgNumber,
  size = 160,
}) => {
  const dueDate = useMemo(
    () => DateTime.now().toISODate({ format: "basic" }),
    []
  );

  const qrData = useMemo(
    () => ({
      uqr: 1,
      tp: 1,
      nme: RECIPIENT_NAME,
      cid: ORGANISATION_NUMBER,
      iref: referenceNumber,
      pt: "BG",
      acc: bgNumber,
      // Required by IcaBanken, SEB & Nordea
      ddt: dueDate,
      // Required by at least Handelsbanken
      due: 0,
    }),
    [bgNumber, dueDate, referenceNumber]
  );

  return (
    <div className="bankgiro-qr-code">
      <QRCodeCanvas value={JSON.stringify(qrData)} size={size} />
    </div>
  );
};
