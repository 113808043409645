import React, { FunctionComponent } from "react";
import {
  AccountType,
  isSavingsAccount,
  CompoundAccount,
} from "../../data/dataAccounts";

interface Props {
  account: CompoundAccount;
  size?: number;
}

export const AccountTypeLogo: FunctionComponent<Props> = ({
  account,
  size = 40,
}) => {
  if (isSavingsAccount(account)) {
    return <CreateAccountTypeLogo color="#7842F5" text="SPAR" size={size} />;
  }

  switch (account.type) {
    case AccountType.ISK_SWE:
      return <CreateAccountTypeLogo color="#09BA96" text="ISK" size={size} />;
    case AccountType.DANICA_KF:
    case AccountType.KF_SWE:
      return <CreateAccountTypeLogo color="#B716DF" text="KF" size={size} />;
    case AccountType.VP_SWE:
      return <CreateAccountTypeLogo color="#323C60" text="VP" size={size} />;
    case AccountType.TJP_SWE:
      return <CreateAccountTypeLogo color="#DF5216" text="TJP" size={size} />;
    case AccountType.VP:
      return <CreateAccountTypeLogo color="#09BA96" text="LYSA" size={size} />;
    case AccountType.LYSA_TJP:
      return <CreateAccountTypeLogo color="#DF5216" text="TJP" size={size} />;
    case AccountType.LYSA_PPF:
      return <CreateAccountTypeLogo color="#DF5216" text="PPF" size={size} />;
  }
};

interface CreateAccountTypeLogoProps {
  color: string;
  text: string;
  size: number;
}

export const CreateAccountTypeLogo: FunctionComponent<
  CreateAccountTypeLogoProps
> = ({ color, text, size }) => {
  const fontSize = size / 3.5;
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx={size / 2} cy={size / 2} r={size / 2} fill={color} />
      <text
        x="50%"
        y="50%"
        textAnchor="middle"
        dominantBaseline="central"
        fontSize={fontSize}
        fontWeight="bold"
        fill="white"
      >
        {text}
      </text>
    </svg>
  );
};
