import React, { FunctionComponent, useRef, useState, useContext } from "react";
import {
  Form,
  TextInput,
  RequiredValidator,
  PasswordInput,
  Button,
  LysaFormRef,
  Card,
  Spinner,
  Snackbar,
  SNACKBAR_TYPES,
  MinLengthValidator,
  BaseValidator,
  DanishTinValidator,
  SwedishTinValidator,
  FinnishTinValidator,
  AllTinValidatorTypes,
  SpanishTinValidator,
} from "@lysaab/ui-2";
import { useIntl, defineMessages } from "react-intl";
import { dataLogin, InitVerificationResult } from "../../data/dataLogin";
import { LocalizationContext } from "../../context/LocalizationContext";
import { OtpType } from "./LoginUsernamePasswordPage";
import { normalizeTin } from "../../utils/TinNormalizer";
import { LysaCountry } from "@lysaab/shared";
import { GermanUsernameValidator } from "./validators/GermanUsernameValidator";

interface Props {
  username: string;
  setUsername: (username: string) => void;
  password: string;
  setPassword: (password: string) => void;
  setOtpType: (otpType: OtpType) => void;
}

const messages = defineMessages({
  tinLabel: {
    id: "login.tin.label",
    description: "Login tin input label",
    defaultMessage: `{
      country, select,
        DK {
          CPR-nummer
        }
        FI {
          HETU/personnummer
        }
        SE {
          Personnummer
        }
        DE {
          Steuer-ID
        }
        ES {
          NIF, Número de Identificación Fiscal
        }
        other {
          Taxpayer Identification Number
        }
    }`,
  },
  tinRequired: {
    id: "login.tin.required",
    description: "Login tin required message",
    defaultMessage: "This field is required",
  },
  tinValidation: {
    id: "login.tin.validation",
    description: "Message to show when tin validation fails",
    defaultMessage: `{
      country, select,
        DK {
          Please provide a valid CPR-nummer
        }
        FI {
          Please provide a valid HETU/personnummer
        }
        SE {
          Please provide a valid personnummer
        }
        DE {
          Please provide a valid Steuer-ID
        }
        ES {
          Please provide a valid NIF, Número de Identificación Fiscal
        }
        other {
          Please provide a valid TIN
        }
    }`,
  },
  passwordLabel: {
    id: "login.password.label",
    description: "Login password label",
    defaultMessage: "Password",
  },
  passwordRequired: {
    id: "login.password.error.required",
    description: "Login password required error",
    defaultMessage: "Password is required",
  },
  passwordTooShort: {
    id: "login.password.error.too.short",
    description: "Login password too short error",
    defaultMessage: "Password is too short",
  },
  nextButton: {
    id: "login.username.button.next",
    description: "Login username step next button",
    defaultMessage: "Next",
  },
  errorHeader: {
    id: "login.username.error.header",
    description: "Login username step error header",
    defaultMessage: "Error",
  },
  errorFailed: {
    id: "login.username.error.failed",
    description: "Login username step error: failed",
    defaultMessage: "Login failed, please try again",
  },
  errorRateLimit: {
    id: "login.username.error.rate.limit",
    description: "Login username step error: rate limit",
    defaultMessage:
      "You've made too many failed tries. Please wait a while before trying again.",
  },
  errorServerError: {
    id: "login.username.error.server",
    description: "Login username step error: server error",
    defaultMessage:
      "An unexpected server error occurred. Please try again later.",
  },
});

type UsernameValidators = AllTinValidatorTypes | typeof GermanUsernameValidator;

const countryTinValidatorMap: Record<LysaCountry, UsernameValidators> = {
  [LysaCountry.DENMARK]: DanishTinValidator,
  [LysaCountry.FINLAND]: FinnishTinValidator,
  [LysaCountry.SWEDEN]: SwedishTinValidator,
  [LysaCountry.GERMANY]: GermanUsernameValidator,
  [LysaCountry.SPAIN]: SpanishTinValidator,
};

export const PasswordStep: FunctionComponent<Props> = ({
  username,
  setUsername,
  password,
  setPassword,
  setOtpType,
}) => {
  const intl = useIntl();
  const lysaForm = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<InitVerificationResult>();

  const tinValidators: BaseValidator[] = [
    new RequiredValidator(intl.formatMessage(messages.tinRequired)),
  ];

  const country = localizationContext.state.country;

  if (!country) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  const CountryTinValidator = countryTinValidatorMap[country as LysaCountry];

  if (CountryTinValidator) {
    const msg = intl.formatMessage(messages.tinValidation, {
      country,
    });
    tinValidators.push(new CountryTinValidator(msg));
  }

  return (
    <div>
      <Form
        lysaFormRef={lysaForm}
        onSubmit={() => {
          if (lysaForm.current?.isValid && country) {
            setIsLoading(true);

            const normalizedTin = normalizeTin(username.trim(), country);

            if (!normalizedTin) {
              console.error("Could not normalize tin", username);
              return;
            }

            dataLogin
              .login(normalizedTin, password, country)
              .then((response) => {
                setIsLoading(false);
                if (response.result === InitVerificationResult.EMAIL_CODE) {
                  setOtpType(OtpType.EMAIL);
                } else if (
                  response.result === InitVerificationResult.TOTP_CODE
                ) {
                  setOtpType(OtpType.TOTP);
                } else {
                  setError(response.result);
                  setPassword("");
                  lysaForm.current?.setForceErrors(false);
                }
              })
              .catch(() => {
                setIsLoading(false);
                setError(InitVerificationResult.ERROR);
                setPassword("");
                lysaForm.current?.setForceErrors(false);
              });
          }
        }}
      >
        {isLoading ? (
          <Spinner />
        ) : (
          <React.Fragment>
            <Card>
              <TextInput
                label={intl.formatMessage(messages.tinLabel, {
                  country: localizationContext.state.country,
                })}
                value={username}
                onChange={setUsername}
                validators={tinValidators}
              />
              <PasswordInput
                label={intl.formatMessage(messages.passwordLabel)}
                value={password}
                onChange={setPassword}
                validators={[
                  new RequiredValidator(
                    intl.formatMessage(messages.passwordRequired)
                  ),
                  new MinLengthValidator(
                    10,
                    intl.formatMessage(messages.passwordTooShort)
                  ),
                ]}
              />
            </Card>
            {error === InitVerificationResult.FAILED && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <h3>{intl.formatMessage(messages.errorHeader)}</h3>
                  <p>{intl.formatMessage(messages.errorFailed)}</p>
                </div>
              </Snackbar>
            )}
            {(error === InitVerificationResult.LOGIN_TEMP_LOCKED ||
              error === InitVerificationResult.RATE_LIMIT) && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <h3>{intl.formatMessage(messages.errorHeader)}</h3>
                  <p>{intl.formatMessage(messages.errorRateLimit)}</p>
                </div>
              </Snackbar>
            )}
            {error === InitVerificationResult.ERROR && (
              <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
                <div>
                  <p>
                    <b>{intl.formatMessage(messages.errorHeader)}</b>
                  </p>
                  <p>{intl.formatMessage(messages.errorServerError)}</p>
                </div>
              </Snackbar>
            )}
            <Button
              block
              type="submit"
              label={intl.formatMessage(messages.nextButton)}
            />
          </React.Fragment>
        )}
      </Form>
    </div>
  );
};
