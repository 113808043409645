import React, { useEffect, useState } from "react";
import { Amount } from "../amount/Amount";
import { AccountType } from "../accountType/AccountType";
import { RiskIndicator, NewIcon } from "@lysaab/ui-2";
import {
  CompoundAccount,
  getAccountAmount,
  isSavingsAccount,
} from "../../data/dataAccounts";
import {
  CombinedAdviseAccount,
  dataInvestments,
} from "../../data/dataInvestments";
import "./AccountName.scss";

interface Props {
  account: CompoundAccount;
  showAmount?: boolean;
  showRisk?: boolean;
  showAccountType?: boolean;
  digits?: number;
  showSharedIndicator?: boolean;
  showSavingsAccountIcon?: boolean;
}

export const AccountName: React.FC<Props> = ({
  account,
  showAmount = true,
  showRisk = false,
  showAccountType = false,
  digits = 0,
  showSharedIndicator = false,
  showSavingsAccountIcon = false,
}) => {
  const [accountAdvice, setAccountAdvice] = useState<CombinedAdviseAccount>();

  useEffect(() => {
    if (isSavingsAccount(account)) {
      return;
    }
    dataInvestments.getAdviseAccounts().then((response) => {
      setAccountAdvice(
        response.find(
          (allocation) => allocation.accountId === account.accountId
        )
      );
    });
  }, [account]);

  return (
    <div className="account-name">
      {showSharedIndicator && (
        <NewIcon.Accounts className="account-name-shared" size={22} />
      )}
      <strong>{account.name}</strong>

      <div>
        {showAccountType ? (
          <div className="account-name-type">
            <AccountType account={account} />
          </div>
        ) : null}
        {showAmount ? (
          <>
            &nbsp;-&nbsp;
            <Amount amount={getAccountAmount(account)} decimals={digits} />
          </>
        ) : null}
        {showRisk && accountAdvice ? (
          <RiskIndicator
            risk={accountAdvice?.takenRisk}
            investmentType={accountAdvice.investmentType}
          />
        ) : null}
        {showSavingsAccountIcon && (
          <NewIcon.SavingsAccount className="account-name-savings-account" />
        )}
      </div>
    </div>
  );
};
