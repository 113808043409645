import {
  Button,
  LysaLink,
  ServerError,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router";
import { EventTracker } from "../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../context/LocalizationContext";
import {
  dataSwish,
  InitiateSwishSuccessResponse,
  isInitiateSwishSuccessResponse,
  SwishStatus,
} from "../../../../../../data/dataSwish";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import {
  DeviceType,
  useDeviceType,
} from "../../../../../../hooks/useDeviceType";
import { SwishDepositContext } from "../SwishDepositContext";
import { BASE_ROUTES, SWISH_DEPOSIT_PAGE_URL } from "../SwishDepositPage";
import { QRCodeCanvas } from "qrcode.react";
import { Location } from "history";
import { DateTime } from "luxon";
import { Link } from "react-router-dom";
import { MESSAGES_PAGE_URL } from "../../../../../../pages/messages/MessagesPage";
import { DEPOSITS_OVERVIEW_URL } from "../../../../../../pages/deposits/overview/Recommendation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
  TrackerEvent,
} from "../../../../../../data/dataCustomerTracking";

import "./SwishDepositQR.scss";
interface Props {
  next: () => void;
  onError: (error: string) => void;
}

const VERIFICATION_TIMER = 3.5; // Minutes

export const SwishDepositQR = ({ next, onError }: Props) => {
  const history = useHistory();
  const [swishState, setSwishState] = useState<InitiateSwishSuccessResponse>();
  const swishDepositContext = useContext(SwishDepositContext);
  const deviceType = useDeviceType();
  const timer = useRef<NodeJS.Timeout>();
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const [isVerificationDelay, setIsVerificationDelay] = useState(false);
  const searchParams = new URLSearchParams(history.location.search);
  const hasInitiated = useRef(searchParams.has("transactionId"));
  const onErrorRef = useRef(onError);

  useEffect(() => {
    onErrorRef.current = onError;
  }, [onError]);

  const poll = useCallback(
    (transactionId: string) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        dataSwish
          .pollStatus(transactionId)
          .then((response) => {
            if (response.status === SwishStatus.CREATED) {
              const searchParams = new URLSearchParams(history.location.search);
              if (!searchParams.has("t")) {
                return;
              }
              const time = searchParams.get("t");
              const diff = DateTime.local().diff(
                DateTime.fromMillis(Number(time)),
                "minutes"
              ).minutes;
              if (diff < VERIFICATION_TIMER) {
                poll(transactionId);
              } else {
                setIsVerificationDelay(true);
              }
            } else if (response.status === SwishStatus.PAID) {
              if (!swishDepositContext.state.accountId) {
                swishDepositContext.setState({
                  accountId: response.payload.accountId,
                  amount: response.payload.amount,
                });
              }
              dataCustomerTrackingService.postEvent({
                domain: FeatureDomain.TRANSFERS,
                subDomain: SubDomain.DEPOSIT,
                eventName: "swishDepositSuccess",
              });
              EventTracker.track({
                event: TrackerEvent.DEPOSIT,
                message: intl.formatNumber(Number(response.payload.amount), {
                  style: "currency",
                  currency: localizationContext.state.currency,
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2,
                }),
              });
              next();
            } else if (response.status === SwishStatus.ERROR) {
              EventTracker.track({
                event: TrackerEvent.ERROR,
                message: response.swishError,
              });
              if (!swishDepositContext.state.accountId) {
                swishDepositContext.setState({
                  accountId: response.payload.accountId,
                  amount: response.payload.amount,
                });
              }
              onErrorRef.current(response.swishError);
            } else if (response.status === SwishStatus.DECLINED) {
              EventTracker.track({
                event: TrackerEvent.ERROR,
                message: response.status,
              });

              if (!swishDepositContext.state.accountId) {
                swishDepositContext.setState({
                  accountId: response.payload.accountId,
                  amount: response.payload.amount,
                });
              }
              onErrorRef.current("DECLINED");
            }
          })
          .catch((error: ServerError<unknown>) => {
            EventTracker.track({
              event: TrackerEvent.ERROR,
              message: error.message,
            });
            onErrorRef.current(error.statusText);
          });
      }, 3000);
    },
    [
      history.location.search,
      intl,
      localizationContext.state.currency,
      next,
      swishDepositContext,
    ]
  );

  const init = useCallback(() => {
    if (
      !swishDepositContext.state.accountId ||
      !swishDepositContext.state.amount
    ) {
      return;
    }
    EventTracker.track({
      event: TrackerEvent.INITIATE_SWISH,
      message: intl.formatNumber(swishDepositContext.state.amount, {
        style: "currency",
        currency: localizationContext.state.currency,
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }),
    });
    dataSwish
      .initiate({
        accountId: swishDepositContext.state.accountId,
        amount: swishDepositContext.state.amount,
      })
      .then((response) => {
        if (isInitiateSwishSuccessResponse(response)) {
          setSwishState(response);
          history.replace(getUrlWithTimeParam(history.location));
          poll(response.transactionId);
        } else {
          EventTracker.track({
            event: TrackerEvent.ERROR,
            message: "Failed to initiate swish request - missing transactionId",
          });
        }
      })
      .catch((error) => {
        EventTracker.track({
          event: TrackerEvent.ERROR,
          message: error.message,
        });
        onErrorRef.current(error.statusText);
      });
  }, [
    history,
    intl,
    localizationContext.state.currency,
    poll,
    swishDepositContext.state.accountId,
    swishDepositContext.state.amount,
  ]);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);

    if (
      !swishDepositContext.state.amount &&
      !searchParams.has("transactionId")
    ) {
      history.push(getNavLink(SWISH_DEPOSIT_PAGE_URL));
    }
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
    /**
     * This should only be done only when the page is starting
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    if (searchParams.has("transactionId")) {
      const transactionId = searchParams.get("transactionId");
      if (transactionId) {
        poll(transactionId);
      }
    }
  }, [history.location.search, poll]);

  useEffect(() => {
    if (hasInitiated.current) {
      return;
    }
    hasInitiated.current = true;
    init();
  }, [init]);

  return (
    <div className="swish-deposit-qr">
      <h1>
        <TranslatedText id="sweden.deposits.swish.story.qr.header" />
      </h1>
      <>
        {isVerificationDelay ? (
          <>
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText
                id="sweden.deposits.swish.story.qr.swish-not-verified"
                values={{
                  link: (text) => {
                    return (
                      <LysaLink
                        component={Link}
                        to={getNavLink(MESSAGES_PAGE_URL)}
                      >
                        {text}
                      </LysaLink>
                    );
                  },
                }}
              />
            </Snackbar>
            <Button
              block
              component={Link}
              to={getNavLink(DEPOSITS_OVERVIEW_URL)}
              label={
                <TranslatedText id="sweden.deposits.swish.story.qr.overview-button" />
              }
            />
          </>
        ) : (
          <div className="qr-container">
            <Spinner />
            {swishState &&
              (deviceType !== DeviceType.MOBILE ||
                typeof deviceType === "undefined") && (
                <div className="qr-desktop">
                  <ol>
                    <li>
                      <TranslatedText id="sweden.deposits.swish.story.qr.instructions-1" />
                    </li>
                    <li>
                      <TranslatedText id="sweden.deposits.swish.story.qr.instructions-2" />
                    </li>
                    <li>
                      <TranslatedText id="sweden.deposits.swish.story.qr.instructions-3" />
                    </li>
                  </ol>
                  {typeof swishState.qrCode !== "undefined" ? (
                    <img
                      alt="QR code"
                      src={`data:image/svg+xml;base64,${swishState.qrCode}`}
                    />
                  ) : (
                    <QRCodeCanvas value={`D${swishState.token}`} size={200} />
                  )}
                </div>
              )}
            {swishState &&
              (deviceType === DeviceType.MOBILE ||
                deviceType === DeviceType.TABLET ||
                typeof deviceType === "undefined") && (
                <Button
                  onClick={() => {
                    const search = new URLSearchParams(history.location.search);
                    if (
                      !search.get("transactionId") ||
                      swishState.transactionId !== search.get("transactionId")
                    ) {
                      search.set("transactionId", swishState.transactionId);
                      history.replace({
                        pathname: getNavLink(BASE_ROUTES.QR),
                        search: search.toString(),
                      });
                    }
                    tryOpenSwishApp(swishState.token);
                  }}
                  block
                  className="open-button"
                  label={
                    <TranslatedText id="sweden.deposits.swish.story.qr.button" />
                  }
                />
              )}
          </div>
        )}
      </>
    </div>
  );
};

function tryOpenSwishApp(token: string) {
  const location = window.location.href;
  const search = new URLSearchParams({
    token: token,
    callbackurl: encodeURIComponent(location),
  });
  const url = new URL("swish://paymentrequest");
  url.search = search.toString();
  window.location.href = url.toString();
}

function getUrlWithTimeParam(location: Location) {
  const search = new URLSearchParams(location.search);
  if (search.has("transactionId")) {
    search.delete("transactionId");
  }
  search.set("t", DateTime.local().toMillis().toString());

  return {
    pathname: getNavLink(BASE_ROUTES.QR),
    search: search.toString(),
  };
}
