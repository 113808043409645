import { CardList, CardListItem, LysaLink, Typography } from "@lysaab/ui-2";
import React from "react";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { BASE_ROUTES } from "../../closeLysaCustomerAccountStory/CloseLysaCustomerStory";

export const CloseLysaCustomerAccount = () => {
  return (
    <React.Fragment>
      <Typography type="h2">
        <TranslatedText id="endInvestment.closeLysaCustomerAccount.header" />
      </Typography>
      <CardList>
        <CardListItem>
          <LysaLink component={Link} to={getNavLink(BASE_ROUTES.INTRO)}>
            <TranslatedText id="endInvestment.closeLysaCustomerAccount.link" />
          </LysaLink>
        </CardListItem>
      </CardList>
    </React.Fragment>
  );
};
