import { Story } from "@lysaab/ui-2";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Route, useHistory, useLocation } from "react-router";
import { Switch } from "../../components/route/Switch";
import { SavingsAccount } from "../../data/dataAccounts";
import {
  InternalTransferSavingsAccount,
  MergedInternalTransferAccount,
} from "../../data/dataTransfer";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useSafeNavigation } from "../../hooks/useSafeNavigation";
import { useStoryValues } from "../../hooks/useStoryValues";
import { OVERVIEW_PAGE_URL } from "../overview/OverviewPage";
import { PageStripped } from "../PageStripped";
import { InternalTransferAmount } from "./amount/InternalTransferAmount";
import { InternalTransferDone } from "./done/InternalTransferDone";
import { InternalTransferFromAccount } from "./fromAccount/InternalTransferFromAccount";
import { InternalTransferContextProvider } from "./InternalTransferContext";
import { InternalTransferSavingsAccountAmount } from "./internalTransferSavingsAccountAmount/InternalTransferSavingsAccountAmount";
import { InternalTransferToAccount } from "./toAccount/InternalTransferToAccount";
import * as H from "history";
import { MergedInvestmentAccount } from "../withdrawal/request/WithdrawalContext";

export const INTERNAL_TRANSFER_PAGE_URL = "/internal-transfer";

const messages = defineMessages({
  header: {
    id: "internal-transfer.story.header",
  },
  ariaProgressLabel: {
    id: "internal-transfer.story.ariaProgressLabel",
  },
});

export const BASE_ROUTES = {
  FROM: `${INTERNAL_TRANSFER_PAGE_URL}/`,
  TO: `${INTERNAL_TRANSFER_PAGE_URL}/to`,
  AMOUNT: `${INTERNAL_TRANSFER_PAGE_URL}/amount`,
  SAVINGS_ACCOUNT_AMOUNT: `${INTERNAL_TRANSFER_PAGE_URL}/savings-account-amount`,
  DONE: `${INTERNAL_TRANSFER_PAGE_URL}/done`,
};

export interface FromInternalTransfer {
  fromAccount: MergedInvestmentAccount;
}

interface FromInternalTransferRedirect {
  returnLocation?: H.History.LocationDescriptor<FromInternalTransfer>;
}

export function InternalTransferPage() {
  const intl = useIntl();
  const history = useHistory();
  const safeNavigation = useSafeNavigation();
  const location = useLocation<FromInternalTransferRedirect>();
  const [currentIndex, ROUTES, storyProgress, storyLength] =
    useStoryValues(BASE_ROUTES);

  const onBack = (currentIndex: number) => {
    const savingsAccountAmountIndex = Object.values(ROUTES).indexOf(
      ROUTES.SAVINGS_ACCOUNT_AMOUNT
    );
    if (
      currentIndex === 0 ||
      currentIndex >= Object.values(ROUTES).indexOf(ROUTES.DONE)
    ) {
      return;
    } else if (currentIndex === savingsAccountAmountIndex) {
      safeNavigation(ROUTES.TO);
      return;
    } else {
      safeNavigation(Object.values(ROUTES)[currentIndex - 1]);
      return;
    }
  };

  return (
    <PageStripped>
      <InternalTransferContextProvider>
        <Story
          ariaLabelProgress={() =>
            intl.formatMessage(messages.ariaProgressLabel, {
              current: currentIndex + 1,
              total: storyLength,
            })
          }
          header={intl.formatMessage(messages.header)}
          onExit={() => {
            safeNavigation(getNavLink(OVERVIEW_PAGE_URL));
          }}
          showClose={true}
          showBack={
            currentIndex > 0 && currentIndex < Object.values(ROUTES).length - 1
          }
          onBack={() => {
            if (location.state?.returnLocation) {
              return history.push(location.state.returnLocation);
            }
            onBack(currentIndex);
          }}
          transitionKey={currentIndex.toString()}
          progress={storyProgress}
        >
          <Switch
            location={location}
            {...{
              order: currentIndex,
            }}
          >
            <Route path={ROUTES.FROM} exact>
              <InternalTransferFromAccount
                next={() => safeNavigation(ROUTES.TO)}
              />
            </Route>
            <Route path={ROUTES.TO} exact>
              <InternalTransferToAccount
                next={(fromAccount: MergedInternalTransferAccount) =>
                  isMergedSavingsAccount(fromAccount)
                    ? safeNavigation(ROUTES.SAVINGS_ACCOUNT_AMOUNT)
                    : safeNavigation(ROUTES.AMOUNT)
                }
              />
            </Route>
            <Route path={ROUTES.AMOUNT} exact>
              <InternalTransferAmount
                next={(isDrain: boolean) =>
                  safeNavigation({ pathname: ROUTES.DONE, state: { isDrain } })
                }
              />
            </Route>
            <Route path={ROUTES.SAVINGS_ACCOUNT_AMOUNT} exact>
              <InternalTransferSavingsAccountAmount
                next={() => safeNavigation(ROUTES.DONE)}
              />
            </Route>
            <Route path={ROUTES.DONE} exact>
              <InternalTransferDone />
            </Route>
          </Switch>
        </Story>
      </InternalTransferContextProvider>
    </PageStripped>
  );
}

export const isMergedSavingsAccount = (
  account?: MergedInternalTransferAccount
): account is SavingsAccount & InternalTransferSavingsAccount => {
  if (typeof account === "undefined") {
    return false;
  }
  return "savingsAccountDistributions" in account;
};
