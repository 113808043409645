import React, { FunctionComponent, useContext, useEffect, useRef } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { useHistory } from "react-router";
import { getNavLink } from "../../hooks/useCountryUrls";
import { TRANSFERS_MENU_PAGE_URL } from "../../pages/transfersMenu/TransfersMenuPage";
import { OVERVIEW_PAGE_URL } from "../../pages/overview/OverviewPage";
import { PROFILE_PAGE_URL } from "../../pages/profile/ProfilePage";
import { useIsSignedIn } from "../../hooks/useIsSignedIn";
import { BurgerMenuContext } from "./BurgerMenuContext";
import { Button, StatusLabel, STATUS_TYPE, NewIcon } from "@lysaab/ui-2";
import { UserContext } from "../../context/UserContext";
import { useIntl } from "react-intl";
import { useKBar } from "kbar";
import { messages } from "./BurgerMenuMessages";
import { LOGOUT_PAGE_URL } from "../../pages/logout/LogoutPage";
import { truncate } from "../../utils/Truncate";
import { RouteAwareToggle } from "../route/RouteAwareToggle";
import { SWITCH_USER_SELECTION_PAGE_URL } from "../../countries/sweden/switchUser/SwitchUserSelection";
import { MESSAGES_PAGE_URL } from "../../pages/messages/MessagesPage";
import { INVITE_PAGE_URL } from "../../pages/invite/InvitePage";
import { CONTACT_PAGE_URL } from "../../pages/contact/ContactPage";
import { useNotifications } from "../../hooks/useNotifications";
import "./BurgerMenu.scss";

export const BURGER_MENU_ARIA_ID = "burger-menu";

export const BurgerMenu: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const history = useHistory();
  const isSignedIn = useIsSignedIn();
  const burgerMenuContext = useContext(BurgerMenuContext);
  const isOpen = burgerMenuContext.state.isOpen;
  const setMenuContext = burgerMenuContext.setState;
  const userContext = useContext(UserContext);
  const intl = useIntl();
  const { unreadMessages } = useNotifications();
  const cID = useRef<NodeJS.Timeout>();
  const { query } = useKBar();

  useEffect(() => {
    setMenuContext({ isOpen: false });
  }, [setMenuContext, history.location]);

  if (!isSignedIn) {
    return <React.Fragment>{children}</React.Fragment>;
  }

  return (
    <div className="burger-menu-wrapper">
      <AnimatePresence>
        {isOpen && (
          <motion.div
            className="burger-menu-overlay"
            key="overlay"
            onClick={() => {
              setMenuContext({ isOpen: !isOpen });
            }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
          />
        )}

        {isOpen && (
          <motion.div
            className="burger-menu"
            key="menu"
            initial={{ x: "-100%" }}
            animate={{ x: 0 }}
            exit={{ x: "-100%" }}
            transition={{ type: "spring", stiffness: 300, damping: 27 }}
            role="navigation"
            id={BURGER_MENU_ARIA_ID}
          >
            <div className="burger-menu-item-wrapper">
              <div>
                <div className="burger-menu-burger">
                  <button
                    className="transparent-button"
                    type="button"
                    onClick={() => {
                      setMenuContext({ isOpen: !isOpen });
                    }}
                    tabIndex={isOpen ? 0 : -1}
                  >
                    <NewIcon.Cancel />
                  </button>
                  {userContext.state.multipleRights && (
                    <div className="current-user">
                      <span className="current-user-label">
                        {intl.formatMessage(messages.currentUser)}
                      </span>
                      <span>{truncate(userContext.state.name ?? "", 28)}</span>
                    </div>
                  )}
                </div>
                <hr />
                <div>
                  <RouteAwareToggle path={getNavLink(OVERVIEW_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Bank />}
                      text={intl.formatMessage(messages.overviewButton)}
                      onClick={() => {
                        history.push(getNavLink(OVERVIEW_PAGE_URL));
                      }}
                      isOpen={isOpen}
                    />
                  </RouteAwareToggle>
                  <RouteAwareToggle path={getNavLink(TRANSFERS_MENU_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Transfer />}
                      text={intl.formatMessage(messages.transfersButton)}
                      onClick={() => {
                        history.push(getNavLink(TRANSFERS_MENU_PAGE_URL));
                      }}
                      isOpen={isOpen}
                    />
                  </RouteAwareToggle>
                  <hr />
                  <RouteAwareToggle path={getNavLink(PROFILE_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Profile />}
                      text={intl.formatMessage(messages.profileButton)}
                      onClick={() => {
                        history.push(getNavLink(PROFILE_PAGE_URL));
                      }}
                      isOpen={isOpen}
                    />
                  </RouteAwareToggle>
                  <RouteAwareToggle path={getNavLink(MESSAGES_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Message />}
                      text={intl.formatMessage(messages.messagesButton)}
                      onClick={() => {
                        history.push(getNavLink(MESSAGES_PAGE_URL));
                      }}
                      isOpen={isOpen}
                      notificationCount={unreadMessages}
                    />
                  </RouteAwareToggle>
                  <RouteAwareToggle path={getNavLink(INVITE_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Invite />}
                      text={intl.formatMessage(messages.inviteButton)}
                      onClick={() => {
                        history.push(getNavLink(INVITE_PAGE_URL));
                      }}
                      isOpen={isOpen}
                    />
                  </RouteAwareToggle>

                  {userContext.state.multipleRights && (
                    <RouteAwareToggle
                      path={getNavLink(SWITCH_USER_SELECTION_PAGE_URL)}
                    >
                      <MenuItem
                        icon={<NewIcon.Accounts />}
                        text={intl.formatMessage(messages.switchButton)}
                        onClick={() => {
                          history.push(
                            getNavLink(SWITCH_USER_SELECTION_PAGE_URL)
                          );
                        }}
                        isOpen={isOpen}
                      />
                    </RouteAwareToggle>
                  )}
                  <MenuItem
                    icon={<NewIcon.HelpOutline />}
                    text={intl.formatMessage(messages.helpButton)}
                    onClick={() => {
                      if (window._elev) {
                        window._elev.openHome();
                      }
                    }}
                    isOpen={isOpen}
                  />
                  <RouteAwareToggle path={getNavLink(CONTACT_PAGE_URL)}>
                    <MenuItem
                      icon={<NewIcon.Phone />}
                      text={intl.formatMessage(messages.contactButton)}
                      onClick={() => {
                        history.push(getNavLink(CONTACT_PAGE_URL));
                      }}
                      isOpen={isOpen}
                    />
                  </RouteAwareToggle>
                </div>
              </div>
              <div>
                <Button
                  variant="secondary"
                  onClick={() => {
                    history.push(getNavLink(LOGOUT_PAGE_URL));
                  }}
                  block
                  tabIndex={isOpen ? 0 : -1}
                  label={intl.formatMessage(messages.logoutButton)}
                />
              </div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
      {children}
      <button
        className="transparent-button floating-burger-menu"
        type="button"
        onPointerDown={() => {
          clearTimeout(cID.current);
          cID.current = setTimeout(() => {
            query.toggle();
          }, 3000);
        }}
        onPointerUp={() => {
          clearTimeout(cID.current);
        }}
        onPointerCancel={() => {
          clearTimeout(cID.current);
        }}
        onClick={() => {
          clearTimeout(cID.current);
          burgerMenuContext.setState({ isOpen: !isOpen });
        }}
      >
        <NewIcon.Menu />
      </button>
    </div>
  );
};

interface MenuItemProps {
  icon: JSX.Element;
  text: string;
  onClick: () => void;
  isOpen: boolean;
  notificationCount?: number;
}

function MenuItem({
  icon,
  text,
  onClick,
  isOpen,
  notificationCount,
}: MenuItemProps) {
  const showNotificationBadge = Boolean(
    notificationCount && notificationCount > 0
  );
  return (
    <button
      className="burger-menu-item transparent-button"
      onClick={onClick}
      tabIndex={isOpen ? 0 : -1}
    >
      <span className="burger-menu-item-icon">{icon}</span>
      <span className="burger-menu-item-text">{text}</span>
      {showNotificationBadge && (
        <StatusLabel type={STATUS_TYPE.ERROR} icon={false}>
          {notificationCount}
        </StatusLabel>
      )}
    </button>
  );
}
