import React, { useContext } from "react";
import { ReactForecast, GetMessageFunction, Slider } from "@lysaab/ui-2";
import { FormattedMessage, useIntl, defineMessages } from "react-intl";
import { LocalizationContext } from "../../context/LocalizationContext";
import "./EditAllocationForecast.scss";
import { MonthlyInvestmentInput } from "./MonthlyInvestmentInput";

const messages = defineMessages({
  yearAlternativesYear: {
    id: "editallocationforecast.yearalternatives.year",
  },
  graphTooShort: {
    id: "editallocationforecast.graph.tooShort",
  },
  graphProbability: {
    id: "editallocationforecast.graph.probability",
  },
  graphVeryGood: {
    id: "editallocationforecast.graph.veryGood",
  },
  graphGood: {
    id: "editallocationforecast.graph.good",
  },
  graphBad: {
    id: "editallocationforecast.graph.bad",
  },
  graphVeryBad: {
    id: "editallocationforecast.graph.veryBad",
  },
});

interface Props {
  chosenAllocation: string;
  allocationInvestment: number;
  monthlyInvestment: number;
  setMonthlyInvestment: (value: number) => void;
  horizonYears: number;
  setHorizonYears: (allocationInvestment: number) => void;
}

export const EditAllocationForecast: React.VFC<Props> = ({
  chosenAllocation,
  allocationInvestment,
  monthlyInvestment,
  setMonthlyInvestment,
  horizonYears,
  setHorizonYears,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  const getTexts: GetMessageFunction = (args) => {
    return {
      tooShort: intl.formatMessage(messages.graphTooShort),
      probability: intl.formatMessage(messages.graphProbability, {
        years: args.years,
        months: args.months,
      }),
      veryGoodText: intl.formatMessage<React.ReactNode>(
        messages.graphVeryGood,
        {
          sum: intl.formatNumber(args.veryGoodSum, {
            currency: localizationContext.state.currency,
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          }),
          percent: args.veryGoodPercentage / 100,
          bold: (str) => {
            return <b key={Math.random()}>{str}</b>;
          },
        }
      ),
      goodText: intl.formatMessage<React.ReactNode>(messages.graphGood, {
        sum: intl.formatNumber(args.goodSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.goodPercentage / 100,
        bold: (str) => {
          return <b key={Math.random()}>{str}</b>;
        },
      }),
      badText: intl.formatMessage<React.ReactNode>(messages.graphBad, {
        sum: intl.formatNumber(args.badSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.badPercentage / 100,
        bold: (str) => {
          return <b key={Math.random()}>{str}</b>;
        },
      }),
      veryBadText: intl.formatMessage<React.ReactNode>(messages.graphVeryBad, {
        sum: intl.formatNumber(args.veryBadSum, {
          currency: localizationContext.state.currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        }),
        percent: args.veryBadPercentage / 100,
        bold: (str) => {
          return <b key={Math.random()}>{str}</b>;
        },
      }),
    };
  };

  return (
    <div className="edit-allocation-forecast-page">
      <h4>
        <FormattedMessage id="editallocationforecast.header.monthly" />
      </h4>
      <MonthlyInvestmentInput
        monthly={monthlyInvestment}
        setMonthly={setMonthlyInvestment}
      />
      <Slider
        min={1}
        max={50}
        step={1}
        value={horizonYears}
        onChange={(value) => setHorizonYears(value)}
        label={
          <h4 className="edit-allocation-forecast-slider-header">
            <strong>
              <FormattedMessage id="editallocationforecast.header.horizon" />
            </strong>
            <i>
              <FormattedMessage
                id="editallocationforecast.text.horizon"
                values={{ horizon: horizonYears }}
              />
            </i>
          </h4>
        }
      />
      <div
        className={"graph-wrapper" + (allocationInvestment ? "" : " disabled")}
      >
        <ReactForecast
          initial={allocationInvestment.toString()}
          interval={"" + horizonYears}
          monthly={allocationInvestment ? monthlyInvestment.toString() : "0"}
          risk={chosenAllocation}
          getTexts={getTexts}
          currency={localizationContext.state.currency}
          locale={localizationContext.getLocale()}
        />
      </div>
    </div>
  );
};
