import { LysaCountry } from "@lysaab/shared";
import React, { useContext, useReducer } from "react";

// If you add a new language here, you'll have to add it to
// translationsRunner.js as well
export enum Language {
  SWEDISH = "sv",
  ENGLISH = "en",
  GERMAN = "de",
}

export function isLanguage(value: unknown): value is Language {
  return (
    typeof value === "string" &&
    Object.values(Language).some((k) => k === value)
  );
}

export enum Currency {
  SEK = "SEK",
  DKK = "DKK",
  EUR = "EUR",
}

// Types for the state
export interface LocalizationState {
  currency: Currency;
  language: Language;
  country?: LysaCountry;
}

// All types for the context. This includes setter methods
export interface LocalizationContextProps {
  state: LocalizationState;
  setState: (newState: Partial<LocalizationState>) => void;
  getLocale: () => string;
}

// Create the context. (This will just create a template)
export const LocalizationContext =
  React.createContext<LocalizationContextProps>({} as LocalizationContextProps);

/**
 * A Higher Order Component. This is used to wrap classes so that the context is injected
 * There is quite a bit TS magic here. This is so that the internal props off the component
 * will be stripped of the context props so that the external props will only show the relevant
 * props
 */
export const withLocalization =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<
    React.PropsWithChildren<Omit<P, keyof LocalizationContextProps>>
  > =>
  (props) =>
    (
      <LocalizationContext.Consumer>
        {(contextProps) => <Component {...(props as P)} {...contextProps} />}
      </LocalizationContext.Consumer>
    );

function stateReducer(
  state: LocalizationState,
  newState: Partial<LocalizationState>
) {
  return { ...state, ...newState };
}

export const LocalizationContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    currency: Currency.SEK,
    language: Language.ENGLISH,
  });

  const getLocale = () => {
    let locale: string = state.language;
    if (state.country) {
      locale = `${state.language}-${state.country}`;
    }

    return locale;
  };

  return (
    <LocalizationContext.Provider value={{ state, setState, getLocale }}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalizationContext = () => {
  const localizationContext = useContext(LocalizationContext);

  if (!localizationContext) {
    throw new Error(
      "useLocalizationContext must be used inside a LocalizationContextProvider"
    );
  }

  return localizationContext;
};

export const useCountry = () => {
  const localizationContext = useContext(LocalizationContext);

  if (!localizationContext) {
    throw new Error(
      "useCountry must be used inside a LocalizationContextProvider"
    );
  }

  const { country } = localizationContext.state;

  if (!country) {
    throw new Error("country doesnt exist in LocalizationContext");
  }

  return country;
};

export const useCurrency = () => {
  const localizationContext = useContext(LocalizationContext);

  if (!localizationContext) {
    throw new Error(
      "useCountry must be used inside a LocalizationContextProvider"
    );
  }

  const { currency } = localizationContext.state;

  if (!currency) {
    throw new Error("currency doesnt exist in LocalizationContext");
  }

  return currency;
};

export const useLanguage = () => {
  const localizationContext = useContext(LocalizationContext);

  if (!localizationContext) {
    throw new Error(
      "useLanguage must be used inside a LocalizationContextProvider"
    );
  }

  const { language } = localizationContext.state;

  if (!language) {
    throw new Error("language doesnt exist in LocalizationContext");
  }

  return language;
};
