import React, { FunctionComponent } from "react";
import { FormattedMessage, FormattedNumber, useIntl } from "react-intl";
import { Amount } from "../../../../../../components/amount/Amount";
import { getFundName } from "../../../../../../components/fundName/FundName";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { Holding } from "../../../../../../data/dataCorporate";
import { DataDisplay } from "../../../../../../components/dataDisplay/DataDisplay";
import "./HoldingDeclaration.scss";

export interface HoldingDeclarationProps {
  title: string;
  holdings: Holding[];
  missingData?: React.ReactNode;
}

export const HoldingDeclaration: FunctionComponent<
  React.PropsWithChildren<HoldingDeclarationProps>
> = ({ title, holdings, missingData }) => {
  const intl = useIntl();
  return (
    <GridRow as="ul" className="holding-declaration">
      <h5>{title}</h5>
      {holdings.length ? (
        holdings.map((holding, index) => (
          <GridCol
            as="li"
            xsmall={12}
            key={title + index}
            className="declaration-list"
          >
            <GridRow>
              <GridCol xsmall={6} className="text-container">
                <DataDisplay
                  type="list-item"
                  title={
                    <FormattedMessage id="sweden.accountingPage.dataDisplay.holdingDeclaration.title.holding" />
                  }
                  text={`${getFundName(holding.isin, intl)} (${holding.isin})`}
                />
              </GridCol>
              <GridCol xsmall={2} className="text-container text-right">
                <DataDisplay
                  type="list-item"
                  title={
                    <FormattedMessage id="sweden.accountingPage.dataDisplay.holdingDeclaration.title.shares" />
                  }
                  text={
                    <FormattedNumber
                      value={holding.shares}
                      minimumFractionDigits={4}
                      maximumFractionDigits={4}
                    />
                  }
                />
              </GridCol>
              <GridCol xsmall={2} className="text-container text-right">
                <DataDisplay
                  type="list-item"
                  title={
                    <FormattedMessage id="sweden.accountingPage.dataDisplay.holdingDeclaration.title.nav" />
                  }
                  text={
                    <FormattedNumber
                      value={holding.price}
                      minimumFractionDigits={2}
                      maximumFractionDigits={2}
                    />
                  }
                />
              </GridCol>
              <GridCol xsmall={2} className="text-container text-right">
                <DataDisplay
                  type="list-item"
                  title={
                    <FormattedMessage id="sweden.accountingPage.dataDisplay.holdingDeclaration.title.price" />
                  }
                  text={
                    <Amount
                      amount={holding.shares * holding.price}
                      decimals={2}
                    />
                  }
                />
              </GridCol>
            </GridRow>
          </GridCol>
        ))
      ) : missingData ? (
        <GridCol as="li" xsmall={12} className="info-box">
          {missingData}
        </GridCol>
      ) : null}
    </GridRow>
  );
};
