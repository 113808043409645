import React, { FunctionComponent } from "react";
import cx from "classnames";
import { Card } from "@lysaab/ui-2";
import "./CardPaneList.scss";

interface Props {
  id?: string;
  title: string;
  className?: string;
}

export const CardPaneList: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ id, className, title, children }) => {
  return (
    <Card id={id} className={cx("card-pane-list", className)}>
      <h2>{title}</h2>
      <div className="card-pane-list--children">{children}</div>
    </Card>
  );
};
