import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
  FunctionComponent,
} from "react";
import { InvestmentAccount } from "../../data/dataAccounts";
import { Retry, Status } from "../../components/retry/Retry";
import { CardList, Typography } from "@lysaab/ui-2";
import { CardBottom } from "./CardBottom";
import { FormattedMessage } from "react-intl";
import { MonthlyEntry } from "./MonthlyEntry";
import "./Monthly.scss";
import { getNavLink } from "../../hooks/useCountryUrls";
import { MONTHLY_DEPOSITS_URL } from "../deposits/monthly/create/MonthlyStory";
import { Link } from "react-router-dom";
import { MONTHLY_OVERVIEW_PAGE } from "../deposits/monthly/overview/MonthlyOverview";
import {
  dataMonthlyPayments,
  MonthlyPayment,
} from "../../data/dataMonthlyPayments";

interface Props {
  account: InvestmentAccount | undefined;
  showAction?: boolean;
}

export const Monthly: FunctionComponent<Props> = ({
  account,
  showAction = true,
}) => {
  const [monthlyPayments, setMonthlyPayments] = useState<MonthlyPayment[]>([]);
  const [status, setStatus] = useState<Status>(Status.PENDING);
  const timer = useRef<number | undefined>();

  // TODO: Type this
  const load = useCallback((account: any) => {
    dataMonthlyPayments
      .getAllMonthlyPayments()
      .then(({ monthlyPayments, monthlyPaymentsSharedAccounts }) => {
        const items = [
          ...monthlyPayments,
          ...monthlyPaymentsSharedAccounts,
        ].filter((item) => item.accountId === account.accountId);
        setMonthlyPayments(items);
        setStatus(Status.SUCCESS);
      })
      .catch(() => {
        setStatus(Status.ERROR);
      });
  }, []);

  useEffect(() => {
    if (!account) {
      return;
    }
    load(account);
  }, [account, load]);

  const retry = useCallback(() => {
    setStatus(Status.PENDING);
    timer.current = window.setTimeout(() => {
      load(account);
    }, 500);
  }, [account, load]);

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  return (
    <section className="account-page-monthly">
      <Typography type="h2">
        <FormattedMessage
          id="accountPage.monthly.header"
          description="Account page monthly savings header"
          defaultMessage="Monthly savings"
        />
      </Typography>

      <CardList>
        <Retry retry={retry} status={status}>
          <div className="account-page-padder">
            <div className="account-page-card-body">
              {monthlyPayments.length ? (
                monthlyPayments.map((monthly, index) => (
                  <MonthlyEntry
                    monthly={monthly}
                    key={monthly.accountId + index}
                  />
                ))
              ) : (
                <section className="no-entries">
                  <FormattedMessage
                    id="accountPage.monthly.noEntries"
                    description="No monthly savings found for this account"
                    defaultMessage="There are currently no monthly deposits to this account"
                  />
                </section>
              )}
            </div>
            {showAction && (
              <CardBottom>
                {monthlyPayments.length ? (
                  <Link to={getNavLink(MONTHLY_OVERVIEW_PAGE)}>
                    <FormattedMessage
                      id="accountPage.monthly.update"
                      description="Account page monthly savings - update monthly savings"
                      defaultMessage="Update monthly deposits"
                    />
                  </Link>
                ) : (
                  <Link to={getNavLink(MONTHLY_DEPOSITS_URL)}>
                    <FormattedMessage
                      id="accountPage.monthly.add"
                      description="Account page monthly savings - add monthly savings"
                      defaultMessage="Add monthly deposit"
                    />
                  </Link>
                )}
              </CardBottom>
            )}
          </div>
        </Retry>
      </CardList>
    </section>
  );
};
