import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { dataLogin, useBootstrapUser } from "../../../data/dataLogin";
import { setUserState, UserContext } from "../../../context/UserContext";
import { EventTracker } from "../../../components/eventTracker/EventTracker";
import { OVERVIEW_PAGE_URL } from "../../../pages/overview/OverviewPage";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { TranslatedText } from "../../../components/TranslatedText";

import "./SwitchUser.scss";
import { useUserStorage } from "../../../context/UserStorageContext";

export const SWITCH_USER_PAGE_URL = "/switch-user/:id";

export function SwitchUserPage() {
  const { id } = useParams<{ id: string }>();
  const userContext = useContext(UserContext);
  const [error, setError] = useState(false);
  const history = useHistory();
  const { setUserStorage } = useUserStorage();
  const bootstrapUser = useBootstrapUser();

  const switchUser = () => {
    dataLogin
      .switchUser(id)
      .then(() => {
        bootstrapUser().then(([bootstrap, legalEntity, userStates]) => {
          setUserState(bootstrap, legalEntity, userContext.setState);
          setUserStorage(userStates);
          dataLogin.keepAlive();
          EventTracker.reset();

          history.push(getNavLink(OVERVIEW_PAGE_URL));
          setError(false);
        });
      })
      .catch(() => {
        setError(true);
      });
  };

  useEffect(() => {
    switchUser();
    //eslint-disable-next-line
  }, [id]);

  if (error) {
    return (
      <div className="switch-user">
        <h1>
          <TranslatedText id="switchUser.loadUser.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="switchUser.loadUser.error" />
        </Snackbar>
        <Button
          onClick={switchUser}
          size="small"
          className="retry-button"
          label={<TranslatedText id="switchUser.loadUser.retry" />}
        />
      </div>
    );
  }

  return (
    <div className="switch-user">
      <h2>
        <TranslatedText id="switchUser.loadUser.header" />
      </h2>
      <Spinner />
    </div>
  );
}
