import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  MaxValidator,
  MinValidator,
  MoneyInput,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useContext, useEffect, useRef, useState } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { useRouteMatch } from "react-router";
import { TrackerEvent } from "../../../../../../../data/dataCustomerTracking";
import { EventTracker } from "../../../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../../../components/TranslatedText";
import { LocalizationContext } from "../../../../../../../context/LocalizationContext";
import {
  InvestmentAccount,
  InvestmentAccountId,
  dataAccounts,
} from "../../../../../../../data/dataAccounts";
import { dataMobilepay } from "../../../../../../../data/dataMobilepay";
import {
  dataMonthlyPayments,
  MonthlyPayment,
} from "../../../../../../../data/dataMonthlyPayments";
import { MonthlyContext } from "../MonthlyContext";

interface Props {
  next: () => void;
}

interface Match {
  accountId?: InvestmentAccountId;
}

const messages = defineMessages({
  lysaAccountLabel: {
    id: "denmark.mobilepay.story.lysa-account-selection.lysa.label",
  },
  lysaAccountRequired: {
    id: "denmark.mobilepay.story.lysa-account-selection.lysa.required",
  },
  amountRequired: {
    id: "denmark.mobilepay.story.account-selection.amount.required",
  },
  amountMin: {
    id: "denmark.mobilepay.story.account-selection.amount.min",
  },
  amountMax: {
    id: "denmark.mobilepay.story.account-selection.amount.max",
  },
});

const DEFAULT_MIN_AMOUNT = 200;
const DEFAULT_MAX_AMOUNT = 60000;

export function LysaAccountSelection({ next }: Props) {
  const [accounts, setAccounts] = useState<InvestmentAccount[]>();
  const [monthlyPayments, setMonthlyPayments] = useState<MonthlyPayment[]>();
  const formRef = useRef<LysaFormRef>();
  const monthlyContext = useContext(MonthlyContext);
  const selectedLysaAccount = monthlyContext.state.selectedLysaAccount;
  const intl = useIntl();
  const match = useRouteMatch<Match>();
  const loaded = useRef(false);
  const localizationContext = useContext(LocalizationContext);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (loaded.current) {
      return;
    }
    loaded.current = true;

    Promise.all([
      dataAccounts.getAccounts().then((resp) => {
        setAccounts(resp);
        if (match.params.accountId) {
          const account = resp.find(
            (acc) => acc.accountId === match.params.accountId
          );
          if (account) {
            monthlyContext.setState({ selectedLysaAccount: account });
          }
        }
      }),
      dataMonthlyPayments.getMonthlyPayments().then(setMonthlyPayments),
    ]).finally(() => setIsLoading(false));
  }, [monthlyContext, match.params.accountId, next]);

  if (isLoading || !accounts) {
    return <Spinner />;
  }

  return (
    <div>
      <h2>
        <TranslatedText
          id="denmark.mobilepay.story.lysa-account-selection.header"
          defaultMessage="Selection destination account"
        />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (
            !formRef.current?.isValid ||
            !selectedLysaAccount ||
            !monthlyContext.state.amount ||
            monthlyPayments?.find(
              (payment) => payment.accountId === selectedLysaAccount.accountId
            )
          ) {
            return;
          }
          setIsLoading(true);
          dataMobilepay
            .createMonthlyDeposit({
              accountId: selectedLysaAccount.accountId,
              amount: Number(monthlyContext.state.amount),
            })
            .then(() => {
              const to = selectedLysaAccount.name;
              const amount = monthlyContext.state.amount;
              const day = monthlyContext.state.withdrawalDay;

              EventTracker.track({
                event: TrackerEvent.ADD_MONTHLY,
                message: `To: ${to}, Amount: ${amount}, Day: ${day}`,
              });
              next();
            })
            .finally(() => setIsLoading(false));
        }}
      >
        <Card>
          <RadioGroup
            alternatives={accounts.map(
              (account): Alternative<InvestmentAccountId> => {
                return {
                  text: account.name,
                  value: account.accountId,
                };
              }
            )}
            value={
              selectedLysaAccount
                ? {
                    text: selectedLysaAccount.name,
                    value: selectedLysaAccount.accountId,
                  }
                : undefined
            }
            header={intl.formatMessage(messages.lysaAccountLabel)}
            onChange={(alt) =>
              monthlyContext.setState({
                selectedLysaAccount: accounts.find(
                  (account) => account.accountId === alt.value
                ),
              })
            }
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.lysaAccountRequired)
              ),
            ]}
          />
          <MoneyInput
            currency={localizationContext.state.currency}
            value={monthlyContext.state.amount}
            onChange={(amount) => monthlyContext.setState({ amount })}
            validators={[
              new RequiredValidator(
                intl.formatMessage(messages.amountRequired)
              ),
              new MinValidator(
                DEFAULT_MIN_AMOUNT,
                intl.formatMessage(messages.amountMin, {
                  minAmount: intl.formatNumber(DEFAULT_MIN_AMOUNT, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
              new MaxValidator(
                DEFAULT_MAX_AMOUNT,
                intl.formatMessage(messages.amountMax, {
                  maxAmount: intl.formatNumber(DEFAULT_MAX_AMOUNT, {
                    style: "currency",
                    currency: localizationContext.state.currency,
                  }),
                })
              ),
            ]}
          />
          {selectedLysaAccount &&
            monthlyPayments?.find(
              (payment) => payment.accountId === selectedLysaAccount.accountId
            ) && (
              <Snackbar type={SNACKBAR_TYPES.WARNING}>
                <FormattedMessage id="denmark.mobilepay.story.duplicate" />
              </Snackbar>
            )}
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="denmark.mobilepay.story.lysa-account-selection.button" />
          }
        />
      </Form>
    </div>
  );
}
