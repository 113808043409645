import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { InvestmentAccount } from "../../../data/dataAccounts";
import { generatePath, Link } from "react-router-dom";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { Card } from "@lysaab/ui-2/components/cards/Card";
import { FormattedMessage, useIntl } from "react-intl";
import { PerformanceContext } from "../../../context/PerformanceContext";
import {
  dataPerformance,
  Interval,
  PerformanceAccount,
} from "../../../data/dataPerformance";
import { Amount } from "../../../components/amount/Amount";
import { AccountName } from "../../../components/accountName/AccountName";
import { AccountType } from "../../../components/accountType/AccountType";
import { FormattedStyledPercentage } from "../../../components/formattedPercentage/FormattedStyledPercentage";
import { useAccountAllocation } from "../../../hooks/useAccountAllocation";
import {
  dataCustomerTrackingService,
  FeatureDomain,
  SubDomain,
} from "../../../data/dataCustomerTracking";
import { motion } from "framer-motion";
import { SHARED_ACCOUNT_PAGE_URL } from "../../account/sharedAccountPage/SharedAccountPage";
import { overviewAccountIntervalMessages } from "../../../components/IntervalMessages";
import { useShowCurrency } from "../../../context/LayoutContext";
import "./OverviewAccountBase.scss";
import { Typography } from "@lysaab/ui-2";

interface Props {
  account: InvestmentAccount;
  accounts: InvestmentAccount[];
}

const TIMER = 10000;

export const OverviewSharedAccount: React.FC<Props> = ({
  account,
  accounts,
}) => {
  const intl = useIntl();
  const { interval, customStartDate, customEndDate } =
    useContext(PerformanceContext);
  const timer = useRef<number | null>(null);
  const [data, setData] = useState<PerformanceAccount>({
    change: 0,
    earnings: 0,
  });
  const accountAllocation = useAccountAllocation(account?.accountId);
  const { showCurrency } = useShowCurrency();

  const load = useCallback(
    (
      account: InvestmentAccount,
      accounts: InvestmentAccount[],
      interval: Interval,
      customStartDate: Date | null,
      customEndDate: Date | null
    ) => {
      let start;
      let end;

      if (interval === Interval.CUSTOM) {
        if (!customStartDate) {
          return;
        }
        if (!customEndDate) {
          return;
        }
        if (customStartDate > customEndDate) {
          return;
        }
        start = customStartDate;
        end = customEndDate;
      } else {
        start = dataPerformance.getStartDateFromUser(interval, accounts);
        end = dataPerformance.getEndDate();
      }

      dataPerformance
        .getAccountPerformance(start, end, account.accountId)
        .then((data) => {
          const accountData = { ...data.accounts[account.accountId] };

          setData({
            change: isNaN(accountData.change) ? 0 : accountData.change,
            earnings: isNaN(accountData.earnings) ? 0 : accountData.earnings,
          });
        })
        .catch(() => {
          timer.current = window.setTimeout(() => {
            load(account, accounts, interval, customStartDate, customEndDate);
          }, TIMER);
        });
    },
    []
  );

  useEffect(() => {
    load(account, accounts, interval, customStartDate, customEndDate);
    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current);
      }
    };
  }, [load, account, accounts, interval, customStartDate, customEndDate]);

  if (!accountAllocation) {
    return null;
  }

  return (
    <motion.div
      layout
      layoutId={account.accountId}
      className="overview-account"
      transition={{
        type: "spring",
        damping: 24,
        stiffness: 200,
      }}
      animate={{ zIndex: 1 }}
    >
      <Link
        to={getNavLink(
          generatePath(SHARED_ACCOUNT_PAGE_URL, {
            accountId: account.accountId,
          })
        )}
        onClick={() =>
          dataCustomerTrackingService.postEvent({
            domain: FeatureDomain.INVESTMENT_ACCOUNT,
            eventName: "clickedAccountCard",
            subDomain: SubDomain.OVERVIEW,
            payload: {
              accountType: account.type,
            },
          })
        }
        className="overview-page-account-link"
      >
        <Card>
          <div className="overview-account-inner">
            <div className="flex">
              <AccountName
                account={account}
                showAmount={false}
                showRisk={true}
                showSharedIndicator={true}
              />

              <div className="overview-account-button">
                <div className="button-primary button-small lysa-ui-button">
                  <Typography type="label">
                    <FormattedMessage id="overview.accounts.showMore" />
                  </Typography>
                </div>
              </div>
            </div>

            <div className="overview-account-allocation">
              <FormattedMessage
                id="overview.accounts.allocation"
                values={{
                  rawRisk: accountAllocation.takenRisk,
                  stocks: intl.formatNumber(accountAllocation.takenRisk / 100, {
                    style: "percent",
                  }),
                  bonds: intl.formatNumber(
                    (100 - accountAllocation.takenRisk) / 100,
                    {
                      style: "percent",
                    }
                  ),
                }}
              />
            </div>

            <div className="overview-account-type-and-value">
              <AccountType account={account} long capitalized />

              <div className="overview-account-value">
                <Amount amount={account.worth} />
              </div>
            </div>

            <div className="overview-account-change-and-profits see-no-evil-hide">
              <div className="overview-account-dates">
                <FormattedMessage
                  {...overviewAccountIntervalMessages[interval]}
                />
              </div>
              {showCurrency ? (
                <div className="overview-account-profits">
                  <Amount amount={data.earnings} showSign={true} />
                </div>
              ) : (
                <div className="overview-account-change">
                  <FormattedStyledPercentage
                    value={data.change}
                    decimals={1}
                    showSign={true}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      </Link>
    </motion.div>
  );
};
