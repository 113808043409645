import React, { useCallback, useContext, useReducer } from "react";
import {
  dataUserState,
  SetUserStateRequest,
  StateName,
  StateValue,
} from "../data/dataUserState";

export interface UserStorageContextProps {
  state: Partial<StateValue>;
  setState: (newState: Partial<StateValue>) => void;
}

const UserStorageContext = React.createContext<
  UserStorageContextProps | undefined
>(undefined);

function stateReducer(
  state: Partial<StateValue>,
  newState: Partial<StateValue>
) {
  return { ...state, ...newState };
}

export const UserStorageContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <UserStorageContext.Provider value={{ state, setState }}>
      {children}
    </UserStorageContext.Provider>
  );
};

export function useUserStorage() {
  const userStorage = useContext(UserStorageContext);

  if (typeof userStorage === "undefined") {
    throw new Error("Missing userContext provider");
  }

  const setUserStorageSyncedWithBackend = useCallback(
    <K extends StateName>(updateState: SetUserStateRequest<K>) => {
      dataUserState.setUserState(updateState).then(() =>
        userStorage.setState({
          [updateState.stateName]: updateState.stateValue,
        })
      );
    },
    [userStorage]
  );

  return {
    userStorage: userStorage.state,
    setUserStorage: userStorage.setState,
    setUserStorageSyncedWithBackend,
  };
}
