import React, { useContext } from "react";
import "./OpenNewAccount.scss";
import { CreateAccountLink } from "../../components/createAccountLink/CreateAccountLink";
import { TranslatedText } from "../../components/TranslatedText";
import { defineMessages, useIntl } from "react-intl";
import { LysaCountry } from "@lysaab/shared";
import { UserContext } from "../../context/UserContext";
import { LegalEntityType } from "../../data/dataLogin";

const messages = defineMessages<
  LysaCountry,
  { id: string },
  Record<LysaCountry, { id: string }>
>({
  [LysaCountry.SWEDEN]: {
    id: "sweden.overview.openAccount.infoToPersons",
  },
  [LysaCountry.DENMARK]: {
    id: "overview.openAccount.info",
  },
  [LysaCountry.FINLAND]: {
    id: "overview.openAccount.info",
  },
  [LysaCountry.GERMANY]: {
    id: "overview.openAccount.info",
  },
  [LysaCountry.SPAIN]: {
    id: "overview.openAccount.info",
  },
});

export function OpenNewAccount() {
  const user = useContext(UserContext);
  const intl = useIntl();

  if (typeof user.state.country === "undefined") {
    return null;
  }

  return (
    <div className="open-new-account">
      <p>
        {user.state.legalEntityType === LegalEntityType.PERSON ? (
          intl.formatMessage(messages[user.state.country])
        ) : (
          <TranslatedText id="overview.openAccount.corporateInfo" />
        )}
      </p>
      <CreateAccountLink>
        <TranslatedText id="overview.openAccount.button" />
      </CreateAccountLink>
    </div>
  );
}
