import React, {
  useCallback,
  useContext,
  useRef,
  VoidFunctionComponent,
} from "react";
import {
  Button,
  Form,
  LysaFormRef,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { SustainabilityQuestion } from "../../../pageComponents/sustainability/SustainabilityQuestion";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";
import { SustainabilityImportance } from "../../../data/dataInvestments";
import { useAccountContext } from "../context/AccountContext";
import { AccountPreferences } from "../../../context/AccountsContext";

interface Props {
  next: () => void;
}

export const Sustainability: VoidFunctionComponent<Props> = ({ next }) => {
  const formRef = useRef<LysaFormRef>();
  const localizationContext = useContext(LocalizationContext);
  const [accountState, setAccountState] = useAccountContext();

  const onSubmit = useCallback(() => {
    if (
      formRef.current?.isValid &&
      (localizationContext.state.country !== LysaCountry.SWEDEN
        ? accountState.account?.sustainability !==
          SustainabilityImportance.IMPORTANT
        : true)
    ) {
      next();
    }
  }, [
    accountState.account?.sustainability,
    localizationContext.state.country,
    next,
  ]);

  if (!accountState.account) {
    return <Spinner />;
  }

  return (
    <Form lysaFormRef={formRef} onSubmit={onSubmit}>
      <SustainabilityQuestion
        sustainability={accountState.account.sustainability}
        setSustainability={(sustainability) =>
          setAccountState({
            account: {
              ...accountState.account,
              sustainability,
            } as AccountPreferences,
          })
        }
      />
      {localizationContext.state.country !== LysaCountry.SWEDEN &&
        accountState.account.sustainability ===
          SustainabilityImportance.IMPORTANT && (
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <FormattedMessage id="accountSustainability.IMPORTANT.error" />
          </Snackbar>
        )}
      <Button
        block
        type="submit"
        label={<FormattedMessage id="accountSustainability.button.next" />}
      />
    </Form>
  );
};
