import React, { useReducer, createContext, FunctionComponent } from "react";
import { AccountType } from "../../data/dataAccounts";
import {
  GetSuitabilityAssessmentResponse,
  InvestmentAccountQuestions,
} from "../../data/dataInvestments";
import {
  PurposeAccountCorporation,
  DepositInterval,
  PurposeAccount,
  WithdrawalInterval,
} from "../../data/dataKyc";

export type CreateAccountState = Partial<InvestmentAccountQuestions> & {
  /** KYC */
  depositInterval?: DepositInterval;
  purpose?: PurposeAccount[];
  corporatePurpose?: PurposeAccountCorporation[];
  withdrawalInterval?: WithdrawalInterval;

  /** Allocation */
  allocationSelectedRisk?: number;
  allocationAdvicedRisk?: number;
  allocationForcedRisk?: number;
  allocationInvestment?: string;
  allocationMonthlyInvestment?: string;

  takenRisk?: number;

  accountName: string;
  accountType: AccountType;

  /** Advice */
  advise?: GetSuitabilityAssessmentResponse;
};

export interface CreateAccountContextProps {
  state: CreateAccountState;
  setState: (newState: Partial<CreateAccountState>) => void;
}

export interface withCreateAccountProps {
  CreateAccount: CreateAccountContextProps;
}

export const CreateAccountContext = createContext<CreateAccountContextProps>(
  {} as CreateAccountContextProps
);

function stateReducer(
  state: CreateAccountState,
  newState: Partial<CreateAccountState>
) {
  return { ...state, ...newState } as CreateAccountState;
}

export const CreateAccountContextProvider: FunctionComponent<
  React.PropsWithChildren<{
    accountName: string;
    accountType: AccountType;
  }>
> = ({ children, accountName, accountType }) => {
  const [state, setState] = useReducer(stateReducer, {
    accountType,
    accountName,
  });

  return (
    <CreateAccountContext.Provider value={{ state, setState }}>
      {children}
    </CreateAccountContext.Provider>
  );
};
