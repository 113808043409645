import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
  useContext,
} from "react";
import { Spinner } from "@lysaab/ui-2";
import { setUserState, UserContext } from "./context/UserContext";
import { dataLogin, useBootstrapUser } from "./data/dataLogin";
import { getLoginCookie } from "./utils/LoginCookie";
import { useUserStorage } from "./context/UserStorageContext";

interface Props {
  children: ReactNode | ReactNode[];
}

export const IsAuthenticatedBoundary: FunctionComponent<Props> = ({
  children,
}) => {
  const [hasLoaded, setHasLoaded] = useState(false);
  const userContext = useContext(UserContext);
  const { setUserStorage } = useUserStorage();
  const bootstrapUser = useBootstrapUser();

  useEffect(() => {
    if (!getLoginCookie()) {
      setHasLoaded(true);
      return;
    }
    bootstrapUser()
      .then(([bootstrap, legalEntity, userStates]) => {
        setUserState(bootstrap, legalEntity, userContext.setState);
        setUserStorage(userStates);
        setHasLoaded(true);
        dataLogin.keepAlive();
      })
      .catch(() => {
        setHasLoaded(true);
        /**
         * We'll just assume that errors here are ok. The user will be redirected to a
         * login page by the underlying country router.
         */
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasLoaded) {
    return (
      <div className="is-authenticated-loader">
        <Spinner />
      </div>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
};
