import React, { useContext, useState, useCallback } from "react";
import cx from "classnames";
import { defineMessages, FormattedMessage } from "react-intl";
import { Card } from "@lysaab/ui-2/components/cards/Card";
import { PerformanceGraph } from "../../components/performanceGraph/PerformanceGraph";
import { IntervalSwitch } from "./IntervalSwitch";
import { IntervalCustomDates } from "./IntervalCustomDates";
import { PerformanceContext } from "../../context/PerformanceContext";
import { GraphMenu } from "./GraphMenu";
import { EventsSwitch } from "./EventsSwitch";
import "./GraphCard.scss";
import { InvestmentAccount, CompoundAccount } from "../../data/dataAccounts";
import { NewCustomerGreeting } from "./NewCustomerGreeting";
import { dataUser } from "../../data/dataUser";
import { NewCustomerGreetingAccount } from "./NewCustomerGreetingAccount";
import { LayoutContext } from "../../context/LayoutContext";
import { WorthSummaryHeader } from "./WorthSummaryHeader";
import { AnimateHeight, CircleButton } from "@lysaab/ui-2";
import { useUser } from "../../context/UserContext";
import { DateTime } from "luxon";
import { Store, STORE_SHOW_PERFORMANCE_GRAPH } from "../../Store";
import { KfMoveInformation } from "../../components/performanceGraph/KfMoveInformation";

interface Props {
  accounts: CompoundAccount[];
  account?: InvestmentAccount;
}

const messages = defineMessages({
  header: {
    id: "overview.graphCard.header",
    description: "Overview graph card header",
    defaultMessage: "Growth",
  },
  shadedHeader: {
    id: "overview.graphCard.shadedHeader",
    description: "Overview graph card shaded header",
    defaultMessage: "Total",
  },
});

function isNewCustomer(accounts: CompoundAccount[]) {
  const created = dataUser.getCreated(accounts);
  const startOfToday = new Date();
  startOfToday.setHours(0, 0, 0, 0);
  return created > startOfToday;
}

export const GraphCard: React.FC<Props> = ({ accounts, account }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { showOverlay } = useContext(PerformanceContext);
  const { state: layoutState } = useContext(LayoutContext);
  const onOpenMenu = useCallback(() => {
    setShowMenu(true);
  }, []);
  const user = useUser();
  const [showGraph, setShowGraph] = useState(
    shouldShowGraphByDefault(user.created)
  );

  const seeNoEvilMode = layoutState.seeNoEvil && !account;

  if (!accounts) {
    return null;
  }

  if (isNewCustomer(accounts)) {
    return account ? <NewCustomerGreetingAccount /> : <NewCustomerGreeting />;
  }

  return (
    <Card
      className={cx("graph-card", {
        "show-menu": showMenu,
        "show-overlay": showOverlay,
      })}
    >
      <WorthSummaryHeader
        account={account}
        seeNoEvilMode={seeNoEvilMode}
        showGraph={showGraph}
        setShowGraph={setShowGraph}
      />

      <div className="graph-wrapper">
        {showGraph && <GraphMenu setShowMenu={setShowMenu} account={account} />}

        <AnimateHeight isOpen={showGraph}>
          {!seeNoEvilMode && (
            <>
              <div className="graph-card-wrapper">
                <div className="graph-card-top">
                  <h4>
                    <FormattedMessage {...messages.header} />
                    <span>
                      {account ? null : (
                        <FormattedMessage {...messages.shadedHeader} />
                      )}
                    </span>
                  </h4>
                  <CircleButton
                    className="graph-menu-open"
                    onClick={onOpenMenu}
                    aria-label="Show selectable index graph list"
                    icon="Tune"
                  />
                </div>

                <div className="graph-card-plot">
                  <PerformanceGraph accounts={accounts} account={account} />
                </div>
              </div>

              <KfMoveInformation account={account} />
              <div className="graph-info">
                <IntervalSwitch />

                <IntervalCustomDates />

                <EventsSwitch />
              </div>
            </>
          )}
        </AnimateHeight>
      </div>
    </Card>
  );
};

function shouldShowGraphByDefault(createdDate: string | undefined) {
  const showGraphSetting = Store.getValue(STORE_SHOW_PERFORMANCE_GRAPH);

  if (showGraphSetting !== null) {
    return showGraphSetting;
  }

  if (typeof createdDate === "undefined") {
    return true;
  }

  const defaultHiddenGraphFrom = DateTime.fromFormat(
    "2022-10-20",
    "yyyy-MM-dd"
  );
  const parsedCreatedDate = DateTime.fromISO(createdDate);

  return parsedCreatedDate.diff(defaultHiddenGraphFrom).milliseconds < 0;
}
