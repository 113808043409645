import React, { useState } from "react";
import { InvestmentType, DocModal } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import {
  DocumentInformation,
  getLatestDocuments,
} from "../../../../../../data/dataDocuments";
import { useCurrency } from "../../../../../../context/LocalizationContext";
import { DocName } from "./DocName";
import "./Docs.scss";

interface Props {
  risk: number;
  investmentType: InvestmentType;
  name: string;
  documentsInformation: DocumentInformation[];
}

export const messages = defineMessages({
  [InvestmentType.BROAD]: {
    id: "sweden.kf.docs.broad",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "sweden.kf.docs.sustainable",
  },
  announcement: {
    id: "sweden.kf.docs.announcement",
  },
  placeholder: {
    id: "sweden.kf.docs.placeholder",
  },
  header: {
    id: "sweden.kf.docs.header",
  },
});

const documentList = [
  ["legal/se/en/LysaLife-forkopsinformation-KF-out.md"],
  ["legal/se/en/LysaLife-villkor-KF-out.md"],
  ["legal/se/en/LysaLife-Faktablad-out.md"],
  ["legal/se/en/LysaLife-infoLysaABdistributor-out.md"],
  [
    "legal/se/sv/information-om-lysa-v-2022-1.md",
    "legal/se/sv/information-om-lysa-v-2023-1.md",
  ],
  [
    "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument.md",
    "legal/se/sv/information-om-egenskaper-och-risker-med-finansiella-instrument-v-2024-1.md",
  ],
  ["legal/se/sv/placeringsriktlinjer.md"],
];

const documents = (information: DocumentInformation[]) =>
  getLatestDocuments(information, ...documentList);

export const Docs: React.VFC<Props> = ({
  risk,
  investmentType,
  name,
  documentsInformation,
}) => {
  const intl = useIntl();
  const [showDoc, setShowDoc] = useState<string>();
  const currency = useCurrency();

  const varValues: Record<string, string> = {
    SIGNING_DATE: intl.formatDate(new Date()),
    CUSTOMER_NAME: name,
    STOCKS: risk ? intl.formatNumber(risk / 100, { style: "percent" }) : "",
    BONDS: risk
      ? intl.formatNumber((100 - risk) / 100, { style: "percent" })
      : "",
    INVESTMENT_TYPE: intl.formatMessage(messages[investmentType]),
    CURRENCY: currency,
  };

  const docs = documents(documentsInformation);

  return (
    <div className="signup-docs">
      <DocModal
        onRequestClose={() => setShowDoc(undefined)}
        active={!!showDoc}
        modalAnnouncement={intl.formatMessage(messages.announcement)}
        selectPlaceholder={intl.formatMessage(messages.placeholder)}
        docList={docs}
        variableValues={varValues}
        header={intl.formatMessage(messages.header)}
        docName={showDoc}
      />

      <div className="link-container">
        {docs.map((doc) => (
          <button
            type="button"
            onClick={() => setShowDoc(doc)}
            className="as-link"
            key={doc}
          >
            <DocName documentName={doc} />
          </button>
        ))}
        <a
          className="as-link"
          href="/docs/PRIIP KID Lysa Life - Företagsägd kapitalförsäkring.pdf"
          download
        >
          PRIIP KID Lysa Life - Företagsägd kapitalförsäkring
        </a>
      </div>
    </div>
  );
};
