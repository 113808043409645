import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { RouteProps, useHistory, matchPath } from "react-router-dom";
import { Route } from "../../components/route/Route";
import {
  AccountsState,
  useAccountsContext,
} from "../../context/AccountsContext";
import { useEligibilityContext } from "../../context/EligibilityContext";
import { EligibilityState } from "../../context/EligibilityContext";
import { getNavLink } from "../../hooks/useCountryUrls";
import { useReviewAccountContext } from "../reviewAccount/ReviewAccountContext";
import { ReviewAccountReturnState } from "../reviewAccount/ReviewAccountStory";
import {
  SuitabilityAssessmentState,
  useSuitabilityAssessmentContext,
} from "./contexts/SuitabilityAssessmentContext";
import { BASE_ROUTES } from "./SuitabilityAssessmentStory";

interface Props {
  children: ReactNode;
}

type SuitabilityAssessmentStartState =
  | {
      returnUrl: string;
    }
  | undefined;

export type SuitabilityAssessmentReturnState = ReviewAccountReturnState<{
  accountsState: AccountsState;
  eligibilityState: EligibilityState;
  suitabilityAssessmentState: SuitabilityAssessmentState;
}>;

const AccessGuard: FunctionComponent<Props> = ({ children }) => {
  const [, setAccountsState] = useAccountsContext();
  const [, setEligibilityState] = useEligibilityContext();
  const [suitabilityAssessmentState, setSuitabilityAssessmentState] =
    useSuitabilityAssessmentContext();
  const [, setReviewAccountState] = useReviewAccountContext();
  const history = useHistory<
    | SuitabilityAssessmentStartState
    | SuitabilityAssessmentReturnState
    | undefined
  >();
  const historyState = history.location.state;

  useEffect(() => {
    const isFirstPage =
      matchPath(history.location.pathname, {
        path: getNavLink(BASE_ROUTES.SITUATION),
        exact: true,
      }) !== null;

    /**
     * If firstPage then only check for returnUrl if user want to exit/is done
     * If not first page then check if history state contain context data and if it does then set it
     * If not first page and directAccessGuard is active then jump to first page
     */
    if (historyState) {
      if (
        isFirstPage &&
        (historyState as SuitabilityAssessmentStartState)?.returnUrl
      ) {
        const startState = historyState as SuitabilityAssessmentStartState;
        if (startState?.returnUrl) {
          setSuitabilityAssessmentState({ returnUrl: startState.returnUrl });
        }
      } else {
        const returnState = historyState as SuitabilityAssessmentReturnState;
        if (returnState?.accountsState) {
          setAccountsState(returnState.accountsState);
        }
        if (returnState?.eligibilityState) {
          setEligibilityState(returnState.eligibilityState);
        }
        if (returnState?.reviewAccountState) {
          setReviewAccountState(returnState.reviewAccountState);
        }
        if (returnState?.suitabilityAssessmentState) {
          setSuitabilityAssessmentState(returnState.suitabilityAssessmentState);
        }
      }
    } else if (!isFirstPage && !suitabilityAssessmentState.directAccessGuard) {
      history.replace(getNavLink(BASE_ROUTES.SITUATION));
    }
  }, [
    suitabilityAssessmentState.directAccessGuard,
    historyState,
    setAccountsState,
    setEligibilityState,
    setSuitabilityAssessmentState,
    setReviewAccountState,
    history,
  ]);

  return <React.Fragment>{children}</React.Fragment>;
};

export const AccessGuardRoute: FunctionComponent<
  React.PropsWithChildren<RouteProps>
> = ({ children, ...props }) => (
  <Route {...props}>
    <AccessGuard>{children}</AccessGuard>
  </Route>
);
