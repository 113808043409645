import React, { useReducer, createContext, FunctionComponent } from "react";
import { CoporateUser } from "../../../../../data/dataCorporate";
export interface UpdatedUser
  extends Pick<CoporateUser, "email" | "identificationNumber" | "admin"> {}

export interface UserManagementState {
  selectedUser?: CoporateUser;
  updatedUser?: UpdatedUser;
  readOnly?: boolean;
}

export interface UserManagementContextProps {
  state: UserManagementState;
  setState: (newState: Partial<UserManagementState>) => void;
}

export const UserManagementContext = createContext<UserManagementContextProps>(
  {} as UserManagementContextProps
);

function stateReducer(
  state: UserManagementState,
  newState: Partial<UserManagementState>
) {
  return { ...state, ...newState };
}

export const UserManagementContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {});

  return (
    <UserManagementContext.Provider value={{ state, setState }}>
      {children}
    </UserManagementContext.Provider>
  );
};
