import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { DocModalLink, LysaLink, StoryFooter } from "@lysaab/ui-2";

export function Footer() {
  const intl = useIntl();

  return (
    <StoryFooter
      contactInfoText={
        <FormattedMessage
          id="sweden.transfer-pension.footer.contact"
          values={{
            break: () => <br />,
            phone: () => {
              const phoneNumber = intl.formatMessage({
                id: "contact.phone.se",
              });
              return (
                <LysaLink href={`tel:${phoneNumber.replace(/\s+/g, "")}`}>
                  {phoneNumber}
                </LysaLink>
              );
            },
            email: () => {
              const email = intl.formatMessage({ id: "contact.email.se" });
              return <LysaLink href={`mailto:${email}`}>{email}</LysaLink>;
            },
          }}
        />
      }
      privacyPolicyText={
        <FormattedMessage
          id="sweden.transfer-pension.footer.privacyPolicy"
          values={{
            a: (linkText) => {
              return (
                <DocModalLink
                  document="legal/se/sv/personuppgiftspolicy.md"
                  modalAnnouncement={intl.formatMessage({
                    id: "sweden.transfer-pension.footer.modalAnnouncement",
                  })}
                  key="element-key-pp"
                >
                  {linkText}
                </DocModalLink>
              );
            },
          }}
        />
      }
    />
  );
}
