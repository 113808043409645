import React, { FunctionComponent, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { ClosedAccount } from "../../../../../data/dataProfile";
import { KfMoveAmount, dataDanica } from "../../../../../data/dataDanica";
import { Spinner } from "@lysaab/ui-2";
import { Amount } from "../../../../../components/amount/Amount";
import { FormattedStyledPercentage } from "../../../../../components/formattedPercentage/FormattedStyledPercentage";

interface Props {
  account?: ClosedAccount;
}

export const AccountInformation: FunctionComponent<Props> = ({ account }) => {
  const intl = useIntl();
  const [moveAmount, setMoveAmount] = useState<KfMoveAmount>();

  useEffect(() => {
    setMoveAmount(undefined);
    if (account === undefined) {
      return;
    }
    dataDanica.getKfMoveAmount(account.accountId).then(setMoveAmount);
  }, [account]);

  if (!account || !moveAmount) {
    return <Spinner />;
  }

  return (
    <React.Fragment>
      <div>
        <h4>
          <TranslatedText id="sweden.acounting-page.move.deposit.sub-header" />{" "}
          - 2023-12-28
        </h4>
        <dl className="data-grid">
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.type.header",
            })}
          </dd>
          <dt>
            {" "}
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.type.withdrawal",
            })}
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.institute",
            })}
          </dd>
          <dt>Danske Bank A/S, Danmark, Sverige Filial</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.org-number",
            })}
          </dd>
          <dt>516401-9811</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.account-number",
            })}
          </dd>
          <dt>13630078494</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.interest-rate",
            })}
          </dd>
          <dt>
            <FormattedStyledPercentage value={0} decimals={2} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.interest",
            })}
          </dd>
          <dt>
            <Amount amount={0} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.date",
            })}
          </dd>
          <dt>2023-12-28</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.deposit",
            })}
          </dd>
          <dt>
            <Amount amount={moveAmount.amount} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.origin",
            })}
          </dd>
          <dt>
            Återköp av företagsägd kapitalförsäkring hos Futur Pension
            Aktiebolag (Publ)
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.deposit.amount",
            })}
          </dd>
          <dt>
            <Amount amount={moveAmount.amount} />
          </dt>
        </dl>
      </div>
      <div>
        <h4>
          <TranslatedText id="sweden.acounting-page.move.withdrawal.sub-header" />{" "}
          - 2024-01-02
        </h4>
        <dl className="data-grid">
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.type.header",
            })}
          </dd>
          <dt>
            {" "}
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.type.withdrawal",
            })}
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.institute",
            })}
          </dd>
          <dt>Danske Bank A/S, Danmark, Sverige Filial</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.org-number",
            })}
          </dd>
          <dt>516401-9811</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.account-number",
            })}
          </dd>
          <dt>13630078494</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.interest-rate",
            })}
          </dd>
          <dt>
            <FormattedStyledPercentage value={0} decimals={2} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.interest",
            })}
          </dd>
          <dt>
            <Amount amount={0} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.date",
            })}
          </dd>
          <dt>2024-01-02</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.deposit",
            })}
          </dd>
          <dt>
            <Amount amount={moveAmount.amount} />
          </dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.origin",
            })}
          </dd>
          <dt>Lysa Life Kapitalförsäkring</dt>
          <dd>
            {intl.formatMessage({
              id: "sweden.accounting-page.move.data.withdrawal.amount",
            })}
          </dd>
          <dt>
            <Amount amount={0} />
          </dt>
        </dl>
      </div>
    </React.Fragment>
  );
};
