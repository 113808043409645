import React, { useEffect, useState } from "react";
import cx from "classnames";
import {
  IndexId,
  Markets,
  Interval,
  dataPerformance,
} from "../../data/dataPerformance";
import { InvestmentAccount } from "../../data/dataAccounts";
import {
  FormattedMessage,
  FormattedDate,
  defineMessages,
  useIntl,
} from "react-intl";
import "./GraphLegend.scss";
import { Amount } from "../amount/Amount";
import { Change } from "../amount/Change";
import AnimateHeight from "react-animate-height";
import { EventsTooltip, IndexChanges } from "./PerformanceGraphTypes";
import { RouteAwareToggle } from "../route/RouteAwareToggle";
import { getNavLink } from "../../hooks/useCountryUrls";
import { INTERNAL_TRANSFER_PAGE_URL } from "../../pages/internalTransfer/InternalTransferPage";
import { comparisonMarkets } from "../../data/dataPerformance";

interface Props {
  account?: InvestmentAccount;
  indexes: IndexId[];
  showEvents: boolean;
  eventsTooltip: EventsTooltip;
  startDate: Date;
  endDate: Date;
  interval: Interval;
  indexChanges: IndexChanges;
  lysaChange: number;
}

const messages = defineMessages({
  [Interval.CURRENT_YEAR]: {
    id: "performance.graph.legend.interval.CURRENT_YEAR",
    description: "Performance graph interval legend - CURRENT_YEAR",
    defaultMessage: "This year",
  },
  [Interval.LAST_SIX_MONTHS]: {
    id: "performance.graph.legend.interval.LAST_SIX_MONTHS",
    description: "Performance graph interval legend - LAST_SIX_MONTHS",
    defaultMessage: "6 months",
  },
  [Interval.LAST_THREE_MONTHS]: {
    id: "performance.graph.legend.interval.LAST_THREE_MONTHS",
    description: "Performance graph interval legend - LAST_THREE_MONTHS",
    defaultMessage: "3 months",
  },
  [Interval.LAST_YEAR]: {
    id: "performance.graph.legend.interval.LAST_YEAR",
    description: "Performance graph interval legend - LAST_YEAR",
    defaultMessage: "1 year",
  },
  [Interval.SINCE_REGISTRATION]: {
    id: "performance.graph.legend.interval.SINCE_REGISTRATION",
    description: "Performance graph interval legend - SINCE_REGISTRATION",
    defaultMessage: "Since start",
  },
  [Interval.CUSTOM]: {
    id: "performance.graph.legend.interval.CUSTOM",
    description: "Performance graph interval legend - CUSTOM",
    defaultMessage: "Custom",
  },
});

export const GraphLegend: React.FC<Props> = (props) => {
  const {
    account,
    indexes,
    showEvents,
    eventsTooltip,
    interval,
    startDate,
    endDate,
    indexChanges,
    lysaChange,
  } = props;
  const intl = useIntl();
  const [markets, setMarkets] = useState<Markets>();

  useEffect(() => {
    if (account) {
      dataPerformance
        .getComparisonMarketsForAccount(account.accountId)
        .then(setMarkets)
        .catch(() => {});
      return;
    }
    dataPerformance
      .getComparisonMarkets()
      .then(setMarkets)
      .catch(() => {});
  }, [account]);

  const lysaLegend = account ? (
    <span>
      <FormattedMessage
        id="performance.graph.legend.account"
        description="Graph performance label for specific account"
        defaultMessage="Profits, specific account"
      />{" "}
      <b>{account.name}</b>
    </span>
  ) : (
    <FormattedMessage
      id="performance.graph.legend.all"
      description="Graph performance label for all accounts"
      defaultMessage="Total profits, all accounts"
    />
  );

  const className = cx("performance-graph-legend", {
    "show-events-legend": showEvents,
  });

  let intervalElem = null;
  if (interval === Interval.CUSTOM) {
    intervalElem = (
      <span className="graph-legend-interval">
        <FormattedDate value={startDate} /> - <FormattedDate value={endDate} />
      </span>
    );
  } else {
    intervalElem = (
      <span className="graph-legend-interval">
        {intl.formatMessage(messages[interval])}
      </span>
    );
  }

  if (!markets) {
    return null;
  }

  return (
    <ul className={className}>
      {indexes.map((item) => {
        const key = `id_${item}` as keyof Markets;
        if (typeof markets[key] === "undefined") {
          return null;
        }

        return (
          <li key={item}>
            <div>
              <span
                className="graph-indicator"
                style={{ backgroundColor: markets[key].color }}
              />
              {intl.formatMessage(comparisonMarkets[markets[key].id])}
            </div>
            <div>
              {indexChanges[key] ? <Change change={indexChanges[key]} /> : ""}
            </div>
          </li>
        );
      })}

      <li className="performance-graph-legend-lysa">
        <div>
          <span className="graph-indicator" />
          {lysaLegend}
        </div>
        <div>
          <Change change={lysaChange} />
        </div>
      </li>

      <AnimateHeight height={showEvents ? "auto" : 0} animateOpacity>
        <li className="performance-graph-legend-deposit">
          <div>
            <span className="graph-indicator" />
            <FormattedMessage
              id="performance.graph.legend.deposit"
              description="Graph performance label deposit"
              defaultMessage="Deposit"
            />
          </div>
          <div className="graph-legend-deposits-total">
            <Amount amount={eventsTooltip.depositsPeriod} />
          </div>
        </li>
        <li className="performance-graph-legend-withdrawal">
          <div>
            <span className="graph-indicator" />
            <FormattedMessage
              id="performance.graph.legend.withdrawal"
              description="Graph performance label withdrawal"
              defaultMessage="Withdrawal"
            />
          </div>
          <div className="graph-legend-withdrawals-total">
            <Amount amount={eventsTooltip.withdrawalsPeriod} />
          </div>
        </li>
        <RouteAwareToggle path={getNavLink(INTERNAL_TRANSFER_PAGE_URL)}>
          <li className="performance-graph-legend-internal">
            <div>
              <span className="graph-indicator" />
              {account ? (
                <FormattedMessage
                  id="performance.graph.legend.account.internal"
                  description="Graph performance label internal"
                  defaultMessage="Internal transfer"
                />
              ) : (
                <FormattedMessage
                  id="performance.graph.legend.overview.internal"
                  description="Graph performance label internal"
                  defaultMessage="Internal transfer"
                />
              )}
            </div>
            <div className="graph-legend-internal-total">
              {account && (
                <Amount
                  amount={eventsTooltip.nettoInternalTransfers}
                  showSign={true}
                />
              )}
            </div>
          </li>
        </RouteAwareToggle>
      </AnimateHeight>
      <li className="performance-graph-legend-interval">
        <span />
        {intervalElem}
        <span />
      </li>
    </ul>
  );
};
