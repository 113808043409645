import React, { useContext, VoidFunctionComponent } from "react";
import { Card, RadioGroup, RequiredValidator } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import {
  depositsCorporation,
  DepositTypeCorporation,
} from "../../../../data/dataKyc";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";

export const messages = defineMessages({
  depositsHeader: { id: "sweden.kyc.deposits.corp.header" },
  depositsRequired: { id: "sweden.kyc.deposits.corp.required" },
});

interface MessageWithId {
  id: string;
}

type CountryMessages =
  | Record<DepositTypeCorporation<LysaCountry.SWEDEN>, MessageWithId>
  | Record<DepositTypeCorporation<LysaCountry.DENMARK>, MessageWithId>
  | Record<DepositTypeCorporation<LysaCountry.FINLAND>, MessageWithId>
  | Record<DepositTypeCorporation<LysaCountry.GERMANY>, MessageWithId>
  | Record<DepositTypeCorporation<LysaCountry.SPAIN>, MessageWithId>;

const swedenMessages = defineMessages<
  DepositTypeCorporation<LysaCountry.SWEDEN>,
  MessageWithId,
  Record<DepositTypeCorporation<LysaCountry.SWEDEN>, MessageWithId>
>({
  SE_0_5M_SEK: {
    id: "sweden.kyc.deposits.corp.alt.SE_0_5M_SEK",
  },
  SE_5M_SEK: {
    id: "sweden.kyc.deposits.corp.alt.SE_5M_SEK",
  },
});

const denmarkMessages = defineMessages<
  DepositTypeCorporation<LysaCountry.DENMARK>,
  MessageWithId,
  Record<DepositTypeCorporation<LysaCountry.DENMARK>, MessageWithId>
>({});

const finlandMessages = defineMessages<
  DepositTypeCorporation<LysaCountry.FINLAND>,
  MessageWithId,
  Record<DepositTypeCorporation<LysaCountry.FINLAND>, MessageWithId>
>({});

const germanyMessages = defineMessages<
  DepositTypeCorporation<LysaCountry.GERMANY>,
  MessageWithId,
  Record<DepositTypeCorporation<LysaCountry.GERMANY>, MessageWithId>
>({});

const spainMessages = defineMessages<
  DepositTypeCorporation<LysaCountry.SPAIN>,
  MessageWithId,
  Record<DepositTypeCorporation<LysaCountry.SPAIN>, MessageWithId>
>({});

const countryMessages: Record<LysaCountry, CountryMessages> = {
  [LysaCountry.SWEDEN]: swedenMessages,
  [LysaCountry.DENMARK]: denmarkMessages,
  [LysaCountry.FINLAND]: finlandMessages,
  [LysaCountry.GERMANY]: germanyMessages,
  [LysaCountry.SPAIN]: spainMessages,
};

interface Props {
  deposit?: DepositTypeCorporation;
  setDeposit: (deposit: DepositTypeCorporation) => void;
}

export const DepositsQuestionCorporation: VoidFunctionComponent<Props> = ({
  deposit,
  setDeposit,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  const alternatives = depositsCorporation[country].map((value) => ({
    text: intl.formatMessage(
      (countryMessages[country] as Record<typeof value, MessageWithId>)[value]
    ),
    value,
  }));

  return (
    <Card>
      <RadioGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.depositsHeader)}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.depositsRequired)),
        ]}
        onChange={(newDeposits) => setDeposit(newDeposits.value)}
        value={alternatives.find((alt) => deposit === alt.value)}
      />
    </Card>
  );
};
