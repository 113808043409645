import React, { useEffect, useState, VoidFunctionComponent } from "react";
import {
  ExpandableCard,
  HiddenContent,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  VisibleContent,
} from "@lysaab/ui-2";
import { FormattedDisplayName, FormattedMessage } from "react-intl";
import {
  CompanyInformation,
  dataCorporate,
} from "../../../../../../data/dataCorporate";
import "./Principals.scss";

export const Principals: VoidFunctionComponent = () => {
  const [loading, setLoading] = useState(true);
  const [companyInformation, setCompanyInformation] =
    useState<CompanyInformation>();

  useEffect(() => {
    dataCorporate
      .getCompanyData()
      .then(setCompanyInformation)
      .catch(() => {})
      .finally(() => setLoading(false));
  }, []);

  if (loading) {
    return (
      <div className="company-settings--principals">
        <Spinner />
      </div>
    );
  }

  if (!companyInformation) {
    return (
      <div className="company-settings--principals">
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <FormattedMessage id="sweden.companySettings.principals.could.not.load" />
        </Snackbar>
      </div>
    );
  }

  return (
    <div className="company-settings--principals">
      <h2>
        <FormattedMessage id="sweden.companySettings.principals.title" />
      </h2>
      {companyInformation.owners.map((owner) => {
        return (
          <ExpandableCard key={owner.tin}>
            <VisibleContent>
              <h3>{owner.name}</h3>
            </VisibleContent>
            <HiddenContent>
              <ul className="info-list">
                <li>
                  <span>
                    <FormattedMessage id="sweden.companySettings.principals.info.name" />
                  </span>
                  <span className="text-right">{owner.name}</span>
                </li>

                <li>
                  <span>
                    <FormattedMessage id="sweden.companySettings.principals.info.citizenships" />
                  </span>
                  <span className="text-right">
                    {owner.citizenships.map((citizenship, i) => {
                      return (
                        <>
                          <FormattedDisplayName
                            type="region"
                            value={citizenship}
                          />
                          {i !== owner.citizenships.length - 1 && (
                            <span>, </span>
                          )}
                        </>
                      );
                    })}
                  </span>
                </li>

                <li>
                  <span>
                    <FormattedMessage id="sweden.companySettings.principals.info.taxResidence" />
                  </span>
                  <span className="text-right">
                    <FormattedDisplayName
                      type="region"
                      value={owner.taxResidence}
                    />
                  </span>
                </li>

                <li>
                  <span>
                    <FormattedMessage id="sweden.companySettings.principals.info.tin" />
                  </span>
                  <span className="text-right">{owner.tin}</span>
                </li>
              </ul>
            </HiddenContent>
          </ExpandableCard>
        );
      })}
    </div>
  );
};
