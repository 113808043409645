import {
  Card,
  LysaLink,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";

import {
  dataTax,
  DkTaxDetails,
  DkTaxSummary,
  TaxAccountYears,
} from "../../../../data/dataTax";
import { Page, PageHeader } from "../../../../pages/Page";
import { TaxDetails } from "./TaxDetails";

import "./TaxInformation.scss";
import "./TaxPrint.scss";

const messages = defineMessages({
  yearsLabel: {
    id: "denmark.tax.years.label",
  },
});

export const TaxInformation: React.VFC = () => {
  const [year, setYear] = useState<number>();
  const [error, setError] = useState(false);
  const [accountsAndYears, setAccountsAndYears] = useState<TaxAccountYears[]>(
    []
  );
  const intl = useIntl();
  const [taxDetails, setTaxDetails] = useState<DkTaxDetails[]>();
  const [taxSummary, setTaxSummary] = useState<DkTaxSummary[]>();
  const [loading, setLoading] = useState<boolean>();

  const doTaxChange = useCallback((year: number) => {
    setLoading(true);
    Promise.all([dataTax.getDkTaxSummary(year), dataTax.getDkTaxDetails(year)])
      .then(([taxSummary, taxDetails]) => {
        setTaxSummary(taxSummary);
        setTaxDetails(taxDetails);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  const years = useMemo(() => {
    return [
      ...Array.from(
        new Set(accountsAndYears.map((entry) => entry.taxYears).flat())
      ),
    ].sort((a, b) => b - a);
  }, [accountsAndYears]);

  useEffect(() => {
    if (typeof year === "undefined" && years.length > 0) {
      setYear(years[0]);
    }
  }, [year, years]);

  useEffect(() => {
    dataTax.getDkTaxYears().then((response) => {
      if (response.length > 0) {
        setAccountsAndYears(response);
      }
    });
  }, []);

  useEffect(() => {
    if (year) {
      doTaxChange(year);
    }
  }, [doTaxChange, year]);

  if (error) {
    return (
      <Page>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="denmark.tax.error" />
        </Snackbar>
      </Page>
    );
  }

  if (!accountsAndYears) {
    return <Spinner />;
  }

  return (
    <Page className="denmark-tax-page">
      <PageHeader>
        <h1>
          <TranslatedText id="denmark.tax.page-header" />
        </h1>
        <div className="external-tax-information-text">
          <TranslatedText
            id="denmark.tax.additional-information"
            values={{
              link: (text) => {
                return (
                  <LysaLink
                    external
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://lysa.dk/tax-information"
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </div>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} className="select-column">
          <h2>
            <TranslatedText id="denmark.tax.select-header" />
          </h2>
          <Card>
            <Select
              alternatives={years.map((year) => ({
                value: year,
                text: year.toString(),
              }))}
              placeholder={intl.formatMessage(messages.yearsLabel)}
              label={intl.formatMessage(messages.yearsLabel)}
              onChange={({ value }) => setYear(value)}
              value={year ? { value: year, text: year.toString() } : undefined}
            />
          </Card>
        </GridCol>
        <GridCol xsmall={12}>
          <TaxDetails
            year={year}
            loading={loading}
            summary={taxSummary}
            details={taxDetails}
          />
        </GridCol>
      </GridRow>
    </Page>
  );
};
