import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useEffect,
} from "react";
import {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  match,
  matchPath,
  RouteProps,
  SwitchProps,
  __RouterContext,
} from "react-router";
import { RouteContext } from "./RouteContext";

interface Props extends SwitchProps {}

export const Switch: FunctionComponent<React.PropsWithChildren<Props>> = ({
  children,
  location: locationProp,
}) => {
  const { addRoute } = useContext(RouteContext);
  const routerContext = useContext(__RouterContext);

  const location = locationProp || routerContext.location;

  let configProps: RouteProps[] | null | undefined = [];

  if (React.Children.count(children) > 0) {
    configProps = React.Children.map(children, (child) => {
      if (
        !React.isValidElement(child) ||
        (typeof child.props.path === "undefined" &&
          typeof child.props.from === "undefined")
      ) {
        return;
      }

      // The condition is handled by the route component itself (currently only
      // PrivateRoute which 404s or redirects). If condition is false, we
      // do know for sure that the route will not work. Therefor we filter
      // those routes out here so we don't include them in the list of known
      // routes (so RouteAwareToggle can do it's thing).
      if (
        typeof child.props.condition !== "undefined" &&
        !child.props.condition
      ) {
        return;
      }

      return {
        path: child.props.path,
        exact: child.props.path,
        strict: child.props.path,
      } as RouteProps;
    });
  }

  useEffect(() => {
    const removeFunctions = configProps?.map((prop) => addRoute(prop));

    return () => {
      removeFunctions?.forEach((func) => func?.());
    };
    /**
     * Due to deep compare not being implemented we use a cheapo way of
     * implementing it by stringifying configProps and then comparing it
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRoute, JSON.stringify(configProps)]);

  let element: ReactNode | null = null;
  let match: match | null = null;

  // We use React.Children.forEach instead of React.Children.toArray().find()
  // here because toArray adds keys to all child elements and we do not want
  // to trigger an unmount/remount for two <Route>s that render the same
  // component at different URLs.
  // Note that unlike the configProps above, we don't want to filter out
  // routes based on the `condition` prop here. We need to render the route
  // component itself since that knows how to handle the condition.
  React.Children.forEach(children, (child) => {
    if (match === null && React.isValidElement(child)) {
      element = child;

      const path = child.props.path || child.props.from;

      match = path
        ? matchPath(location.pathname, { ...child.props, path })
        : routerContext.match;
    }
  });

  return match
    ? React.cloneElement(element as any, { location, computedMatch: match })
    : null;
};
