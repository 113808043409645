import {
  BaseValidator,
  CircleButton,
  DanishTinValidator,
  FinnishTinValidator,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  SwedishTinValidator,
  TextInput,
  CountrySelect,
  AllTinValidatorTypes,
  GermanTinValidator,
  WorldCountry,
  SpanishTinValidator,
  TinServerValidator,
} from "@lysaab/ui-2";
import React, { useContext } from "react";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { ReportingCountry } from "../../data/dataCrs";
import { TranslatedText } from "../TranslatedText";
import { DisallowedCountry, UsaValidator } from "../validators/UsaValidator";
import { LocalizationContext } from "../../context/LocalizationContext";

import "./TaxResidenceList.scss";
import { LysaCountry } from "@lysaab/shared";

const messages = defineMessages({
  taxResidenceLabel: {
    id: "taxResidenceList.taxResidence.label",
  },
  taxResidencePlaceholder: {
    id: "taxResidenceList.taxResidence.placeholder",
  },
  taxResidenceRequired: {
    id: "taxResidenceList.taxResidence.required",
  },
  tinLabel: {
    id: "taxResidenceList.tin.label",
  },
  tinRequired: {
    id: "taxResidenceList.tin.required",
  },
  tinValidation: {
    id: "taxResidenceList.tin.validation",
  },
});

const countryTinValidatorMap: Record<LysaCountry, AllTinValidatorTypes> = {
  [LysaCountry.DENMARK]: DanishTinValidator,
  [LysaCountry.FINLAND]: FinnishTinValidator,
  [LysaCountry.SWEDEN]: SwedishTinValidator,
  [LysaCountry.GERMANY]: GermanTinValidator,
  [LysaCountry.SPAIN]: SpanishTinValidator,
};

interface Props {
  data: ReportingCountry[];
  setData: (countries: ReportingCountry[]) => void;
  disablePrimaryTaxResidence?: boolean;
  omitCountries?: WorldCountry[];
}

export const TaxResidenceList = ({
  data,
  setData,
  disablePrimaryTaxResidence = true,
  omitCountries = [],
}: Props) => {
  const { country: userCountry, language } =
    useContext(LocalizationContext).state;
  const intl = useIntl();

  const taxResidenceList = data.map((reportingCountryData, index, arr) => {
    const tinValidators: BaseValidator[] = [
      new RequiredValidator(intl.formatMessage(messages.tinRequired)),
    ];

    const country = reportingCountryData.country as WorldCountry;
    const CountryTinValidator =
      countryTinValidatorMap[country as unknown as LysaCountry];

    if (CountryTinValidator) {
      const msg = intl.formatMessage(messages.tinValidation, {
        country,
      });
      tinValidators.push(new CountryTinValidator(msg));
    } else {
      const msg = intl.formatMessage(messages.tinValidation, {
        country,
      });
      if (country) {
        tinValidators.push(new TinServerValidator(msg, country));
      }
    }
    const isPrimaryTaxResidence = reportingCountryData.country === userCountry;

    return (
      <div
        className="tax-residence-list__country"
        key={"tax-residence-list__country" + country + index}
      >
        {!disablePrimaryTaxResidence && index === 0 && (
          <TaxResidenceInfo
            userCountry={userCountry}
            country={intl.formatDisplayName(userCountry || "", {
              type: "region",
            })}
          />
        )}
        <CountrySelect
          label={intl.formatMessage(messages.taxResidenceLabel)}
          placeholder={intl.formatMessage(messages.taxResidencePlaceholder)}
          value={reportingCountryData.country as WorldCountry}
          onChange={({ value }) => {
            const crsReportingCountries = [...data];
            crsReportingCountries[index] = {
              ...crsReportingCountries[index],
              country: value,
            };
            setData(crsReportingCountries);
          }}
          validators={[
            new RequiredValidator(
              intl.formatMessage(messages.taxResidenceRequired)
            ),
            new UsaValidator(" "),
          ]}
          disabled={isPrimaryTaxResidence && disablePrimaryTaxResidence}
          language={language}
          omitList={[
            ...data
              .filter(({ country }) => country !== reportingCountryData.country)
              .map(({ country }) => country as WorldCountry),
            ...omitCountries,
          ]}
        />
        {data.find((country, idx) => {
          return (
            country.country === DisallowedCountry.UNITED_STATES_OF_AMERICA &&
            idx === index
          );
        }) && (
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <p>
              <TranslatedText id="taxResidenceList.taxResidence.usa" />
            </p>
          </Snackbar>
        )}
        {!isPrimaryTaxResidence && (
          <TextInput
            label={intl.formatMessage(messages.tinLabel, { country })}
            value={reportingCountryData.tin || ""}
            onChange={(tin) => {
              const crsReportingCountries = [...data];
              crsReportingCountries[index] = {
                ...crsReportingCountries[index],
                tin: tin,
              };
              setData(crsReportingCountries);
            }}
            validators={tinValidators}
            disabled={isPrimaryTaxResidence && disablePrimaryTaxResidence}
          />
        )}
        {disablePrimaryTaxResidence && index === 0 && (
          <TaxResidenceInfo
            userCountry={userCountry}
            country={intl.formatDisplayName(userCountry || "", {
              type: "region",
            })}
          />
        )}
        {!isPrimaryTaxResidence && arr.length > 1 && (
          <CircleButton
            onClick={() => {
              const crsReportingCountries = [...data];
              crsReportingCountries.splice(index, 1);
              setData(crsReportingCountries);
            }}
            icon="Minus"
          />
        )}
        {index === arr.length - 1 && (
          <CircleButton
            onClick={() => {
              const crsReportingCountries = [...data];
              crsReportingCountries.push({
                tin: "",
                country: undefined,
              });
              setData(crsReportingCountries);
            }}
            icon="Plus"
          />
        )}
      </div>
    );
  });
  return <>{taxResidenceList}</>;
};

const elevioArticle: Record<LysaCountry, number> = {
  [LysaCountry.SWEDEN]: 166,
  [LysaCountry.FINLAND]: 166,
  [LysaCountry.DENMARK]: 166,
  [LysaCountry.GERMANY]: 166,
  [LysaCountry.SPAIN]: 166,
};

const TaxResidenceInfo = ({
  country,
  userCountry,
}: {
  country?: string;
  userCountry?: LysaCountry;
}) => {
  return (
    <p>
      <FormattedMessage
        id="taxResidenceList.taxResidence.info"
        values={{
          country,
          help: (parts) =>
            userCountry && (
              <span data-elevio-article={elevioArticle[userCountry]}>
                <span className="loner link">{parts}</span>
              </span>
            ),
        }}
      />
    </p>
  );
};
