import {
  Card,
  LysaLink,
  Select,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, { useCallback, useEffect, useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { GridCol } from "../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../components/grid/gridRow/GridRow";
import { TranslatedText } from "../../../../components/TranslatedText";
import { dataTax, DeTaxResponse } from "../../../../data/dataTax";
import { Page, PageHeader } from "../../../../pages/Page";
import { TaxDetails } from "./components/TaxDetails";

import "./TaxInformation.scss";
import "./TaxPrint.scss";

const messages = defineMessages({
  yearsLabel: {
    id: "germany.tax.years.label",
  },
});

export const TaxInformation: React.VFC = () => {
  const [year, setYear] = useState<number>();
  const [error, setError] = useState(false);
  const [years, setYears] = useState<number[]>([]);
  const intl = useIntl();
  const [taxInformation, setTaxInformation] = useState<DeTaxResponse>();
  const [loading, setLoading] = useState<boolean>();

  const doTaxChange = useCallback((year: number) => {
    setLoading(true);
    dataTax
      .getDeTaxDetails(year)
      .then((taxInformation) => {
        setTaxInformation(taxInformation);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (typeof year === "undefined" && years.length > 0) {
      setYear(Math.max(...years));
    }
  }, [year, years]);

  useEffect(() => {
    dataTax.getDeTaxYears().then((response) => {
      if (response.length > 0) {
        setYears(response);
      }
    });
  }, []);

  useEffect(() => {
    if (year) {
      doTaxChange(year);
    }
  }, [doTaxChange, year]);

  if (error) {
    return (
      <Page>
        <Snackbar type={SNACKBAR_TYPES.ERROR}>
          <TranslatedText id="germany.tax.error" />
        </Snackbar>
      </Page>
    );
  }

  if (!years) {
    return <Spinner />;
  }

  return (
    <Page className="germany-tax-page">
      <PageHeader>
        <Typography type="h1">
          <TranslatedText id="germany.tax.page-header" />
        </Typography>
        <div className="external-tax-information-text">
          <TranslatedText
            id="germany.tax.additional-information"
            values={{
              link: (text) => {
                return (
                  <LysaLink
                    external
                    rel="noreferrer noopener"
                    target="_blank"
                    href="https://lysa.de/knowledge/steuerinformationen"
                  >
                    {text}
                  </LysaLink>
                );
              },
            }}
          />
        </div>
      </PageHeader>
      <GridRow>
        <GridCol xsmall={12} className="select-column">
          <Typography type="h2">
            <TranslatedText id="germany.tax.select-header" />
          </Typography>
          <Card>
            <Select
              alternatives={years.map((year) => ({
                value: year,
                text: year.toString(),
              }))}
              placeholder={intl.formatMessage(messages.yearsLabel)}
              label={intl.formatMessage(messages.yearsLabel)}
              onChange={({ value }) => setYear(value)}
              value={year ? { value: year, text: year.toString() } : undefined}
            />
          </Card>
        </GridCol>
        <GridCol xsmall={12}>
          <TaxDetails
            year={year}
            loading={loading}
            information={taxInformation}
          />
        </GridCol>
      </GridRow>
    </Page>
  );
};
