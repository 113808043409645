import React, {
  useContext,
  useEffect,
  useCallback,
  FunctionComponent,
} from "react";
import { LayoutContext } from "../../context/LayoutContext";
import { defineMessages, FormattedMessage, useIntl } from "react-intl";
import { Store, STORE_SETTINGS_COOKIE_BANNER_OK } from "../../Store";
import { Button } from "@lysaab/ui-2/components/buttons/Button";
import { LocalizationContext } from "../../context/LocalizationContext";
import { AnimateHeight, DocModalLink, Typography } from "@lysaab/ui-2";
import { LysaCountry } from "@lysaab/shared";

import "./CookieConsent.scss";

const messages = defineMessages({
  modalAnnouncement: {
    id: "cookieConsent.modalAnnouncement",
  },
});

const cookieDocuments: Record<LysaCountry, string> = {
  [LysaCountry.SWEDEN]: "legal/se/sv/kakor-1.md",
  [LysaCountry.DENMARK]: "legal/all/en/cookies.md",
  [LysaCountry.FINLAND]: "legal/all/en/cookies.md",
  [LysaCountry.GERMANY]: "legal/de/de/cookies.md",
  [LysaCountry.SPAIN]: "legal/all/en/cookies.md",
};

const CookieLinkButton: FunctionComponent<
  React.PropsWithChildren<React.ButtonHTMLAttributes<HTMLButtonElement>>
> = (props) => {
  return <button type="button" className="cookie-link-button" {...props} />;
};

let hasInjected = false;

export const CookieConsent: React.FC<{}> = () => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const { setState, state } = useContext(LayoutContext);

  useEffect(() => {
    insertPlausibleScript();
  }, []);

  useEffect(() => {
    if (Store.getValue(STORE_SETTINGS_COOKIE_BANNER_OK)) {
      return;
    }

    setState({
      showCookieConsent: true,
    });
  }, [setState]);

  const bannerOk = useCallback(() => {
    Store.setValue(STORE_SETTINGS_COOKIE_BANNER_OK, true);
    setState({ showCookieConsent: false });
  }, [setState]);

  if (!localizationContext.state.country) {
    return null;
  }

  return (
    <div className="baked-dough-consent">
      <AnimateHeight isOpen={state.showCookieConsent}>
        <article>
          <Typography type="body">
            <FormattedMessage
              id="cookieConsent.paragraph"
              values={{
                link: (
                  <DocModalLink
                    document={
                      cookieDocuments[localizationContext.state.country]
                    }
                    modalAnnouncement={intl.formatMessage(
                      messages.modalAnnouncement
                    )}
                    component={CookieLinkButton}
                  >
                    <FormattedMessage id="cookieConsent.link" />
                  </DocModalLink>
                ),
              }}
            />
          </Typography>
          <Button
            size="small"
            onClick={bannerOk}
            data-test-id="accept-cookies-button"
            label={<FormattedMessage id="cookieConsent.button.accept" />}
          />
        </article>
      </AnimateHeight>
    </div>
  );
};

function insertPlausibleScript() {
  if (hasInjected) {
    return;
  }
  const newScript = document.createElement("script");
  const existingScriptTag = document.getElementsByTagName("script")[0];
  newScript.defer = true;
  newScript.src = "https://analytics.lysa.se/js/script.local.manual.js";
  newScript.dataset.domain =
    process.env.NODE_ENV === "production"
      ? window.location.hostname
      : "app.lysa-test.se";
  existingScriptTag.parentNode?.insertBefore(newScript, existingScriptTag);
  hasInjected = true;
}
