import {
  Card,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  useDebounceFn,
} from "@lysaab/ui-2";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import {
  InvestmentAccount,
  AllAccountResponse,
  dataAccounts,
  InvestmentAccountId,
  InvestmentType,
  SavingsAccountId,
} from "../../../data/dataAccounts";
import {
  ClosedAccount,
  ClosedSavingsAccount,
  dataProfile,
} from "../../../data/dataProfile";
import {
  dataTransactions,
  Filter,
  Transaction,
} from "../../../data/dataTransactions";
import { TransactionCard } from "./transactionCard/TransactionCard";
import { Filters } from "./filters/Filters";
import { DateTime } from "luxon";
import { GridRow } from "../../../components/grid/gridRow/GridRow";
import { GridCol } from "../../../components/grid/gridCol/GridCol";
import { TranslatedText } from "../../../components/TranslatedText";
import { dataInvestments } from "../../../data/dataInvestments";
import { useLocation } from "react-router";
import { HistoricTransactionsPageLocationState } from "../HistoricTransactionsPage";

export interface ActiveAccountInformation {
  name: string;
  investmentType: InvestmentType;
  risk: number;
  worth: number;
}

export interface ClosedAccountInformation {
  name: string;
  closed: string;
}

export interface SavingsAccountInformation {
  name: string;
  worth: number;
}

export type AccountInformation = Record<
  InvestmentAccountId | SavingsAccountId,
  | ActiveAccountInformation
  | ClosedAccountInformation
  | SavingsAccountInformation
>;

export const HistoricTransactions = () => {
  const location = useLocation<
    HistoricTransactionsPageLocationState | undefined
  >();
  const { accountId: defaultAccountId } = location.state || {};
  const [closedAccounts, setClosedAccounts] = useState<
    (ClosedAccount | ClosedSavingsAccount)[]
  >([]);
  const [activeAccounts, setActiveAccounts] = useState<AllAccountResponse>({
    investmentAccounts: [],
    savingsAccounts: [],
    sharedInvestmentAccounts: [],
    sharedSavingsAccounts: [],
  });
  const [transactions, setTransactions] = useState<Transaction[]>([]);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);
  const [accountInformation, setAccountInformation] =
    useState<AccountInformation>();
  const [filter, setFilter] = useState<Filter>();
  const [error, setError] = useState(false);

  useEffect(() => {
    Promise.all([
      dataAccounts.getAllAccounts(),
      dataProfile.getClosedAccounts(),
      dataProfile.getClosedSavingsAccounts(),
      dataInvestments.getAdviseAccounts(),
    ]).then(
      ([
        activeAccounts,
        closedAccounts,
        closedSavingsAccounts,
        adviseAccount,
      ]) => {
        const allActiveInvestmentAccounts = [
          ...activeAccounts.investmentAccounts,
          ...activeAccounts.sharedInvestmentAccounts,
        ];
        const allClosedAccounts = [...closedAccounts, ...closedSavingsAccounts];

        setClosedAccounts(allClosedAccounts);
        setActiveAccounts(activeAccounts);
        const accountInformation: AccountInformation = {};

        allClosedAccounts.forEach((account) => {
          accountInformation[account.accountId] = {
            name: account.name,
            closed: account.closed,
          } as ClosedAccountInformation;
        });

        allActiveInvestmentAccounts.forEach((account) => {
          const accountAdvise = adviseAccount.find(
            (adviseAccount) => adviseAccount.accountId === account.accountId
          );
          if (typeof accountAdvise === "undefined") {
            return;
          }

          accountInformation[account.accountId] = {
            name: account.name,
            worth: account.worth,
            risk: accountAdvise.takenRisk,
            investmentType: accountAdvise.investmentType,
          };
        });

        const allActiveSavingsAccounts = [
          ...activeAccounts.savingsAccounts,
          ...activeAccounts.sharedSavingsAccounts,
        ];
        allActiveSavingsAccounts.forEach((account) => {
          accountInformation[account.accountId] = {
            name: account.name,
            worth: account.totalBalance,
          };
        });
        setAccountInformation(accountInformation);
      }
    );
  }, []);

  const loadTransactions = useCallback((filter: Filter) => {
    const transactionsPromise =
      filter.accountIds.length > 0
        ? dataTransactions.getTransactions(filter)
        : [];
    const closedAccountTransactionPromise =
      filter.accountIds.length > 0
        ? dataProfile.getClosedAccountsTransactions(filter)
        : [];
    Promise.all([transactionsPromise, closedAccountTransactionPromise])
      .then(([activeAccountTransactions, closedAccountTransactions]) => {
        setTransactions(
          [...activeAccountTransactions, ...closedAccountTransactions].sort(
            (transactionA, transactionB) =>
              DateTime.fromISO(transactionB.booked).diff(
                DateTime.fromISO(transactionA.booked)
              ).milliseconds
          )
        );
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setIsTransactionsLoading(false);
      });
  }, []);

  const debouncedLoadTransactions = useDebounceFn(loadTransactions, 1000);

  useEffect(() => {
    if (typeof filter === "undefined") {
      return;
    }
    setIsTransactionsLoading(true);
    debouncedLoadTransactions(filter);
  }, [debouncedLoadTransactions, filter]);

  return (
    <GridRow>
      <GridCol xsmall={12} medium={5}>
        <Card>
          {error && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="historicTransactionsPage.historicTransactions.error" />
            </Snackbar>
          )}
          <Filters
            setFilter={setFilter}
            activeAccounts={activeAccounts}
            closedAccounts={closedAccounts}
            defaultAccountId={defaultAccountId}
          />
        </Card>
      </GridCol>
      <GridCol xsmall={12} medium={7}>
        {isTransactionsLoading || typeof accountInformation === "undefined" ? (
          <Spinner />
        ) : transactions.length > 0 ? (
          transactions.map((transaction, index) => (
            <TransactionCard
              key={transaction.booked + index}
              transaction={transaction}
              accountInformation={accountInformation[transaction.accountId]}
            />
          ))
        ) : (
          <Card>
            <TranslatedText id="historicTransactionsPage.historicTransactions.noTransactions" />
          </Card>
        )}
      </GridCol>
    </GridRow>
  );
};

export function isClosedAccount(
  account: InvestmentAccount | ClosedAccount
): account is ClosedAccount {
  return account.hasOwnProperty("closed");
}
