import React, {
  useContext,
  useCallback,
  useState,
  useEffect,
  useRef,
} from "react";
import cx from "classnames";
import { PerformanceContext } from "../../context/PerformanceContext";
import { Interval } from "../../data/dataPerformance";
import { useWidth } from "../../hooks/useWidth";
import { FormattedMessage } from "react-intl";
import { Icon } from "@lysaab/ui-2";
import "./IntervalSwitch.scss";

export function IntervalSwitch() {
  const { setState, interval } = useContext(PerformanceContext);
  const [showRightChevron, setShowRightChevron] = useState(false);
  const [showLeftChevron, setShowLeftChevron] = useState(false);
  const width = useWidth();
  const slider = useRef<HTMLDivElement>(null);
  const sliderWidth = useRef<number>(0);

  const onClick = useCallback(
    (ev: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const interval = (ev.currentTarget as HTMLElement).getAttribute(
        "data-interval"
      ) as Interval;
      setState({
        interval,
      });
    },
    [setState]
  );

  const getClasses = useCallback(
    // TODO: Type this
    (intervalOfButton: any) => {
      if (intervalOfButton === Interval.CUSTOM) {
        return intervalOfButton === interval
          ? "active custom-interval-button interval-button"
          : "custom-interval-button interval-button";
      }
      if (intervalOfButton === interval) {
        return "active interval-button";
      }
      return "interval-button";
    },
    [interval]
  );

  useEffect(() => {
    if (!slider.current) {
      return;
    }

    const activeElem = slider.current.querySelector(".active");
    const boxSlider = slider.current.getBoundingClientRect();
    const boxActiveInterval = activeElem?.getBoundingClientRect();
    if (!boxActiveInterval) {
      return;
    }
    const width = boxSlider.width;
    const scrollWidth = slider.current.scrollWidth;
    sliderWidth.current = width;

    if (width >= scrollWidth) {
      setShowLeftChevron(false);
      setShowRightChevron(false);
      return;
    }

    const maxScrollOffset = scrollWidth - width;
    const halfWidth = width / 2;
    const currentScrollPositionOfActiveInterval =
      boxActiveInterval.left - boxSlider.left;
    const scrollLeft = Math.min(
      maxScrollOffset,
      Math.max(currentScrollPositionOfActiveInterval - halfWidth, 0)
    );

    if (scrollLeft <= 0) {
      setShowLeftChevron(false);
      setShowRightChevron(true);
    } else if (maxScrollOffset - scrollLeft <= 2) {
      setShowLeftChevron(true);
      setShowRightChevron(false);
    } else {
      setShowLeftChevron(true);
      setShowRightChevron(true);
    }

    slider.current.scrollLeft = scrollLeft;
  }, [width]);

  useEffect(() => {
    if (!slider.current) {
      return;
    }

    const sliderElem = slider.current;
    const onScroll = () => {
      const scrollLeft = sliderElem.scrollLeft;
      const scrollWidth = sliderElem.scrollWidth;
      const maxScrollOffset = scrollWidth - sliderWidth.current;
      if (scrollLeft <= 0) {
        setShowLeftChevron(false);
        setShowRightChevron(true);
      } else if (maxScrollOffset - scrollLeft <= 2) {
        setShowLeftChevron(true);
        setShowRightChevron(false);
      } else {
        setShowLeftChevron(true);
        setShowRightChevron(true);
      }
    };

    sliderElem.addEventListener("scroll", onScroll);

    return () => {
      sliderElem.removeEventListener("scroll", onScroll);
    };
  }, [slider]);

  const scrollLeft = useCallback(() => {
    if (!slider.current) {
      return;
    }
    slider.current.scrollLeft = 0;
  }, []);

  const scrollRight = useCallback(() => {
    if (!slider.current) {
      return;
    }
    slider.current.scrollLeft =
      slider.current.scrollWidth - sliderWidth.current;
  }, []);

  return (
    <div
      className={cx("interval-switch", {
        "show-custom-dates": interval === Interval.CUSTOM,
        "show-left-chevron": showLeftChevron,
        "show-right-chevron": showRightChevron,
      })}
    >
      <div className="interval-switch-slider" ref={slider}>
        <button
          className="interval-switch-prev"
          onClick={scrollLeft}
          aria-label="Show shorter graph intervals"
        >
          <Icon.ChevronLeft size={16} />
        </button>

        <button
          className="interval-switch-next"
          onClick={scrollRight}
          aria-label="Show longer graph intervals"
        >
          <Icon.ChevronRight size={16} />
        </button>

        <div>
          <button
            className={getClasses(Interval.LAST_THREE_MONTHS)}
            data-interval={Interval.LAST_THREE_MONTHS}
            onClick={onClick}
          >
            <span>
              <FormattedMessage
                id="overview.graphCard.interval.lastThreeMonths"
                description="interval switch last three months"
                defaultMessage="3 months"
              />
            </span>
          </button>

          <button
            className={getClasses(Interval.LAST_SIX_MONTHS)}
            data-interval={Interval.LAST_SIX_MONTHS}
            onClick={onClick}
          >
            <span>
              <FormattedMessage
                id="overview.graphCard.interval.lastSixMonths"
                description="interval switch last six months"
                defaultMessage="6 months"
              />
            </span>
          </button>

          <button
            className={getClasses(Interval.CURRENT_YEAR)}
            data-interval={Interval.CURRENT_YEAR}
            onClick={onClick}
          >
            <span>
              <FormattedMessage
                id="overview.graphCard.interval.currentYear"
                description="interval switch current year"
                defaultMessage="This year"
              />
            </span>
          </button>

          <button
            className={getClasses(Interval.LAST_YEAR)}
            data-interval={Interval.LAST_YEAR}
            onClick={onClick}
          >
            <span>
              <FormattedMessage
                id="overview.graphCard.interval.lastYear"
                description="interval switch last year"
                defaultMessage="Last year"
              />
            </span>
          </button>

          <button
            className={getClasses(Interval.SINCE_REGISTRATION)}
            data-interval={Interval.SINCE_REGISTRATION}
            onClick={onClick}
          >
            <span>
              <FormattedMessage
                id="overview.graphCard.interval.sinceRegistration"
                description="interval switch since registration"
                defaultMessage="Since start"
              />
            </span>
          </button>

          <button
            className={getClasses(Interval.CUSTOM)}
            data-interval={Interval.CUSTOM}
            onClick={onClick}
          >
            <span>
              <Icon.Calendar
                color={interval === Interval.CUSTOM ? "#1840e3" : "#111"}
              />
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}
