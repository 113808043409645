import { Icon } from "@lysaab/ui-2";
import React, { useContext } from "react";
import { defineMessages, useIntl } from "react-intl";
import { WithdrawalItem } from "../../components/withdrawalItem/WithdrawalItem";
import { UserContext } from "../../context/UserContext";
import { ROUTES } from "../../countries/sweden/pages/withdrawal/kf/request/WithdrawalKfRequestPage";
import { InvestmentAccount } from "../../data/dataAccounts";
import {
  KF_WITHDRAWAL_PENDING_STATUS,
  PendingKfWithdrawalResponse,
} from "../../data/dataDanica";
import { LegalEntityType } from "../../data/dataLogin";
import { getDynamicLink } from "../../hooks/useCountryUrls";

interface Props {
  accounts: InvestmentAccount[];
  kfPendingWithdrawals: PendingKfWithdrawalResponse[];
}

export const messages = defineMessages({
  [KF_WITHDRAWAL_PENDING_STATUS.PENDING_DANICA]: {
    id: "withdrawals.pending.kf.status.pendingDanica",
    description: "KF pending withdrawals - pending Futur",
    defaultMessage: "Futur is verifying withdrawal",
  },
  [KF_WITHDRAWAL_PENDING_STATUS.PENDING_SIGNING]: {
    id: "withdrawals.pending.kf.status.pendingSigning",
    description: "KF pending withdrawals - pending signing",
    defaultMessage:
      "One or more of the persons authorized to sign for the firm have not yet done so. <link>Sign here</link>",
  },
  [KF_WITHDRAWAL_PENDING_STATUS.PENDING_DANICA_MANUAL_VERIFICATION]: {
    id: "withdrawals.pending.kf.status.pendingDanicaManual",
    description: "KF pending withdrawals - manual",
    defaultMessage:
      "Futur will verify the withdrawal manually. We'll send you an email when they are done.",
  },
  [KF_WITHDRAWAL_PENDING_STATUS.WITHDRAWAL_ONGOING]: {
    id: "withdrawals.pending.kf.status.withdrawalOngoing",
    description: "KF pending withdrawals - ongoing",
    defaultMessage:
      "We're selling your assets. You'll have your money within a few days.",
  },
});

export const KfPendingWithdrawals: React.FunctionComponent<Props> = ({
  accounts,
  kfPendingWithdrawals,
}) => {
  const userContext = useContext(UserContext);
  const user = userContext.state;
  const intl = useIntl();

  const isPerson = user.legalEntityType === LegalEntityType.PERSON;

  if (isPerson) {
    return null;
  }

  const items = kfPendingWithdrawals.map((pendingWithdrawal) => {
    const account = accounts.find(
      (item) => item.accountId === pendingWithdrawal.accountId
    );

    if (!account) {
      return null;
    }

    let statusText = null;
    if (
      Object.values(KF_WITHDRAWAL_PENDING_STATUS).includes(
        pendingWithdrawal.status
      )
    ) {
      if (
        pendingWithdrawal.status ===
        KF_WITHDRAWAL_PENDING_STATUS.PENDING_SIGNING
      ) {
        statusText = (
          <>
            <span className="icon-wrapper">
              <Icon.Error size={20} color="#ff7576" />
            </span>
            {intl.formatMessage<React.ReactNode>(
              messages[pendingWithdrawal.status],
              {
                link: (parts) => (
                  <a
                    key={pendingWithdrawal.accountId}
                    href={getDynamicLink(ROUTES.SIGNERS, {
                      signingId: pendingWithdrawal.signingId,
                    })}
                  >
                    {parts}
                  </a>
                ),
              }
            )}
          </>
        );
      } else {
        statusText = intl.formatMessage(messages[pendingWithdrawal.status]);
      }
    }

    return (
      <div className="pending-kf-withdrawal" key={pendingWithdrawal.accountId}>
        <WithdrawalItem
          moneyOnAccount={account.worth}
          name={account.name}
          date={new Date(pendingWithdrawal.requested)}
          externalBank={pendingWithdrawal.bank}
          externalBankAccount={pendingWithdrawal.externalBankAccount}
          withdrawalAmount={
            pendingWithdrawal.drain
              ? account.worth.toString()
              : pendingWithdrawal.amount?.toString()
          }
        />
        <div className="pending-kf-status">{statusText}</div>
      </div>
    );
  });

  return <>{items}</>;
};
