import React, { useReducer, createContext, FunctionComponent } from "react";
import { CompoundAccountId } from "../../data/dataAccounts";

const DEFAULT_TRANSFER_DAY = 29;

export interface MonthlyInternalTransferState {
  id?: string;
  fromAccountId?: CompoundAccountId;
  toAccountId?: CompoundAccountId;
  transferDay?: number;
  amount?: number;
}

export interface MonthlyInternalTransferContextProps {
  state: MonthlyInternalTransferState;
  setState: (newState: Partial<MonthlyInternalTransferState>) => void;
}

export const MonthlyInternalTransferContext = createContext<
  MonthlyInternalTransferContextProps | undefined
>(undefined);

function stateReducer(
  state: MonthlyInternalTransferState,
  newState: Partial<MonthlyInternalTransferState>
) {
  return { ...state, ...newState };
}

export const MonthlyInternalTransferContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    transferDay: DEFAULT_TRANSFER_DAY,
  });

  return (
    <MonthlyInternalTransferContext.Provider value={{ state, setState }}>
      {children}
    </MonthlyInternalTransferContext.Provider>
  );
};

export function useMonthlyInternalTransferContext() {
  const context = React.useContext(MonthlyInternalTransferContext);
  if (context === undefined) {
    throw new Error(
      "useMonthlyInternalTransferContext must be used within a MonthlyInternalTransferContext"
    );
  }
  return context;
}
