import React, { useReducer } from "react";

export interface BurgerMenuState {
  isOpen: boolean;
}

export interface BurgerMenuContextProps {
  state: BurgerMenuState;
  setState: (newState: Partial<BurgerMenuState>) => void;
}

export const BurgerMenuContext = React.createContext<BurgerMenuContextProps>(
  {} as BurgerMenuContextProps
);

export interface withBurgerMenuProps {
  burgermenu: BurgerMenuContextProps;
}

export const withBurgerMenu =
  <P extends object>(
    Component: React.ComponentType<React.PropsWithChildren<P>>
  ): React.FC<React.PropsWithChildren<Omit<P, keyof withBurgerMenuProps>>> =>
  (props) =>
    (
      <BurgerMenuContext.Consumer>
        {(contextProps) => (
          <Component {...(props as P)} burgermenu={contextProps} />
        )}
      </BurgerMenuContext.Consumer>
    );

function stateReducer(
  state: BurgerMenuState,
  newState: Partial<BurgerMenuState>
) {
  return { ...state, ...newState };
}

export const BurgerMenuContextProvider: React.FC<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, { isOpen: false });

  return (
    <BurgerMenuContext.Provider value={{ state, setState }}>
      {children}
    </BurgerMenuContext.Provider>
  );
};
