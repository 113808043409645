import { Typography } from "@lysaab/ui-2";
import React from "react";
import { TranslatedText } from "../TranslatedText";

import "./CloseAccountReasonsList.scss";

interface Props {
  reasons: Array<string>;
  className?: string;
}

export const CloseAccountReasonsList = ({ reasons, className }: Props) => {
  return (
    <div className={className}>
      <Typography>
        <TranslatedText id="closeAccountReasonsList.header" />
      </Typography>
      <ul className="close-account-reasons-list">
        {reasons.map((reason) => (
          <li key={reason}>
            <Typography component="span">{reason}</Typography>
          </li>
        ))}
      </ul>
    </div>
  );
};
