import React, { VoidFunctionComponent } from "react";
import { defineMessages, useIntl } from "react-intl";
import { RebatePeriodResponse } from "../../../../data/dataFees";
import { CostBodyCell } from "../components/costBodyCell/CostBodyCell";
import { CostHeaderCell } from "../components/costHeaderCell/CostHeaderCell";
import { FeeBodyCell } from "../components/feeBodyCell/FeeBodyCell";
import { FeeHeaderCell } from "../components/feeHeaderCell/FeeHeaderCell";
import { LysaTable } from "../../../../components/lysaTable/LysaTable";
import { LysaTableTextCell } from "../../../../components/lysaTableTextCell/LysaTableTextCell";

const messages = defineMessages({
  start: {
    id: "fees.feeTables.rebate.start",
  },
  end: {
    id: "fees.feeTables.rebate.end",
  },
  rebatedfee: {
    id: "fees.feeTables.rebate.rebatedfee",
  },
  rebatedCost: {
    id: "fees.feeTables.rebate.rebatedCost",
  },
});
interface Props {
  rebates: RebatePeriodResponse[];
}

export const RebateTable: VoidFunctionComponent<Props> = ({ rebates }) => {
  const intl = useIntl();
  return (
    <LysaTable>
      <thead>
        <tr>
          <LysaTableTextCell
            value={intl.formatMessage(messages.start)}
            header={true}
          />
          <LysaTableTextCell
            value={intl.formatMessage(messages.end)}
            header={true}
          />
          <FeeHeaderCell text={intl.formatMessage(messages.rebatedfee)} />
          <CostHeaderCell text={intl.formatMessage(messages.rebatedCost)} />
        </tr>
      </thead>
      <tbody>
        {rebates.map((rebate, index) => {
          return (
            <tr key={rebate.start + index}>
              <React.Fragment key={rebate.start + index}>
                <LysaTableTextCell
                  value={intl.formatDate(rebate.start)}
                  label={intl.formatMessage(messages.start)}
                />
                <LysaTableTextCell
                  value={intl.formatDate(rebate.end)}
                  label={intl.formatMessage(messages.end)}
                />
                <FeeBodyCell
                  value={rebate.rebatedFee}
                  label={intl.formatMessage(messages.rebatedfee)}
                />
                <CostBodyCell
                  value={rebate.rebatedCost}
                  label={intl.formatMessage(messages.rebatedCost)}
                />
              </React.Fragment>
            </tr>
          );
        })}
      </tbody>
    </LysaTable>
  );
};
