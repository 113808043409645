import React, {
  useState,
  useCallback,
  useContext,
  VoidFunctionComponent,
} from "react";
import { Icon, Snackbar, SNACKBAR_TYPES, Spinner } from "@lysaab/ui-2";
import { FormattedMessage } from "react-intl";
import { OtpStep } from "../../../../pages/login/OtpStep";
import { PasswordStep } from "../../../../pages/login/PasswordStep";
import { LocalizationContext } from "../../../../context/LocalizationContext";
import { LoginFooter } from "../../../../pages/login/LoginFooter";
import "./LoginTinPasswordPage.scss";

export const LOGIN_TIN_PASSWORD_PAGE_URL = "/login-tin-password";
export const LOGIN_TIN_PASSWORD_PAGE_DEPRECATED_URL =
  "/login-tin-password-deprecated";

export enum OtpType {
  EMAIL = "EMAIL",
  TOTP = "TOTP",
}

interface Props {
  allowUsernameReminder?: boolean;
}

export const LoginTinPasswordPage: VoidFunctionComponent<Props> = ({
  allowUsernameReminder = false,
}) => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [otp, setOtp] = useState<string>("");
  const [otpType, setOtpType] = useState<OtpType>();
  const localizationContext = useContext(LocalizationContext);

  const reset = useCallback(() => {
    setOtp("");
    setUsername("");
    setPassword("");
    setOtpType(undefined);
  }, []);

  const country = localizationContext.state.country;

  if (!country) {
    return (
      <div>
        <Spinner />
      </div>
    );
  }

  return (
    <div className="denmark-login-tin-password-page">
      <header className="denmark-login-tin-password-page-header">
        <Icon.Logo />
      </header>
      <div className="center-wrapper">
        <h1>
          <FormattedMessage id="denmark.login-tin-password.header" />
        </h1>
        <Snackbar type={SNACKBAR_TYPES.WARNING}>
          <FormattedMessage
            id="denmark.login-tin-password.only-for-old-accounts"
            values={{ country }}
          />
        </Snackbar>
        {otpType ? (
          <OtpStep
            otp={otp}
            setOtp={setOtp}
            onSubmit={() => {}}
            otpType={otpType}
            username={username}
            onReset={reset}
          />
        ) : (
          <PasswordStep
            username={username}
            setUsername={setUsername}
            password={password}
            setPassword={setPassword}
            setOtpType={setOtpType}
          />
        )}
        <LoginFooter allowUsernameReminder={allowUsernameReminder} />
      </div>
    </div>
  );
};
