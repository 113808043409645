import { cache } from "@lysaab/ui-2";
import React, {
  useReducer,
  createContext,
  FunctionComponent,
  useEffect,
} from "react";
import { dataAccounts } from "../data/dataAccounts";
import { BASE_URL_ACCOUNT_SHARE, dataAttorney } from "../data/dataAttorney";
import { dataLogin } from "../data/dataLogin";
import { useInterval } from "../hooks/useInterval";

let shareAccountString: string | undefined = undefined;

const hasAccountSharesChanged = async () => {
  cache.delete(BASE_URL_ACCOUNT_SHARE + "/received");
  const accountShares = await dataAttorney.getAccountSharesWithMe();
  const responseString = JSON.stringify(accountShares);
  const hasChanged = responseString !== shareAccountString;
  if (shareAccountString && hasChanged) {
    shareAccountString = undefined;
    return true;
  }
  shareAccountString = responseString;
  return false;
};

export interface FeatureConfigurationContextState {
  sharedAccountAccepted: boolean;
}

export interface FeatureConfigurationContextProps {
  state: FeatureConfigurationContextState;
  setState: (newState: Partial<FeatureConfigurationContextState>) => void;
}

export const FeatureConfigurationContext =
  createContext<FeatureConfigurationContextProps>(
    {} as FeatureConfigurationContextProps
  );

function stateReducer(
  state: FeatureConfigurationContextState,
  newState: Partial<FeatureConfigurationContextState>
) {
  return { ...state, ...newState };
}

export const FeatureConfigurationContextProvider: FunctionComponent<
  React.PropsWithChildren<unknown>
> = ({ children }) => {
  const [state, setState] = useReducer(stateReducer, {
    sharedAccountAccepted: false,
  });

  useEffect(() => {
    if (state.sharedAccountAccepted) {
      hasAccountSharesChanged().catch();
    }
  }, [state.sharedAccountAccepted]);

  useInterval(
    async () => {
      try {
        if (await hasAccountSharesChanged()) {
          setState({ sharedAccountAccepted: false });
          await dataLogin.refresh();
          dataAccounts.clearGetAccounts();
          cache.delete("/investments/advice");
          dataAccounts.getAllAccounts();
        }
      } catch {
        // Swallow this silently
      }
    },
    state.sharedAccountAccepted ? 10 * 1000 : null
  );

  return (
    <FeatureConfigurationContext.Provider value={{ state, setState }}>
      {children}
    </FeatureConfigurationContext.Provider>
  );
};

export function useFeatureConfigurationContext() {
  const context = React.useContext(FeatureConfigurationContext);
  if (context === undefined) {
    throw new Error(
      "useFeatureConfigurationContext must be used within a FeatureConfigurationContextProvider"
    );
  }
  return context;
}
