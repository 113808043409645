import React, { useContext, VoidFunctionComponent } from "react";
import { RequiredValidator, Card, CheckboxGroup } from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { banks as availableBanks, BankType } from "../../../data/dataKyc";
import { LocalizationContext } from "../../../context/LocalizationContext";
import { UserContext } from "../../../context/UserContext";
import { LysaCountry } from "@lysaab/shared";

export const messages = defineMessages({
  bankHeader: {
    id: "sweden.kyc.bank.header",
  },
  bankRequired: {
    id: "sweden.kyc.bank.required",
  },
});

interface MessageWithId {
  id: string;
}

type CountryMessages =
  | Record<BankType<LysaCountry.SWEDEN>, MessageWithId>
  | Record<BankType<LysaCountry.DENMARK>, MessageWithId>
  | Record<BankType<LysaCountry.FINLAND>, MessageWithId>
  | Record<BankType<LysaCountry.GERMANY>, MessageWithId>
  | Record<BankType<LysaCountry.SPAIN>, MessageWithId>;

const swedenMessages = defineMessages<
  BankType<LysaCountry.SWEDEN>,
  MessageWithId,
  Record<BankType<LysaCountry.SWEDEN>, MessageWithId>
>({
  SE_SWEDBANK: {
    id: `sweden.kyc.bank.alt.SV_SWEDBANK`,
  },
  SE_HB: {
    id: `sweden.kyc.bank.alt.SV_HB`,
  },
  SE_NORDEA: {
    id: `sweden.kyc.bank.alt.SV_NORDEA`,
  },
  SE_SEB: {
    id: `sweden.kyc.bank.alt.SV_SEB`,
  },
  SE_LF: {
    id: `sweden.kyc.bank.alt.SV_LF`,
  },
  SE_SKANDIA: {
    id: `sweden.kyc.bank.alt.SV_SKANDIA`,
  },
  SE_DB: {
    id: `sweden.kyc.bank.alt.SV_DB`,
  },
  SE_OTHER: {
    id: `sweden.kyc.bank.alt.SV_OTHER`,
  },
});

const denmarkMessages = defineMessages<
  BankType<LysaCountry.DENMARK>,
  MessageWithId,
  Record<BankType<LysaCountry.DENMARK>, MessageWithId>
>({
  DK_DB: {
    id: `denmark.kyc.bank.alt.DK_DB`,
  },
  DK_JYSKE: {
    id: `denmark.kyc.bank.alt.DK_JYSKE`,
  },
  DK_NORDEA: {
    id: `denmark.kyc.bank.alt.DK_NORDEA`,
  },
  DK_NYKREDIT: {
    id: `denmark.kyc.bank.alt.DK_NYKREDIT`,
  },
  DK_OTHER: {
    id: `denmark.kyc.bank.alt.DK_OTHER`,
  },
  DK_SYDBANK: {
    id: `denmark.kyc.bank.alt.DK_SYDBANK`,
  },
});

const finlandMessages = defineMessages<
  BankType<LysaCountry.FINLAND>,
  MessageWithId,
  Record<BankType<LysaCountry.FINLAND>, MessageWithId>
>({
  FI_DB: {
    id: `finland.kyc.bank.alt.FI_DB`,
  },
  FI_HB: {
    id: `finland.kyc.bank.alt.FI_HB`,
  },
  FI_NORDEA: {
    id: `finland.kyc.bank.alt.FI_NORDEA`,
  },
  FI_OP: {
    id: `finland.kyc.bank.alt.FI_OP`,
  },
  FI_OTHER: {
    id: `finland.kyc.bank.alt.FI_OTHER`,
  },
  FI_SP: {
    id: `finland.kyc.bank.alt.FI_SP`,
  },
});

const germanyMessages = defineMessages<
  BankType<LysaCountry.GERMANY>,
  MessageWithId,
  Record<BankType<LysaCountry.GERMANY>, MessageWithId>
>({
  DE_COMMERZ: {
    id: `germany.kyc.bank.alt.DE_COMMERZ`,
  },
  DE_DB: {
    id: `germany.kyc.bank.alt.DE_DB`,
  },
  DE_DZ: {
    id: `germany.kyc.bank.alt.DE_DZ`,
  },
  DE_HYPO: {
    id: `germany.kyc.bank.alt.DE_HYPO`,
  },
  DE_KFW: {
    id: `germany.kyc.bank.alt.DE_KFW`,
  },
  DE_OTHER: {
    id: `germany.kyc.bank.alt.DE_OTHER`,
  },
});

const spainMessages = defineMessages<
  BankType<LysaCountry.SPAIN>,
  MessageWithId,
  Record<BankType<LysaCountry.SPAIN>, MessageWithId>
>({
  ES_SAN: {
    id: "spain.kyc.bank.alt.ES_SAN",
  },
  ES_BBVA: {
    id: "spain.kyc.bank.alt.ES_BBVA",
  },
  ES_CAIXA: {
    id: "spain.kyc.bank.alt.ES_CAIXA",
  },
  ES_SABADELL: {
    id: "spain.kyc.bank.alt.ES_SABADELL",
  },
  ES_BANKIA: {
    id: "spain.kyc.bank.alt.ES_BANKIA",
  },
  ES_OTHER: {
    id: "spain.kyc.bank.alt.ES_OTHER",
  },
});

const countryMessages: Record<LysaCountry, CountryMessages> = {
  [LysaCountry.SWEDEN]: swedenMessages,
  [LysaCountry.DENMARK]: denmarkMessages,
  [LysaCountry.FINLAND]: finlandMessages,
  [LysaCountry.GERMANY]: germanyMessages,
  [LysaCountry.SPAIN]: spainMessages,
};

interface Props {
  banks?: BankType[];
  setBanks: (banks: BankType[]) => void;
}

export const BankQuestion: VoidFunctionComponent<Props> = ({
  banks,
  setBanks,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);
  const {
    state: { legalEntityType },
  } = useContext(UserContext);
  const country = localizationContext.state.country;

  if (!country) {
    return null;
  }

  const alternatives = availableBanks[country].map((value) => ({
    text: intl.formatMessage(
      (countryMessages[country] as Record<typeof value, MessageWithId>)[value]
    ),
    value,
  }));

  return (
    <Card>
      <CheckboxGroup
        alternatives={alternatives}
        header={intl.formatMessage(messages.bankHeader, { legalEntityType })}
        validators={[
          new RequiredValidator(intl.formatMessage(messages.bankRequired)),
        ]}
        onChange={(banks) => setBanks(banks.map((bank) => bank.value))}
        values={alternatives.filter((alt) => (banks ?? []).includes(alt.value))}
      />
    </Card>
  );
};
