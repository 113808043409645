import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VoidFunctionComponent,
} from "react";
import {
  Card,
  EmailInput,
  EmailValidator,
  Form,
  LysaFormRef,
  Button,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  SwedishTinValidator,
  TextInput,
  Toggle,
} from "@lysaab/ui-2";
import { FormattedMessage, useIntl } from "react-intl";
import { useHistory, useParams } from "react-router";
import { GridCol } from "../../../../../../components/grid/gridCol/GridCol";
import { GridRow } from "../../../../../../components/grid/gridRow/GridRow";
import { getNavLink } from "../../../../../../hooks/useCountryUrls";
import { UserManagementContext } from "../UserManagementContext";
import { BASE_ROUTES, UserManagementBasePaths } from "../UserManagementStory";
import { messages } from "../Messages";
import { UserContext } from "../../../../../../context/UserContext";
import { StaticInput } from "../../../../../../components/staticInput/StaticInput";
import "./UserManagementEdit.scss";

interface Match {
  action: UserManagementBasePaths.ADD | UserManagementBasePaths.UPDATE;
}

interface Props {
  next: (
    action: UserManagementBasePaths.ADD | UserManagementBasePaths.UPDATE,
    updatedUser: { identificationNumber: string; email: string; admin: boolean }
  ) => void;
  remove: () => void;
}

export const UserManagementEdit: VoidFunctionComponent<Props> = ({
  next,
  remove,
}) => {
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();
  const history = useHistory();
  const params = useParams<Match>();

  const userManagementContext = useContext(UserManagementContext);
  const userContext = useContext(UserContext);

  const [noChange, setNoChange] = useState<boolean>(false);
  const [identificationNumber, setIdentificationNumber] = useState<string>(
    userManagementContext.state.selectedUser?.identificationNumber ?? ""
  );
  const [email, setEmail] = useState<string>(
    userManagementContext.state.selectedUser?.email ?? ""
  );
  const [admin, setAdmin] = useState<boolean>(
    userManagementContext.state.selectedUser?.admin ?? false
  );

  const isAdmin = !userContext.state.readOnly;

  const onSumbit = useCallback(() => {
    if (
      userManagementContext.state.selectedUser?.email === email &&
      userManagementContext.state.selectedUser?.admin === admin
    ) {
      setNoChange(true);
      return;
    }

    if (formRef.current?.isValid) {
      let updatedUser = {
        email,
        admin,
        identificationNumber,
      };
      userManagementContext.setState({
        updatedUser: updatedUser,
      });
      next(params.action, updatedUser);
    }
  }, [
    userManagementContext,
    email,
    admin,
    identificationNumber,
    next,
    params.action,
  ]);

  useEffect(() => {
    if (
      (params.action === UserManagementBasePaths.ADD &&
        userManagementContext.state.selectedUser) ||
      (params.action === UserManagementBasePaths.UPDATE &&
        !userManagementContext.state.selectedUser)
    ) {
      history.push(getNavLink(BASE_ROUTES.HOME));
    }
  }, [
    history,
    params.action,
    userManagementContext.state,
    userManagementContext.state.selectedUser,
  ]);

  return (
    <div className="user-management-edit">
      <Form lysaFormRef={formRef} onSubmit={onSumbit}>
        <GridRow>
          <GridCol xsmall={12}>
            <h3>
              {userManagementContext.state.selectedUser ? (
                <FormattedMessage id={"sweden.userManagement.edit.user"} />
              ) : (
                <FormattedMessage id={"sweden.userManagement.add.user"} />
              )}
            </h3>
          </GridCol>

          <GridCol xsmall={12}>
            <Card>
              {userManagementContext.state.selectedUser && (
                <StaticInput
                  label={intl.formatMessage(messages.editName)}
                  value={userManagementContext.state.selectedUser.name}
                />
              )}

              {!!userManagementContext.state.selectedUser || !isAdmin ? (
                <StaticInput
                  label={intl.formatMessage(messages.editTin)}
                  value={identificationNumber}
                />
              ) : (
                <TextInput
                  label={intl.formatMessage(messages.editTin)}
                  value={identificationNumber}
                  onChange={(event) => setIdentificationNumber(event)}
                  placeholder={intl.formatMessage(messages.editTinPlaceholder)}
                  validators={[
                    new SwedishTinValidator(
                      intl.formatMessage(messages.editTinErrorInvalid)
                    ),
                    new RequiredValidator(
                      intl.formatMessage(messages.editTinErrorRequired)
                    ),
                  ]}
                />
              )}

              {!isAdmin ? (
                <StaticInput
                  label={intl.formatMessage(messages.editEmail)}
                  value={email}
                />
              ) : (
                <EmailInput
                  label={intl.formatMessage(messages.editEmail)}
                  value={email}
                  placeholder={intl.formatMessage(
                    messages.editEmailPlaceholder
                  )}
                  onChange={(event) => setEmail(event)}
                  validators={[
                    new EmailValidator(
                      intl.formatMessage(messages.editEmailErrorInvalid)
                    ),
                    new RequiredValidator(
                      intl.formatMessage(messages.editEmailErrorRequired)
                    ),
                  ]}
                />
              )}

              {!isAdmin || userManagementContext.state.selectedUser?.admin ? (
                <StaticInput
                  label={intl.formatMessage(messages.editIsAdmin)}
                  value={
                    admin
                      ? intl.formatMessage(messages.editIsAdminYes)
                      : intl.formatMessage(messages.editIsAdminNo)
                  }
                />
              ) : (
                <div className="lysa-ui-input-group">
                  <label htmlFor="admin-input">
                    {intl.formatMessage(messages.editIsAdmin)}
                  </label>
                  <Toggle id="admin-input" value={admin} onChange={setAdmin} />
                </div>
              )}
            </Card>
          </GridCol>

          {noChange && (
            <GridCol xsmall={12}>
              <Snackbar type={SNACKBAR_TYPES.SUCCESS}>
                <FormattedMessage id="sweden.userManagement.snackbar.no.change" />
              </Snackbar>
            </GridCol>
          )}

          {isAdmin ? (
            <GridCol xsmall={12}>
              <Button
                block
                type="submit"
                label={<FormattedMessage id="sweden.userManagement.next" />}
              />
            </GridCol>
          ) : (
            <GridCol xsmall={12}>
              <Snackbar type={SNACKBAR_TYPES.ERROR}>
                <FormattedMessage id="sweden.userManagement.snackbar.no.access" />
              </Snackbar>
            </GridCol>
          )}
        </GridRow>
      </Form>

      {userManagementContext.state.selectedUser &&
      !userManagementContext.state.selectedUser.currentUser &&
      isAdmin ? (
        <Button
          variant="negative"
          block
          className="margin-top"
          onClick={() => remove()}
          label={<FormattedMessage id="sweden.userManagement.removeUser" />}
        />
      ) : null}
    </div>
  );
};
