import {
  Card,
  encode,
  Button,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, { useEffect, VoidFunctionComponent, useState } from "react";
import { useParams, useHistory } from "react-router";
import { Link } from "react-router-dom";
import { TranslatedText } from "../../../components/TranslatedText";
import { InvestmentAccountId } from "../../../data/dataAccounts";
import { dataProfile } from "../../../data/dataProfile";
import { useAccount } from "../../../hooks/useAccount";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { useInvestmentAccountCloseable } from "../../../hooks/useInvestmentAccountCloseable";
import { END_INVESTMENT_URL } from "../../endInvestment/EndInvestment";
import { NOT_FOUND_URL } from "../../NotFoundPage";

import "./CloseAccountConfirmation.scss";

interface Props {
  next: () => void;
}

export const CloseAccountConfirmation: VoidFunctionComponent<Props> = ({
  next,
}) => {
  const history = useHistory();
  const [status, setStatus] = useState<
    "READY_FOR_DELETION" | "SENDING_TO_SERVER" | "ON_CLOSE_ERROR"
  >("READY_FOR_DELETION");
  const accountId = useParams<{ accountId: InvestmentAccountId }>().accountId;
  const account = useAccount(accountId);
  const getInvestmentAccountCloseable = useInvestmentAccountCloseable();

  useEffect(() => {
    if (
      typeof getInvestmentAccountCloseable !== "function" ||
      status === "SENDING_TO_SERVER"
    ) {
      return;
    }
    const closeableState = getInvestmentAccountCloseable(accountId);

    if (!closeableState.isCloseable) {
      return history.replace(getNavLink(NOT_FOUND_URL));
    }
  }, [accountId, getInvestmentAccountCloseable, history, status]);

  if (getInvestmentAccountCloseable === "ERROR") {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="closeAccount.confirmation.header" />
        </Typography>
        <Card className="close-account-confirmation-error">
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="endInvestment.closeAccount.error.text" />
          </Snackbar>
        </Card>
        <Link
          className="lysa-ui-button button-primary block"
          to={getNavLink(END_INVESTMENT_URL)}
        >
          <TranslatedText id="endInvestment.closeAccount.story.confirm.back" />
        </Link>
      </>
    );
  }

  if (
    getInvestmentAccountCloseable === "LOADING" ||
    status === "SENDING_TO_SERVER" ||
    !account
  ) {
    return <Spinner />;
  }

  return (
    <>
      <Typography type="h2">
        <TranslatedText id="closeAccount.confirmation.header" />
      </Typography>

      <React.Fragment>
        <Card className="close-account-confirmation">
          {status === "ON_CLOSE_ERROR" && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="endInvestment.closeAccount.error.text" />
            </Snackbar>
          )}
          <p>
            <TranslatedText
              id="closeAccount.confirmation.ingress"
              values={{
                accountName: account.name,
              }}
            />
          </p>
          <p>
            <TranslatedText id="closeAccount.confirmation.account.download.textBefore" />
          </p>
          <div className="close-account-confirmation-rows">
            <div className="flex">
              <span>
                <TranslatedText id="closeAccount.confirmation.download.accountPerformance" />
              </span>
              <Button
                download
                variant="secondary"
                size="small"
                inline
                component="a"
                href={
                  process.env.REACT_APP_API +
                  encode`/gdpr-export/performance/${account.accountId}`
                }
                label={
                  <TranslatedText id="closeAccount.confirmation.account.download" />
                }
              />
            </div>
            <div className="flex">
              <span>
                <TranslatedText id="closeAccount.confirmation.download.accountTransactions" />
              </span>
              <Button
                download
                variant="secondary"
                size="small"
                component="a"
                inline
                href={
                  process.env.REACT_APP_API +
                  encode`/gdpr-export/transactions/${account.accountId}`
                }
                label={
                  <TranslatedText id="closeAccount.confirmation.account.download.link" />
                }
              />
            </div>
          </div>
          <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
            <TranslatedText id="closeAccount.confirmation.account.download.textAfter" />
          </Snackbar>
        </Card>

        <Button
          onClick={() => {
            setStatus("SENDING_TO_SERVER");
            dataProfile
              .closeInvestmentAccount(accountId)
              .then(() => next())
              .catch(() => {
                setStatus("ON_CLOSE_ERROR");
              });
          }}
          block
          label={<TranslatedText id="closeAccount.confirmation.button" />}
        />
      </React.Fragment>
    </>
  );
};
