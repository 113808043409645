import {
  Button,
  Card,
  LysaLink,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
  Typography,
} from "@lysaab/ui-2";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  isInvestmentAccount,
  CompoundAccount,
  isPensionAccountType,
} from "../../../data/dataAccounts";
import { dataWithdrawals } from "../../../data/dataWithdrawals";
import { useAccounts } from "../../../hooks/useAccounts";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { usePendingDeposits } from "../../../hooks/usePendingDeposits";
import { MESSAGES_PAGE_URL } from "../../messages/MessagesPage";
import { AddKlarnaAccountLocationState } from "../../withdrawal/addAccountKlarna/AddAccountKlarnaStory";
import { BASE_ROUTES, CloseAccountState } from "../CloseLysaCustomerStory";
import { dataSavingsAccountInterest } from "../../../data/dataSavingsAccountInterest";
import { useIsPerson } from "../../../hooks/useIsPerson";
import { Link } from "react-router-dom";
import { CloseCustomerScenario } from "../../../data/dataProfile";

interface Props {
  next: () => void;
  addWithdrawalAccountUrl?: string;
  returnState?: CloseAccountState;
}

type ClosingConditions =
  | {
      status: "LOADING";
    }
  | {
      status: "ERROR";
    }
  | {
      status: "SUCCESS";
      hasPendingDeposit: boolean;
      hasMeansOnAccountsButNoExternalAccount: boolean;
      hasPensionAccount: boolean;
    };

export const Intro = ({
  next,
  addWithdrawalAccountUrl,
  returnState,
}: Props) => {
  const isPerson = useIsPerson();
  const loadPendingDeposits = usePendingDeposits();
  const { accounts } = useAccounts();
  const [closingConditions, setClosingConditions] = useState<ClosingConditions>(
    { status: "LOADING" }
  );

  useEffect(() => {
    if (
      typeof loadPendingDeposits === "undefined" ||
      typeof accounts === "undefined"
    ) {
      return;
    }

    const getInterestForSavingsAccountPromises =
      accounts.savingsAccounts.length > 0
        ? dataSavingsAccountInterest.getMultipleAccruedInterest([
            ...accounts.savingsAccounts.map(
              (savingsAccount) => savingsAccount.accountId
            ),
          ])
        : [];
    Promise.all([
      dataWithdrawals.getExternalAccounts(),
      loadPendingDeposits(),
      getInterestForSavingsAccountPromises,
    ])
      .then(([externalAccounts, pendingDeposits, accruedInterestResponse]) => {
        const allAccounts = [
          ...accounts.investmentAccounts,
          ...accounts.savingsAccounts,
        ];
        const hasAccruedInterest = accruedInterestResponse.some(
          (account) => account.accruedInterest > 0
        );
        const meansOnAccounts = allAccounts.reduce(
          (accumulator: number, account: CompoundAccount) =>
            accumulator + moneyOnAccount(account),
          0
        );
        const hasPendingDeposit = allAccounts.some((account) =>
          pendingDeposits.some(
            (deposit) => deposit.accountId === account.accountId
          )
        );
        const hasPensionAccount = accounts.investmentAccounts.some((account) =>
          isPensionAccountType(account.type)
        );

        const hasExternalAccount = externalAccounts.length > 0;
        const hasMeansOnAccountsButNoExternalAccount =
          (meansOnAccounts > 0 || hasAccruedInterest) && !hasExternalAccount;

        setClosingConditions({
          status: "SUCCESS",
          hasPendingDeposit,
          hasPensionAccount,
          hasMeansOnAccountsButNoExternalAccount,
        });
      })
      .catch(() => setClosingConditions({ status: "ERROR" }));
  }, [accounts, loadPendingDeposits]);

  if (!isPerson) {
    return (
      <React.Fragment>
        <Typography type="h2">
          <TranslatedText id="closeLysaCustomerAccountStory.intro.header" />
        </Typography>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="closeLysaCustomerAccountStory.intro.corporateError" />
        </Snackbar>
        <Button
          block
          component={Link}
          to={getNavLink(MESSAGES_PAGE_URL)}
          label={
            <FormattedMessage id="closeLysaCustomerAccountStory.intro.button.messages" />
          }
        />
      </React.Fragment>
    );
  }

  if (closingConditions.status === "LOADING") {
    return <Spinner />;
  }
  if (closingConditions.status === "ERROR") {
    return (
      <>
        <Typography type="h2">
          <TranslatedText id="closeLysaCustomerAccountStory.intro.header" />
        </Typography>
        <Card>
          <Snackbar type={SNACKBAR_TYPES.ERROR}>
            <Typography>
              <TranslatedText id="closeLysaCustomerAccountStory.intro.error" />
            </Typography>
          </Snackbar>
        </Card>
      </>
    );
  }

  const isClosingDueToFatca =
    returnState?.scenario === CloseCustomerScenario.FATCA;
  const isClosingDueToEconomicSituation =
    returnState?.scenario === CloseCustomerScenario.ECONOMIC_SITUATION;
  const isForcedToClose =
    isClosingDueToFatca || isClosingDueToEconomicSituation;

  const isCloseCustomerDisallowed =
    closingConditions.hasMeansOnAccountsButNoExternalAccount ||
    closingConditions.hasPendingDeposit ||
    (closingConditions.hasPensionAccount && !isForcedToClose);
  return (
    <React.Fragment>
      <Typography type="h2">
        <TranslatedText id="closeLysaCustomerAccountStory.intro.header" />
      </Typography>
      <Card>
        <Typography>
          <TranslatedText id="closeLysaCustomerAccountStory.intro.ingress" />
        </Typography>
        <Typography>
          <TranslatedText id="closeLysaCustomerAccountStory.intro.description" />
        </Typography>
        {isCloseCustomerDisallowed && (
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            {closingConditions.hasPensionAccount ? (
              <>
                <Typography>
                  <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPensionWarningDescription" />
                </Typography>
                <Typography>
                  <TranslatedText
                    id="closeLysaCustomerAccountStory.intro.hasPensionWarningContactUs"
                    values={{
                      link: (text) => (
                        <LysaLink
                          component={Link}
                          to={getNavLink(MESSAGES_PAGE_URL)}
                        >
                          {text}
                        </LysaLink>
                      ),
                    }}
                  />
                </Typography>
              </>
            ) : (
              <>
                {closingConditions.hasPendingDeposit && (
                  <Typography>
                    <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPendingDepositWarning" />
                  </Typography>
                )}
                {addWithdrawalAccountUrl &&
                  closingConditions.hasMeansOnAccountsButNoExternalAccount && (
                    <React.Fragment>
                      <Typography>
                        <TranslatedText id="closeLysaCustomerAccountStory.intro.addExternalAccountText" />
                      </Typography>
                      <Link<AddKlarnaAccountLocationState>
                        to={{
                          pathname: addWithdrawalAccountUrl,
                          state: {
                            returnUrl: getNavLink(BASE_ROUTES.INTRO),
                          },
                        }}
                        className="lysa-link"
                      >
                        <TranslatedText id="closeLysaCustomerAccountStory.intro.addExternalAccount" />
                      </Link>
                    </React.Fragment>
                  )}
              </>
            )}
          </Snackbar>
        )}
        {closingConditions.hasPensionAccount && isForcedToClose && (
          <Snackbar type={SNACKBAR_TYPES.WARNING}>
            {isClosingDueToEconomicSituation && (
              <>
                <Typography>
                  <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToEconomicSitation.firstParagraph" />
                </Typography>
                <Typography>
                  <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToEconomicSitation.secondParagraph" />
                </Typography>
                <Typography>
                  <TranslatedText
                    id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToEconomicSitation.contactUs"
                    values={{
                      link: (text) => (
                        <LysaLink
                          component={Link}
                          to={getNavLink(MESSAGES_PAGE_URL)}
                        >
                          {text}
                        </LysaLink>
                      ),
                    }}
                  />
                </Typography>
              </>
            )}
            {isClosingDueToFatca && (
              <>
                <Typography>
                  <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToFatca.firstParagraph" />
                </Typography>
                <Typography>
                  <TranslatedText id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToFatca.secondParagraph" />
                </Typography>
                <Typography>
                  <TranslatedText
                    id="closeLysaCustomerAccountStory.intro.hasPension.isClosingDueToFatca.contactUs"
                    values={{
                      link: (text) => (
                        <LysaLink
                          component={Link}
                          to={getNavLink(MESSAGES_PAGE_URL)}
                        >
                          {text}
                        </LysaLink>
                      ),
                    }}
                  />
                </Typography>
              </>
            )}
          </Snackbar>
        )}
      </Card>
      {!isCloseCustomerDisallowed && (
        <Button
          block
          onClick={next}
          label={
            <TranslatedText id="closeLysaCustomerAccountStory.intro.next" />
          }
        />
      )}
    </React.Fragment>
  );
};

export const moneyOnAccount = (account: CompoundAccount) => {
  if (isInvestmentAccount(account)) {
    return account.worth + account.uninvestedMoney;
  }
  return account.totalBalance;
};
