import React, { FunctionComponent, useMemo, useRef } from "react";
import * as d3 from "d3";
import "./GlidepathGraph.scss";
import { GlidepathDataPoint } from "../../../../../../data/dataInvestments";
import { defineMessages, useIntl } from "react-intl";
import { DEFAULT_PAYOUT_AGE } from "../../TransferContext";
import { Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../../../../../components/TranslatedText";

const arrowSize = 6;
const TOP_PADDING = 40;
const BOTTOM_PADDING = 20;
const GRAPH_HEIGHT = 225;
const GRAPH_WIDTH = 450;

/**
 * Reblancing starts 10 years before payout.
 * But we want to show the last line before rebalancing starts
 */

export const REBALANCE_OFFSET = -10;
export const PRE_OFFSET = 1;
export const PRE_REBALANCE_OFFSET = REBALANCE_OFFSET - PRE_OFFSET;

const messages = defineMessages({
  yearLabel: {
    id: "sweden.transfer-pension.glidepath-graph.year-label",
  },
});

interface Props {
  glidepath: GlidepathDataPoint[];
  age: number;
  withdrawalAge: number;
}

export const GlidepathGraph: FunctionComponent<Props> = ({
  glidepath,
  age,
  withdrawalAge,
}) => {
  const scale = useRef({
    x: d3.scaleLinear().domain([36, 80]).range([0, GRAPH_WIDTH]),
    y: d3
      .scaleLinear()
      .domain([0, 100])
      .range([GRAPH_HEIGHT - BOTTOM_PADDING, TOP_PADDING]),
  });

  const graphData = useMemo(() => {
    scale.current.x.domain([
      d3.min(glidepath, (d) => d.age) ?? 0,
      d3.max(glidepath, (d) => d.age) ?? 0,
    ]);

    const areaGenerator = d3
      .area<GlidepathDataPoint>()
      .x((d) => scale.current.x(d.age))
      .y0(scale.current.y(0))
      .y1((d) => scale.current.y(d.takenRisk));

    const glidePath = areaGenerator(glidepath) ?? undefined;

    const payoutPosition = scale.current.x(withdrawalAge - PRE_OFFSET);
    const reallocationPosition = scale.current.x(
      Math.max(withdrawalAge + PRE_REBALANCE_OFFSET, age)
    );

    /**
     * We use hardcoded ticks
     */
    const yTicks = [0, 50, 100].map((tick) => ({
      tick,
      position: scale.current.y(tick),
    }));

    return {
      glidePath,
      reallocationPosition,
      payoutPosition,
      yTicks,
    };
  }, [age, glidepath, withdrawalAge]);

  return (
    <div className="glidepath-graph">
      <div className="wrapper">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 ${GRAPH_WIDTH} ${GRAPH_HEIGHT}`}
        >
          <defs>
            <clipPath id="activearea">
              <path d={graphData.glidePath} />
            </clipPath>
            <clipPath id="background">
              <rect
                rx={16}
                y={TOP_PADDING}
                height={GRAPH_HEIGHT - (BOTTOM_PADDING + TOP_PADDING)}
                width={GRAPH_WIDTH}
              />
            </clipPath>
          </defs>
          <rect
            rx={16}
            y={TOP_PADDING}
            height={GRAPH_HEIGHT - (BOTTOM_PADDING + TOP_PADDING)}
            width={GRAPH_WIDTH}
            fill="#e6e9f4"
          />
          <path
            d={graphData.glidePath}
            fill="#1840E3"
            clipPath="url(#background)"
          />
          <DrawLine xPos={graphData.reallocationPosition} />
          <DrawLine xPos={graphData.payoutPosition} />
          <g>
            <DrawYTicks ticks={graphData.yTicks} color="#1840E3" />
          </g>
          <g clipPath="url(#activearea)">
            <DrawYTicks ticks={graphData.yTicks} color="#FFF" />
          </g>
          <DrawXTicks
            reallocatePosition={graphData.reallocationPosition}
            payoutPosition={graphData.payoutPosition}
            age={age}
            withdrawalAge={withdrawalAge}
            lastAge={
              glidepath.length > 0 ? glidepath[glidepath.length - 1].age : age
            }
          />
        </svg>
      </div>
      <section className="footer">
        <div className="footer-content">
          <span className="dot left"></span>
          <Typography type="label">
            <TranslatedText id="sweden.transfer-pension.glide-path.equity-funds" />
          </Typography>
        </div>
        <div className="footer-content">
          <span className="dot right"> </span>
          <Typography type="label">
            <TranslatedText id="sweden.transfer-pension.glide-path.fixed-income-funds" />
          </Typography>
        </div>
      </section>
    </div>
  );
};

function DrawXTicks({
  reallocatePosition,
  payoutPosition,
  age,
  withdrawalAge,
  lastAge,
}: {
  reallocatePosition: number;
  payoutPosition: number;
  withdrawalAge: number;
  age: number;
  lastAge: number;
}) {
  const intl = useIntl();

  return (
    <g>
      {age <= DEFAULT_PAYOUT_AGE && (
        <>
          <text
            alignmentBaseline="baseline"
            x={0}
            y={TOP_PADDING - 6}
            textAnchor="start"
            fill="#66676B"
            fontSize={14}
          >
            Tillväxt
          </text>
          <text
            alignmentBaseline="baseline"
            x={reallocatePosition}
            y={TOP_PADDING - 6}
            textAnchor="start"
            fill="#66676B"
            fontSize={14}
          >
            Omfördelning
          </text>
        </>
      )}
      <text
        alignmentBaseline="baseline"
        x={payoutPosition}
        y={TOP_PADDING - 6}
        textAnchor="start"
        fill="#66676B"
        fontSize={14}
      >
        Utbetalning
      </text>
      {age <= DEFAULT_PAYOUT_AGE && (
        <text
          alignmentBaseline="baseline"
          x={0}
          y={GRAPH_HEIGHT - 4}
          textAnchor="start"
          fill="#66676B"
          fontSize={14}
        >
          Idag
        </text>
      )}
      {age <= DEFAULT_PAYOUT_AGE && (
        <text
          alignmentBaseline="baseline"
          x={reallocatePosition}
          y={GRAPH_HEIGHT - 4}
          textAnchor="middle"
          fill="#66676B"
          fontSize={14}
        >
          {intl.formatMessage(messages.yearLabel, {
            years: Math.max(withdrawalAge + REBALANCE_OFFSET, age),
          })}
        </text>
      )}
      <text
        alignmentBaseline="baseline"
        x={payoutPosition === 0 ? payoutPosition + 20 : payoutPosition}
        y={GRAPH_HEIGHT - 4}
        textAnchor="middle"
        fill="#66676B"
        fontSize={14}
      >
        {intl.formatMessage(messages.yearLabel, { years: withdrawalAge })}
      </text>
      <text
        alignmentBaseline="baseline"
        x={GRAPH_WIDTH}
        y={GRAPH_HEIGHT - 4}
        textAnchor="end"
        fill="#66676B"
        fontSize={14}
      >
        {intl.formatMessage(messages.yearLabel, {
          years: lastAge,
        })}
      </text>
    </g>
  );
}

function DrawYTicks({
  ticks,
  color,
}: {
  ticks: { tick: number; position: number }[];
  color: string;
}) {
  return (
    <React.Fragment>
      {ticks.map(({ tick, position }) => {
        let yOffset = 0;

        if (tick === 0) {
          yOffset = -10;
        } else if (tick === 100) {
          yOffset = 12;
        }
        return (
          <text
            alignmentBaseline="middle"
            key={tick}
            x={GRAPH_WIDTH - 8}
            y={position + yOffset}
            textAnchor="end"
            fill={color}
            fontSize={14}
          >
            {tick} %
          </text>
        );
      })}
    </React.Fragment>
  );
}

function DrawLine({ xPos }: { xPos: number }) {
  return (
    <g>
      <line
        x1={xPos}
        y1={TOP_PADDING}
        x2={xPos}
        y2={GRAPH_HEIGHT - BOTTOM_PADDING}
        strokeDasharray="2"
        stroke="#FFF"
      />
      <path
        d={`M ${xPos - arrowSize / 2} ${TOP_PADDING} h ${arrowSize} L ${xPos} ${
          TOP_PADDING + arrowSize
        } Z`}
        fill="#FFF"
      />
      <path
        d={`M ${xPos - arrowSize / 2} ${
          GRAPH_HEIGHT - BOTTOM_PADDING
        } h ${arrowSize} L ${xPos} ${
          GRAPH_HEIGHT - arrowSize - BOTTOM_PADDING
        } Z`}
        fill="#FFF"
      />
    </g>
  );
}
