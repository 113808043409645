import React from "react";
import { Modal } from "../../../../../../../components/modal/Modal";
import { TranslatedText } from "../../../../../../../components/TranslatedText";

interface Props {
  showModal: boolean;
  onClose: () => void;
}

export const SavingsRelatedInformationModal: React.VFC<Props> = ({
  showModal,
  onClose,
}) => {
  return (
    <Modal
      onClose={onClose}
      showModal={showModal}
      header={
        <TranslatedText id="savingsAccountPage.overviewCard.informationModal.title" />
      }
      closeOnOverlayClick
    >
      <h4>
        <TranslatedText id="savingsAccountPage.overviewCard.informationModal.worthDifferenceHeader" />
      </h4>
      <TranslatedText
        id="savingsAccountPage.overviewCard.informationModal.worthDifferenceBody"
        values={{
          paragraph: (parts) => <p>{parts}</p>,
        }}
      />
    </Modal>
  );
};
