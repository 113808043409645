import React, {
  FunctionComponent,
  ReactNode,
  useContext,
  useReducer,
} from "react";

export interface ContextProps<T extends object> {
  state: T;
  setState: React.Dispatch<Partial<T>>;
}

function stateReducer<T extends object>(state: T, newState: Partial<T>): T {
  return { ...state, ...newState };
}

export function createContext<T extends object>(
  contextName: string,
  defaultData: T
): [
  provider: React.FunctionComponent<{ children: ReactNode }>,
  useContextHook: () => [ContextProps<T>["state"], ContextProps<T>["setState"]]
] {
  const Context = React.createContext<ContextProps<T>>(
    undefined as unknown as ContextProps<T>
  );

  Context.displayName = contextName;

  const ContextProvider: FunctionComponent<{ children: ReactNode }> =
    React.memo(({ children }) => {
      const [state, setState] = useReducer<React.Reducer<T, Partial<T>>>(
        stateReducer,
        defaultData
      );

      return (
        <Context.Provider value={{ state, setState }}>
          {children}
        </Context.Provider>
      );
    });

  const useContextHook = (): [
    ContextProps<T>["state"],
    ContextProps<T>["setState"]
  ] => {
    const context = useContext(Context);

    if (typeof context === "undefined") {
      throw new Error(`Missing ${contextName} provider in component tree`);
    }

    return [context.state, context.setState];
  };

  return [ContextProvider, useContextHook];
}
