import React, { useContext } from "react";
import { TranslatedText } from "../../components/TranslatedText";
import "./ReturnInformationModal.scss";
import { FormatNumberOptions, useIntl } from "react-intl";
import { Modal } from "../../components/modal/Modal";
import { ElevioLink } from "../../components/elevio/ElevioLink";
import { LocalizationContext } from "../../context/LocalizationContext";
import { LysaCountry } from "@lysaab/shared";
import { CurrencyString } from "./CurrencyString";

interface Props {
  showModal: boolean;
  onClose: () => void;
}

const INITIAL_INVESTMENT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 10000,
  [LysaCountry.FINLAND]: 1000,
  [LysaCountry.GERMANY]: 1000,
  [LysaCountry.SPAIN]: 1000,
  [LysaCountry.SWEDEN]: 10000,
};

const DEVELOPMENT_AMOUNT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 1000,
  [LysaCountry.FINLAND]: 100,
  [LysaCountry.GERMANY]: 100,
  [LysaCountry.SPAIN]: 100,
  [LysaCountry.SWEDEN]: 1000,
};

const SUM_INITIAL_INVESTMENT_DEVELOPMENT_AMOUNT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 11000,
  [LysaCountry.FINLAND]: 1100,
  [LysaCountry.GERMANY]: 1100,
  [LysaCountry.SPAIN]: 1100,
  [LysaCountry.SWEDEN]: 11000,
};

const SECOND_DEPOSIT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 150000,
  [LysaCountry.FINLAND]: 15000,
  [LysaCountry.GERMANY]: 15000,
  [LysaCountry.SPAIN]: 15000,
  [LysaCountry.SWEDEN]: 150000,
};

const SUM_INITIAL_INVESTMENT_SECOND_DEPOSIT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 161000,
  [LysaCountry.FINLAND]: 16100,
  [LysaCountry.GERMANY]: 16100,
  [LysaCountry.SPAIN]: 16100,
  [LysaCountry.SWEDEN]: 161000,
};

const DEVELOPMENT_AMOUNT_DECREASE: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 8050,
  [LysaCountry.FINLAND]: 805,
  [LysaCountry.GERMANY]: 805,
  [LysaCountry.SPAIN]: 805,
  [LysaCountry.SWEDEN]: 8050,
};

const SUM_INITIAL_INVESTMENT_SECOND_DEVELOPMENT_DECREASE: Record<
  LysaCountry,
  number
> = {
  [LysaCountry.DENMARK]: 152950,
  [LysaCountry.FINLAND]: 15295,
  [LysaCountry.GERMANY]: 15295,
  [LysaCountry.SPAIN]: 15295,
  [LysaCountry.SWEDEN]: 152950,
};

const NOMINAL_RETURN: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 7050,
  [LysaCountry.FINLAND]: 705,
  [LysaCountry.GERMANY]: 705,
  [LysaCountry.SPAIN]: 705,
  [LysaCountry.SWEDEN]: 7050,
};

const TOTAL_ON_ACCOUNT: Record<LysaCountry, number> = {
  [LysaCountry.DENMARK]: 160000,
  [LysaCountry.FINLAND]: 16000,
  [LysaCountry.GERMANY]: 16000,
  [LysaCountry.SPAIN]: 16000,
  [LysaCountry.SWEDEN]: 160000,
};

const elevioArticleId: Record<LysaCountry, number> = {
  [LysaCountry.SWEDEN]: 319,
  [LysaCountry.FINLAND]: 321,
  [LysaCountry.DENMARK]: 319,
  [LysaCountry.GERMANY]: 321,
  [LysaCountry.SPAIN]: 321,
};

export const ReturnInformationModal: React.VFC<Props> = ({
  showModal,
  onClose,
}) => {
  const intl = useIntl();
  const localizationContext = useContext(LocalizationContext);

  if (typeof localizationContext.state.country === "undefined") {
    return null;
  }

  const formatNumberOptions: Partial<FormatNumberOptions> = {
    style: "currency",
    currency: localizationContext.state.currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  };

  return (
    <Modal
      onClose={onClose}
      showModal={showModal}
      header={<TranslatedText id="returnInformationModal.title" />}
      className="return-information-modal"
      closeOnOverlayClick
    >
      <h4>
        <TranslatedText id="returnInformationModal.how-is-my-return-calculated.header" />
      </h4>
      <TranslatedText
        id="returnInformationModal.how-is-my-return-calculated.text"
        values={{
          paragraph: (parts) => <p>{parts}</p>,
          break: () => <br />,
          initialInvestment:
            INITIAL_INVESTMENT[localizationContext.state.country],
          developmentAmount:
            DEVELOPMENT_AMOUNT[localizationContext.state.country],
          sumInitialInvestmentDevelopmentAmount:
            SUM_INITIAL_INVESTMENT_DEVELOPMENT_AMOUNT[
              localizationContext.state.country
            ],
          secondDeposit: SECOND_DEPOSIT[localizationContext.state.country],
          sumInitialInvestmentSecondDeposit:
            SUM_INITIAL_INVESTMENT_SECOND_DEPOSIT[
              localizationContext.state.country
            ],
          developmentAmountDecrease:
            DEVELOPMENT_AMOUNT_DECREASE[localizationContext.state.country],
          sumInitialInvestmentDevelopmentDecrease:
            SUM_INITIAL_INVESTMENT_SECOND_DEVELOPMENT_DECREASE[
              localizationContext.state.country
            ],
          nominalReturn: NOMINAL_RETURN[localizationContext.state.country],
          totalOnAccount: TOTAL_ON_ACCOUNT[localizationContext.state.country],
          currency: () => {
            return <CurrencyString />;
          },
          currencyWithNumbers: (amount) => {
            return intl.formatNumber(Number(amount) || 0, formatNumberOptions);
          },
          example: (parts) => <div className="example-block">{parts}</div>,
          percentage: (amount) => {
            return intl.formatNumber(Number(amount) / 100, {
              style: "percent",
              maximumFractionDigits: 4,
            });
          },
          number: (amount) => {
            return intl.formatNumber(Number(amount));
          },
          elevio: (text) => {
            if (localizationContext.state.country === undefined) {
              return;
            }
            return (
              <ElevioLink
                articleId={elevioArticleId[localizationContext.state.country]}
                linkText={text}
              />
            );
          },
        }}
      />
    </Modal>
  );
};
