import React from "react";
import cx from "classnames";
import { Button, CardList, RiskIndicator, Typography } from "@lysaab/ui-2";
import { TranslatedText } from "../../components/TranslatedText";
import {
  InvestmentAccount,
  AccountType,
  InvestmentType,
} from "../../data/dataAccounts";
import {
  getEligibility,
  EligibilityResponse,
  isValidPensionAccountQuestions,
  CombinedAdviseAccount,
  isInvestmentAdviseAccount,
} from "../../data/dataInvestments";
import "./AccountFocus.scss";
import { FormattedMessage, defineMessages, useIntl } from "react-intl";
import { SuitabilityDownload } from "../../pageComponents/advise/SuitabilityDownload";
import { CardBottom } from "./CardBottom";
import { savingsHorizonLengthMessages } from "../../pageComponents/horizon/questions/SavingsHorizonLengthInput";
import { HideIfReadOnly } from "../../components/hideIfReadOnly/HideIfReadOnly";
import { Link, generatePath } from "react-router-dom";
import {
  ACCOUNT_SITUATION_EDIT_PAGE,
  AccountSituationInstanceLocationState,
} from "../accountSituation/AccountSituationStory";
import { getNavLink } from "../../hooks/useCountryUrls";
import { MESSAGES_PAGE_URL } from "../messages/MessagesPage";

const investmentTypeMessages = defineMessages<InvestmentType>({
  [InvestmentType.BROAD]: {
    id: "account.account-focus.type.BROAD",
  },
  [InvestmentType.SUSTAINABLE]: {
    id: "account.account-focus.type.SUSTAINABLE",
  },
});

interface Props {
  account?: InvestmentAccount;
  adviseAccount?: CombinedAdviseAccount;
  eligibility?: EligibilityResponse;
}

export const AccountFocus: React.VFC<Props> = ({
  adviseAccount,
  account,
  eligibility,
}) => {
  const intl = useIntl();

  if (!adviseAccount || !account) {
    return <div className="account-account-focus" />;
  }

  return (
    <section
      className={cx("account-account-focus", {
        "is-insurance-account": account.type === AccountType.DANICA_KF,
      })}
    >
      <div className="account-account-focus--header">
        <Typography type="h2">
          <TranslatedText id="account.account-focus.title" />
        </Typography>

        {!!eligibility && isInvestmentAdviseAccount(adviseAccount) && (
          <div>
            <SuitabilityDownload
              esgQuestions={adviseAccount.esgResult}
              accountQuestions={adviseAccount}
              advisedRisk={adviseAccount.advisedRisk}
              eligibility={getEligibility(eligibility)}
              accountType={account.type}
              render={(url, filename) => (
                <Button
                  component="a"
                  href={url}
                  download={filename}
                  variant="secondary"
                  size="small"
                  label={
                    <FormattedMessage id="account.account-focus.suitabilityPdfDownload.download" />
                  }
                  icon="Deposit"
                />
              )}
            />
          </div>
        )}
      </div>
      <CardList>
        <div className="account-page-padder">
          <div className="account-page-card-body">
            {isInvestmentAdviseAccount(adviseAccount) &&
              adviseAccount?.savingsHorizon && (
                <dl>
                  <dt>
                    {intl.formatMessage({
                      id: "account.account-focus.horizon",
                    })}
                  </dt>
                  <dd data-test-id="account-savings-horizon">
                    {intl.formatMessage(
                      savingsHorizonLengthMessages[adviseAccount.savingsHorizon]
                    )}
                  </dd>
                </dl>
              )}

            {isValidPensionAccountQuestions(adviseAccount) &&
              adviseAccount?.withdrawalAge && (
                <dl>
                  <dt>
                    {intl.formatMessage({
                      id: "account.account-focus.withdrawalAge",
                    })}
                  </dt>
                  <dd data-test-id="account-savings-withdrawalAge">
                    {`${adviseAccount.withdrawalAge} ${intl.formatMessage({
                      id: "account.account-focus.withdrawalAge-unit",
                    })}`}
                  </dd>
                </dl>
              )}
            {isValidPensionAccountQuestions(adviseAccount) &&
              adviseAccount?.withdrawalMonths && (
                <dl>
                  <dt>
                    {intl.formatMessage({
                      id: "account.account-focus.withdrawalDuration",
                    })}
                  </dt>
                  <dd data-test-id="account-savings-withdrawalDuration">
                    {`${
                      adviseAccount.withdrawalMonths / 12 // Converted to years
                    } ${intl.formatMessage({
                      id: "account.account-focus.withdrawalDuration-unit",
                    })}`}
                  </dd>
                </dl>
              )}
            <dl>
              <dt>
                {intl.formatMessage({ id: "account.account-focus.type" })}
              </dt>
              <dd data-test-id="account-investment-type">
                {intl.formatMessage(
                  investmentTypeMessages[adviseAccount.investmentType]
                )}
                <RiskIndicator
                  size={20}
                  risk={adviseAccount.takenRisk}
                  investmentType={adviseAccount.investmentType}
                />
              </dd>
            </dl>

            <div>
              <p data-test-id="account-advised-declaration">
                {adviseAccount?.declaration ?? ""}
              </p>
            </div>

            {(account.type === AccountType.DANICA_KF ||
              account.type === AccountType.KF_SWE) && (
              <div className="kf-info">
                <p>
                  <TranslatedText id="account.account-focus.declaration.insurance.paragraph" />
                </p>
              </div>
            )}
            {isValidPensionAccountQuestions(adviseAccount) && (
              <div>
                <Typography type="h4">
                  <TranslatedText id="account.account-focus.beneficiary.header" />
                </Typography>
                <Typography type="body">
                  <TranslatedText id="account.account-focus.beneficiary.body.first" />
                </Typography>
                <Typography type="body">
                  <TranslatedText
                    id="account.account-focus.beneficiary.body.second"
                    values={{
                      link: (link) => (
                        <a href="mailto:kontakt@lysalife.se">{link}</a>
                      ),
                    }}
                  />
                </Typography>
              </div>
            )}
          </div>

          {isInvestmentAdviseAccount(adviseAccount) ? (
            <HideIfReadOnly>
              {!account.isSharedAccount && (
                <CardBottom>
                  <Link<AccountSituationInstanceLocationState>
                    to={{
                      pathname: generatePath(
                        getNavLink(ACCOUNT_SITUATION_EDIT_PAGE),
                        {
                          accountId: account.accountId,
                        }
                      ),
                      state: { returnUrl: window.location.pathname },
                    }}
                  >
                    <TranslatedText id="account.account-focus.cta.update-focus" />
                  </Link>
                </CardBottom>
              )}
            </HideIfReadOnly>
          ) : (
            <CardBottom>
              <Link to={getNavLink(MESSAGES_PAGE_URL)}>
                <TranslatedText id="account.account-focus.cta.update-focus.pension.info"></TranslatedText>
              </Link>
            </CardBottom>
          )}
        </div>
      </CardList>
    </section>
  );
};
