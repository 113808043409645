import React, { FunctionComponent, ReactNode } from "react";
import cx from "classnames";

import "./LysaTableTextCell.scss";

interface Props {
  value?: string | ReactNode;
  label?: string | ReactNode;
  header?: boolean;
  className?: string;
}

export const LysaTableTextCell: FunctionComponent<
  React.PropsWithChildren<Props>
> = ({ value, label, header = false, className, children }) => {
  return header ? (
    <th className={cx("text-cell", className)}>
      {typeof children !== "undefined" ? children : <span>{value}</span>}
    </th>
  ) : (
    <td className={cx("text-cell", className)} data-label={label}>
      {typeof children !== "undefined" ? children : <span>{value}</span>}
    </td>
  );
};
