import React, { useEffect, useRef, useState } from "react";
import {
  Alternative,
  Card,
  Form,
  LysaFormRef,
  LysaLink,
  Button,
  RadioGroup,
  RequiredValidator,
  Snackbar,
  SNACKBAR_TYPES,
  Spinner,
} from "@lysaab/ui-2";
import { defineMessages, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import { useMonthlyContext } from "..//MonthlyContext";
import { BASE_ROUTES } from "../MonthlyStory";
import { EventTracker } from "../../../../../components/eventTracker/EventTracker";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { getNavLink } from "../../../../../hooks/useCountryUrls";
import { dataDirectDebit } from "../../../../../data/dataDirectDebit";
import { UnconfirmedExternalAccountValidator } from "./unconfirmedExternalAccountValidator/UnconfirmedExternalAccountValidator";
import {
  dataMonthlyPayments,
  MonthlyPaymentWithBankInfo,
} from "../../../../../data/dataMonthlyPayments";
import { MONTHLY_OVERVIEW_PAGE } from "../../overview/MonthlyOverview";
import { banks } from "../../../../../data/dataBanks";
import { TrackerEvent } from "../../../../../data/dataCustomerTracking";

interface Props {
  next: () => void;
  isKlarnaAvailable: boolean;
}

const messages = defineMessages({
  externalAccountLabel: {
    id: "deposits.monthly.story.external-account-selection.label",
  },
  lysaAccountRequired: {
    id: "deposits.monthly.story.external-account-selection.lysa.required",
  },
  unconfirmedExternalAccount: {
    id: "deposits.monthly.story.external-account-selection.lysa.unconfirmed",
  },
  ibanError: {
    id: "deposits.monthly.story.external-account-selection.number.ibanError",
  },
});

export const ExternalAccountSelection = ({
  next,
  isKlarnaAvailable,
}: Props) => {
  const [accountAlternatives, setAccountAlternatives] = useState<
    Alternative<string>[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [monthlyPaymentExist, setMonthlyPaymentExist] = useState(false);
  const [monthlyPayments, setMonthlyPayments] = useState<
    MonthlyPaymentWithBankInfo[]
  >([]);
  const monthlyContext = useMonthlyContext();
  const formRef = useRef<LysaFormRef>();
  const intl = useIntl();

  useEffect(() => {
    Promise.all([
      dataDirectDebit.getAccountRestrictions(),
      dataMonthlyPayments.getMonthlyPayments(),
    ])
      .then(([restrictions, monthlyPayments]) => {
        setMonthlyPayments(monthlyPayments);
        setAccountAlternatives([
          ...restrictions.map((restriction) => {
            const bankText =
              restriction.bank && restriction.bank !== "UNKNOWN"
                ? `${restriction.externalAccountNumber} - ${
                    banks[restriction.bank as keyof typeof banks]?.long
                  }`
                : restriction.externalAccountNumber;
            return {
              text: bankText,
              value: restriction.externalAccountNumber,
            };
          }),
        ]);
      })
      .catch(() => {
        setIsError(true);
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <React.Fragment>
      <h2>
        <TranslatedText id="deposits.monthly.story.external-account-selection.header" />
      </h2>
      <Form
        lysaFormRef={formRef}
        onSubmit={() => {
          if (!formRef.current?.isValid || accountAlternatives.length === 0) {
            return;
          } else if (
            monthlyPayments.some((monthlyPayment) => {
              return (
                monthlyPayment.externalBankAccount ===
                  monthlyContext.state.externalAccount &&
                monthlyPayment.accountId ===
                  monthlyContext.state.selectedLysaAccount?.accountId
              );
            })
          ) {
            setMonthlyPaymentExist(true);
          } else {
            EventTracker.track({
              event: TrackerEvent.ADD_MONTHLY_EXTERNAL_ACCOUNT,
              message: `${monthlyContext.state.externalAccount}`,
            });
            next();
          }
        }}
      >
        <Card>
          {isError && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText id="deposits.monthly.story.external-account-selection.error" />
            </Snackbar>
          )}
          {monthlyPaymentExist && (
            <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
              <TranslatedText
                id="deposits.monthly.story.external-account-selection.monthlyPaymentExist"
                values={{
                  externalAccount: `${monthlyContext.state.externalAccount}`,
                  lysaAccount: monthlyContext.state.selectedLysaAccount?.name,
                  link: (text) => (
                    <LysaLink
                      component={Link}
                      to={getNavLink(MONTHLY_OVERVIEW_PAGE)}
                    >
                      {text}
                    </LysaLink>
                  ),
                }}
              />
            </Snackbar>
          )}
          {accountAlternatives.length > 0 ? (
            <RadioGroup
              alternatives={accountAlternatives}
              value={
                typeof monthlyContext.state.externalAccount !== "undefined"
                  ? accountAlternatives.find(
                      (alternative) =>
                        alternative.value ===
                        monthlyContext.state.externalAccount
                    )
                  : undefined
              }
              header={intl.formatMessage(messages.externalAccountLabel)}
              onChange={(alt) => {
                isError && setIsError(false);
                monthlyPaymentExist && setMonthlyPaymentExist(false);
                monthlyContext.setState({
                  externalAccount: alt.value,
                });
              }}
              validators={[
                new RequiredValidator(
                  intl.formatMessage(messages.lysaAccountRequired)
                ),
                new UnconfirmedExternalAccountValidator(
                  intl.formatMessage(messages.ibanError),
                  intl.formatMessage(messages.unconfirmedExternalAccount)
                ),
              ]}
            />
          ) : (
            <p>
              <TranslatedText id="deposits.monthly.story.external-account-selection.no-accounts" />
            </p>
          )}
          {!isError && (
            <LysaLink
              component={Link}
              to={getNavLink(
                isKlarnaAvailable
                  ? BASE_ROUTES.BANK_SELECTION
                  : BASE_ROUTES.MANUAL_ACCOUNT_SELECTION
              )}
            >
              <TranslatedText id="deposits.monthly.story.external-account-selection.add-external-account" />
            </LysaLink>
          )}
        </Card>
        <Button
          type="submit"
          block
          label={
            <TranslatedText id="deposits.monthly.story.external-account-selection.button" />
          }
        />
      </Form>
    </React.Fragment>
  );
};
