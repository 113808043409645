import { Button, Card } from "@lysaab/ui-2";
import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { StoryDisclaimer } from "../../../components/storyDisclaimer/StoryDisclaimer";
import { TranslatedText } from "../../../components/TranslatedText";
import {
  CompoundAccount,
  isInvestmentAccount,
  isSavingsAccount,
} from "../../../data/dataAccounts";
import { MergedInternalTransferAccount } from "../../../data/dataTransfer";
import { getNavLink } from "../../../hooks/useCountryUrls";
import { OVERVIEW_PAGE_URL } from "../../overview/OverviewPage";
import {
  InternalTransferContext,
  InternalTransferState,
} from "../InternalTransferContext";

export function InternalTransferDone() {
  const location = useLocation<{ isDrain: boolean } | undefined>();
  const internalTransferContext = useContext(InternalTransferContext);
  const setInternalTransferState = internalTransferContext.setState;
  const [internalTransferData, setInternalTransferData] =
    useState<InternalTransferState>();
  const isDrain = location.state?.isDrain;

  if (!internalTransferData) {
    setInternalTransferData(internalTransferContext.state);
  }

  useEffect(() => {
    if (internalTransferData) {
      setInternalTransferState({
        fromAccount: undefined,
        toAccount: undefined,
        amount: undefined,
      });
    }
  }, [internalTransferData, setInternalTransferState]);

  if (
    !internalTransferData ||
    !internalTransferData.fromAccount ||
    !internalTransferData.toAccount
  ) {
    return null;
  }

  return (
    <div>
      <h2>
        <TranslatedText id="internal-transfer.story.done.header" />
      </h2>
      <Card>
        {isBetweenAccountTypes(
          internalTransferData.fromAccount,
          internalTransferData.toAccount
        ) ? (
          <TranslatedText id="internal-transfer.story.done.body.savingsAccount" />
        ) : (
          <TranslatedText id="internal-transfer.story.done.body" />
        )}
      </Card>
      <Button
        component={Link}
        to={getNavLink(OVERVIEW_PAGE_URL)}
        block
        label={<TranslatedText id="internal-transfer.story.done.button" />}
      />
      {isFromIskToSavings(
        internalTransferData.fromAccount,
        internalTransferData.toAccount
      ) &&
        isDrain && (
          <StoryDisclaimer>
            <TranslatedText id="internal-transfer.story.done.disclaimer" />
          </StoryDisclaimer>
        )}
    </div>
  );
}

const isBetweenAccountTypes = (
  fromAccount: MergedInternalTransferAccount,
  toAccount: CompoundAccount
) => {
  if (
    isFromIskToSavings(fromAccount, toAccount) ||
    (isInvestmentAccount(toAccount) && isSavingsAccount(fromAccount))
  ) {
    return true;
  } else {
    return false;
  }
};

const isFromIskToSavings = (
  fromAccount: MergedInternalTransferAccount,
  toAccount: CompoundAccount
) => {
  return isInvestmentAccount(fromAccount) && isSavingsAccount(toAccount);
};
