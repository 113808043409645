import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Card,
  Button,
  CardList,
  CardListItem,
  Alternative,
  Icon,
  Snackbar,
  SNACKBAR_TYPES,
} from "@lysaab/ui-2";
import { dataIsk, SavedTransfer } from "../../../../data/dataIsk";
import { FormattedMessage, useIntl } from "react-intl";

interface Props {
  next: () => void;
}

const date = new Date();
const isSummer = date.getMonth() >= 6 && date.getMonth() <= 7;
const isWinter = date.getMonth() === 0 || date.getMonth() === 11;

export const IskHome: FunctionComponent<Props> = ({ next }) => {
  const [history, setHistory] = useState<SavedTransfer[]>([]);
  const [institutions, setInstitutions] = useState<Alternative<string>[]>([]);
  const intl = useIntl();

  useEffect(() => {
    dataIsk.getTransferHistory().then((history) => setHistory(history));
    dataIsk.getInstitutions().then((resp) => setInstitutions(resp));
  }, []);

  return (
    <div>
      <h2>
        <FormattedMessage
          id="sweden.isk.move.home.header"
          defaultMessage="Move your savings to Lysa"
        />
      </h2>
      {isSummer && (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <FormattedMessage id="sweden.isk.move.home.summerInfo" />
        </Snackbar>
      )}
      {isWinter && (
        <Snackbar type={SNACKBAR_TYPES.WARNING} icon>
          <FormattedMessage id="sweden.isk.move.home.winterInfo" />
        </Snackbar>
      )}
      {history && history.length > 0 && (
        <React.Fragment>
          <h2>
            <FormattedMessage
              id="sweden.isk.move.home.previous"
              defaultMessage="Transfer history"
            />
          </h2>
          <CardList>
            {history.map((transfer) => (
              <CardListItem key={transfer.transferId}>
                <div className="history-item">
                  <div>
                    <div>
                      <b>
                        {
                          institutions.find(
                            (inst) => inst.value === transfer.institute
                          )?.text
                        }
                      </b>
                    </div>
                    <div>{transfer.accountNumber}</div>
                  </div>
                  <div>
                    <div>
                      <b>
                        <FormattedMessage
                          id="sweden.isk.move.home.transfer-type"
                          defaultMessage="Transfer type"
                        />
                      </b>
                    </div>
                    <div>
                      {transfer.fullTransfer ? (
                        <span>
                          <FormattedMessage
                            id="sweden.isk.move.home.type.everyting"
                            defaultMessage="Transfer everything"
                          />
                        </span>
                      ) : (
                        <span>
                          <FormattedMessage
                            id="sweden.isk.move.home.type.amount"
                            defaultMessage="Transfer {amount} kr"
                            values={{
                              amount: intl.formatNumber(transfer.amount || 0),
                            }}
                          />
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="badge-wrapper">
                    {transfer.completed && (
                      <div className="status-badge completed">
                        <FormattedMessage
                          id="sweden.isk.move.home.status.done"
                          defaultMessage="Done"
                        />
                      </div>
                    )}
                    {!transfer.completed && (
                      <div className="status-badge pending">
                        <FormattedMessage
                          id="sweden.isk.move.home.status.pending"
                          defaultMessage="pending"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </CardListItem>
            ))}
          </CardList>
        </React.Fragment>
      )}

      <Card>
        <ul>
          <li>
            <div>
              <Icon.User />
            </div>
            <div>
              <FormattedMessage
                id="sweden.isk.move.home.list.1"
                defaultMessage="Transfering your savings to Lysa is simple - fill our this form and we'll do the rest"
              />
            </div>
          </li>
          <li>
            <div>
              <Icon.Calendar />
            </div>
            <div>
              <FormattedMessage
                id="sweden.isk.move.home.list.2"
                defaultMessage="A transfer between two ISK-accounts is not taxed and usually takes between 2-3 weeks"
              />
            </div>
          </li>
          <li>
            <div>
              <Icon.Bank />
            </div>
            <div>
              <FormattedMessage
                id="sweden.isk.move.home.list.3"
                defaultMessage="Tip! You need to know the institute and ISK-account number your transfering from"
              />
            </div>
          </li>
          <li>
            <div>
              <Icon.Search />
            </div>
            <div>
              <FormattedMessage
                id="sweden.isk.move.home.elevio"
                defaultMessage="Read more about ISK-transfers <link>here</link>"
                values={{
                  link: (parts) => (
                    <span data-elevio-article="121">
                      <span className="loner link">{parts}</span>
                    </span>
                  ),
                }}
              />
            </div>
          </li>
        </ul>
      </Card>
      <Button
        block
        onClick={() => next()}
        label={<FormattedMessage id="sweden.isk.move.home.next" />}
      />
    </div>
  );
};
