import React, { useReducer, createContext, FunctionComponent } from "react";
import {
  SavingsAccountPurpose,
  SavingsAccountDepositInterval,
  SavingsAccountWithdrawalInterval,
} from "../../../../../data/dataKyc";

export interface CreateSavingsAccountState {
  /** KYC */
  purpose?: SavingsAccountPurpose[];
  depositInterval?: SavingsAccountDepositInterval;
  withdrawalInterval?: SavingsAccountWithdrawalInterval;

  accountName: string;
}

interface CreateAccountContextProps {
  state: CreateSavingsAccountState;
  setState: (newState: Partial<CreateSavingsAccountState>) => void;
}

export const CreateSavingsAccountContext =
  createContext<CreateAccountContextProps>({} as CreateAccountContextProps);

function stateReducer(
  state: CreateSavingsAccountState,
  newState: Partial<CreateSavingsAccountState>
) {
  return { ...state, ...newState };
}

export const CreateSavingsAccountContextProvider: FunctionComponent<
  React.PropsWithChildren<{
    accountName: string;
  }>
> = ({ children, accountName }) => {
  const [state, setState] = useReducer(stateReducer, {
    accountName,
  });

  return (
    <CreateSavingsAccountContext.Provider value={{ state, setState }}>
      {children}
    </CreateSavingsAccountContext.Provider>
  );
};
